import Close from '@mui/icons-material/Close'
import Logout from '@mui/icons-material/Logout'
import { Box, DialogActions, DialogContent, DialogTitle, IconButton, Stack } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import CustomButton from 'src/components/shared/Custom/Button'
import CustomDialog from 'src/components/shared/Custom/Dialog'
import AuthService from 'src/services/AuthService'

export default function Deslogin({open, setOpen}) {

    const navigate = useNavigate()

  return (
    <CustomDialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap={5}
        >
          <Box
            fontSize={18}
            fontWeight={500}
            display={"flex"}
            alignItems="center"
            gap={2}
          >
            <Logout
              sx={{
                fontSize: 32,
                color: "white",
                background: "#F2788E",
                borderRadius: "100%",
                height: 30,
                width: 30,
                padding: 0.5,
              }}
            />
            Cerrar sesión
          </Box>
          <Box>
            <IconButton onClick={() => {setOpen(false)}}>
              <Close />
            </IconButton>
          </Box>
        </Box>
        </DialogTitle>
        <DialogContent>
            ¿Está seguro que desea cerrar la sesión?
        </DialogContent>
        <DialogActions sx={{padding: 3, paddingTop: 0}}>
        <Stack direction="row" justifyContent="flex-end" gap={1}>
            <CustomButton sx={{color: 'black', '&:hover': {shadow: 0, backgroundColor: 'white'}}} onClick={() => {setOpen(false)}}>Cancelar</CustomButton>
            <CustomButton
              variant="outlined"
              sx={{
                color: "#F2788E",
                borderColor: "#F2788E",
                "&:hover": { backgroundColor: "white", borderColor: "#F2788E" },
              }}
              onClick={() => {AuthService.deslog(); navigate('/'); setOpen(false)}}
            >
              Cerrar sesión
            </CustomButton>
          </Stack>
        </DialogActions>
    </CustomDialog>
  )
}
