import Close from "@mui/icons-material/Close";
import { Box, DialogTitle, Icon, IconButton } from "@mui/material";
import React from "react";
import * as Icons from "@mui/icons-material";

export interface ICustomDialogTitle {
  icon: React.ReactNode;
  iconColor: string;
  title: string;
  handleClose: () => void;
}

export default function CustomDialogTitle({
  icon,
  iconColor,
  title,
  handleClose,
}) {
  return (
    <DialogTitle>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={5}
      >
        <Box
          fontSize={18}
          fontWeight={500}
          display={"flex"}
          alignItems="center"
          gap={2}
        >
          {React.createElement(Icons[icon], {
            sx: {
              fontSize: 32,
              color: "white",
              backgroundColor: iconColor,
              borderRadius: "100%",
              height: 30,
              width: 30,
              padding: 0.5,
            },
          })}
          {title}
        </Box>
        <Box>
          <IconButton onClick={() => handleClose()}>
            <Close />
          </IconButton>
        </Box>
      </Box>
    </DialogTitle>
  );
}
