import { Box, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useTable from "src/hooks/useTable";
import Card from "./Card";
import { ArrowDownward, ArrowUpward, TodayOutlined } from "@mui/icons-material";
import Aplazar from "../../Main/Dialogs/Aplazar";

export default function Table({refresher, setRefresher}) {
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const { isLoading, data, setData, getData } = useTable(
    `listas?aplazados&order=fechaAplazo&order=${order}`
  );

  useEffect(() => {
    getData();
  }, [order, refresher]);

  return (
    <>
      <Aplazar getData={getData} />
      <Stack gap={2}>
        <Stack
          justifyContent="space-between"
          direction="row"
          gap={1}
          alignItems="center"
        >
          <Stack direction="row" gap={1} alignItems="center">
            <IconButton
              onClick={() => setOrder(order === "asc" ? "desc" : "asc")}
            >
              {order === "asc" ? <ArrowUpward /> : <ArrowDownward />}
            </IconButton>
            <Typography fontWeight={500}>Ordenar por fecha</Typography>
          </Stack>
        </Stack>
        {Boolean(data) &&
          Boolean(data?.length) &&
          data.map((item) => <Card item={item} refreshTables={() => {getData();setRefresher(!refresher)}} key={item?.id} />)}
      </Stack>
    </>
  );
}
