import { Add, ExpandMore, PostAdd } from "@mui/icons-material";
import {
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import CustomAccordion from "src/components/shared/Custom/Accordion";
import CustomButton from "src/components/shared/Custom/Button";
import CustomTextField from "src/components/shared/Custom/TextField";
import useFetch from "src/hooks/useFetch";

export default function Form({refresher, setRefresher}) {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [form, setForm] = useState<{ denominacion: string }>({
    denominacion: null,
  });
  const { fetchData: fetchDataPost } = useFetch("post", "metodos-pago", form);
  const handleFormChange = (field, value) => {
    setForm({ ...form, [field]: value });
  };

  const handleSubmit = async () => {
    await fetchDataPost();
    setRefresher(!refresher);
    clearForm();
    setExpanded(false);
  };

  const clearForm = () => {
    setForm({
      denominacion: null
    });
  };

  useEffect(() => {
    clearForm();
  }, [expanded]);

  return (
    <CustomAccordion sx={{ backgroundColor: "#f2f2f2" }} expanded={expanded}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ flexDirection: "row-reverse", gap: 2 }}
        onClick={() => setExpanded(!expanded)}
      >
        <Stack direction="row" gap={1}>
          <PostAdd sx={{ color: "#93ef8e" }} />
          <Typography fontWeight={500}>Crear método de pago</Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Stack gap={2}>
          <CustomTextField
            onChange={(e) => handleFormChange(e.target.name, e.target.value)}
            name="denominacion"
            label="Denominación"
            value={form["denominacion"]}
          />
          <Stack justifyContent="flex-end" direction="row">
            <CustomButton
              onClick={() => handleSubmit()}
              buttonColor="#1b75be"
              disabled={Object.values(form).some(
                (item) => item === null || item === undefined || item === ""
              )}
              variant="contained"
              startIcon={<Add />}
            >
              Crear
            </CustomButton>
          </Stack>
        </Stack>
      </AccordionDetails>
    </CustomAccordion>
  );
}
