import unformat from "accounting-js/lib/unformat.js";
import formatNumber from "accounting-js/lib/formatNumber";
import formatMoney from "accounting-js/lib/formatMoney";
import settings from "accounting-js/lib/settings.js";

settings.decimal = ",";
settings.thousand = ".";
settings.precision = 0;

export default class CurrencyService {
  public static formatInput(value) {
    value.replaceAll(new RegExp(/^[0-9]*$/g), "");

    let unformattedValue = String(unformat(value));

    return formatNumber(Number(unformattedValue));
  }

  public static formatStatic(value) {
    return formatMoney(value, {format: "%s %v"});
  }

  public static unformatStatic(value) {
    return unformat(value)
  }
}
