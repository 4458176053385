import { Box, Skeleton, Stack } from "@mui/material";
import { format, parse, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useFetch from "src/hooks/useFetch";
import { IPrestamo } from "src/utils/interfaces";
import { ESTADOS } from "src/utils/PrestamoEnums";
import CreateForm from "../CreateForm";
import Dia from "./Fields/dia";
import Observacion from "./Fields/observacion";
import Resumen from "./Fields/resumen";

export default function UpdateForm({
  getForm,
  handleFormChange,
  setFormField,
  loadForm,
}: {
  getForm: any;
  handleFormChange: any;
  setFormField: any;
  loadForm: any;
}) {
  const [searchParams] = useSearchParams();

  const [entity, setEntity] = useState<IPrestamo>(null);

  const { data, fetchData } = useFetch(
    "get",
    `prestamos/${searchParams.get("id")}`
  );

  const getEntity = async () => {
    await fetchData();
  };

  useEffect(() => {
    getEntity();
  }, []);

  useEffect(() => {
    if (data) {
      setEntity(() => data);
      loadForm({
        ...data,
        valorPrestamo: String(data["valorPrestamo"]),
        fechaEntrega: data["fechaEntrega"]
          ? format(
              parse(data["fechaEntrega"], "dd/MM/yyyy", new Date()),
              "yyyy-MM-dd"
            )
          : "",
      });
    }
  }, [data]);

  return (
    <>
      {!entity && <Stack gap={2}>
        <Skeleton variant="rounded" width={400} height={100} />
        <Skeleton variant="rounded" width={400} height={100} />
        <Skeleton variant="rounded" width={400} height={100} />
        </Stack>}
      {entity?.estadoId === ESTADOS.PENDIENTE && (
        <CreateForm
          handleFormChange={handleFormChange}
          getForm={getForm}
          setFormField={setFormField}
        />
      )}
      {entity?.estadoId === ESTADOS.ACTIVO && (
        <Stack gap={3}>
          <Resumen
            handleFormChange={handleFormChange}
            getForm={getForm}
            setFormField={setFormField}
            entity={data}
          />
          <Dia
            handleFormChange={handleFormChange}
            getForm={getForm}
            setFormField={setFormField}
          />
          <Observacion
            handleFormChange={handleFormChange}
            getForm={getForm}
            setFormField={setFormField}
          />
        </Stack>
      )}
    </>
  );
}
