import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'
import { IFormComponent } from 'src/hooks/useForm'
import CurrencyService from 'src/services/CurrencyService'

export default function Resumen({fields}) {

  const calcularValorCuota = (type: string | boolean = false, interesTotal, cantidadCuotas) => {
    if(fields.valorPrestamo){
      const result = (Number(fields.valorPrestamo)+(Number(fields.valorPrestamo)*Number(interesTotal)/100))/Number(cantidadCuotas)
      if(type === 'numeric') return Math.round(result)
      return CurrencyService.formatStatic(result)
    }
    if(type === 'numeric'){
      return null
    }
    return 'No calculable'
  }

  const calcularMontoARecaudar = (cantidadCuotas, interesTotal) => {
    if(calcularValorCuota('numeric', interesTotal, cantidadCuotas)) return CurrencyService.formatStatic(Number(cantidadCuotas)*calcularValorCuota('numeric', interesTotal, cantidadCuotas))
    return 'No calculable'
  }

  return (
    <Box sx={{ overflow: "auto",  maxHeight: '250px', border: '2px solid #acafe7' }}>
        <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={{ backgroundColor: "#dddef5" }}>Tipo de préstamo</TableCell>
                <TableCell sx={{ backgroundColor: "#dddef5" }}>Cantidad total de cuotas</TableCell>
                <TableCell sx={{ backgroundColor: "#dddef5" }}>Valor de la cuota</TableCell>
                <TableCell sx={{ backgroundColor: "#dddef5" }}>Interés total aplicado</TableCell>
                <TableCell sx={{ backgroundColor: "#dddef5" }}>Monto a recaudar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.opciones.map(opcion => (
              <TableRow key={Math.random()}>
                <TableCell>{opcion.tipo}</TableCell>
                <TableCell sx={{whiteSpace: 'nowrap'}}>{opcion.cantidadCuotas}</TableCell>
                <TableCell sx={{whiteSpace: 'nowrap'}}>{calcularValorCuota(false,opcion.interesTotal, opcion.cantidadCuotas)}</TableCell>
                <TableCell sx={{whiteSpace: 'nowrap'}}>{opcion.interesTotal}%</TableCell>
                <TableCell sx={{whiteSpace: 'nowrap'}}>{calcularMontoARecaudar(opcion.cantidadCuotas, opcion.interesTotal)}</TableCell>
              </TableRow>)
              )}
            </TableBody>
          </Table>
        </Box>
      </Box>
  )
}
