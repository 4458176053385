import React from "react";
import DocumentService from "src/services/DocumentService";
import Home from "../../components/base/Home";
import MiniDrawer from "../../components/layout/Drawer";

export default function HomePage() {

  DocumentService.setTitle('Inicio')

  return (
    <MiniDrawer>
      <Home/>
    </MiniDrawer>
  );
}
