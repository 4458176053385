import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import BorrarModal from "src/components/shared/Modals/BorrarModal";
import TablaEstandar, { IAction, IColumn } from "src/components/shared/Tabla";
import useTable from "src/hooks/useTable";
import CurrencyService from "src/services/CurrencyService";
import { IPago, IPrestamo } from "src/utils/interfaces";
import EntityForm from "./EntityForm";

export default function TableWrapper({ prestamo }: { prestamo: IPrestamo }) {
  const { isLoading, getData, data } = useTable(
    `pagos?prestamoId=${prestamo?.id}&order=fecha&order=desc`
  );

  const columns: IColumn<IPago>[] = [
    {
      property: "fecha",
      label: "Fecha del pago",
      isDate: true,
    },
    {
      property: "importe",
      label: "Valor del pago",
      format(row) {
        return row.importe
          ? `${CurrencyService.formatStatic(row.importe)}`
          : "--";
      },
    },
    {
      property: "metodoPago.denominacion",
      label: "Medio de pago",
    },
    {
      property: "observacion",
      label: "Observaciones",
      format(row) {
        return <i>{row.observacion ? row.observacion : "--"}</i>;
      },
    },
    {
      property: "esInteres",
      label: "Es pago de interés",
      format: (row) => (row.esInteres ? "Sí" : "No"),
    },
    {
      property: "creadoPor",
      label: "Creado por",
    },
  ];

  useEffect(() => {
    if (prestamo) {
      getData();
    }
  }, [prestamo]);

  return (
    <>
      <EntityForm />
      <TablaEstandar
        defaultRowsPerPage={50}
        isLoading={isLoading}
        rows={data}
        columns={columns}
      />
    </>
  );
}
