import { MoreVert } from "@mui/icons-material";
import { IconButton, Menu, MenuItem } from "@mui/material";
import React from "react";
import { IAction } from "../Tabla";

export default function CustomMenu({data, actions}) {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const openMenu = Boolean(menuAnchorEl);
  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>, row) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={(e) => handleClickMenu(e, data)}>
        <MoreVert />
      </IconButton>
      <Menu
        elevation={1}
        id="basic-menu"
        anchorEl={menuAnchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {actions.map((action: IAction) => (
          <MenuItem
            key={`${action.label}`}
            onClick={() => {
              handleCloseMenu();
              action.onClick(data);
            }}
            disabled={action.disabled ? action.disabled(data) : false}
            sx={{color: action.color}}
          >
            {action.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
