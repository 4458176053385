import _ from "lodash";
import React, { useEffect, useState } from "react";
import CustomTextField from "src/components/shared/Custom/TextField";
import FormService, { IFormField } from "src/services/FormService";

export interface IFormComponent {
  getForm: any;
  handleFormChange?: (target: any, formatInput?: (value: any) => any) => void;
  setFormField?: (field: string, value: any) => void
}

export interface IFormObject {
  [field: string]: {
    required?: boolean;
    value?: any;
  };
}

function useForm(
  form: IFormObject,
  formAction: () => Promise<void>,
  formFields?: IFormField[]
) {
  if (!form) {
    form = FormService.getFormObject(formFields);
  }

  const [entityForm, setEntityForm] = useState<IFormObject>(form);
  const [processingData, setProcessingData] = useState<boolean>(false);

  const sendRequest = async () => {
    setProcessingData(() => true);
    await formAction();
    setProcessingData(() => false);
  };

  const handleFormChange = (target, formatInput?: (value) => any) => {
    function getValue(target: HTMLInputElement) {
      let value: string | boolean;
      if (target.hasOwnProperty("checked")) {
        value = target.checked;
      } else {
        value = target.value;
      }
      if (formatInput) {
        value = formatInput(value);
      }
      return value;
    }

    setEntityForm(() => ({
      ...entityForm,
      [target.name]: {
        ...entityForm[target.name],
        value: getValue(target),
      },
    }));
  };

  const saveButtonState = () => {
    if (processingData) {
      return true;
    } else {
      const fields = Object.keys(entityForm);
      return fields.some((field: string) => {
        const value = entityForm[field].value;
        const required = entityForm[field].required;
        if (required && (value === "" || !value)) {
          return true;
        }
        return false;
      });
    }
  };

  const getForm = () => {
    const fields = Object.keys(entityForm);
    const cleanForm: { [key: string]: any } = {};
    for (const field of fields) {
      cleanForm[field] = entityForm[field].value ?? "";
    }
    return cleanForm;
  };

  const getSendForm = () => {
    const fields = Object.keys(entityForm);
    const cleanForm: { [key: string]: any } = {};
    for (const field of fields) {
      cleanForm[field] =
        entityForm[field].value === "" ? null : entityForm[field].value;
    }
    return cleanForm;
  };

  const getFormBuild = () => {
    return formFields
      .filter((formField) => formField.label)
      .map(
        ({
          name,
          label,
          order,
          helperText,
          formatDisplay,
          formatInput,
          helperName,
          onChange,
        }) => (
          <CustomTextField
            key={name}
            label={label}
            placeholder={label}
            sx={{
              minWidth: "240px",
              flexGrow: 1,
              order: order ?? "",
              ".MuiFormHelperText-root": {
                color: getForm()?.[helperName] ? "#0060A6" : "#666666",
                marginX: 0,
              },
            }}
            name={name}
            value={
              formatDisplay ? formatDisplay(getForm()?.[name]) : getForm()?.[name]
            }
            onChange={(e) => {
              handleFormChange(e.target, formatInput);
              onChange && onChange();
            }}
            helperText={helperText && helperText(getForm()?.[name])}
          />
        )
      );
  };

  const setFormField = (field: string, value: any): void => {
    if (!entityForm.hasOwnProperty(field)) {
      return;
    }
    setEntityForm((entityForm) => ({
      ...entityForm,
      [field]: {
        ...entityForm[field],
        value,
      },
    }));
  };

  const loadEntity = (entity): void => {
    const cleanedEntity: IFormObject = _.pick(entity, Object.keys(entityForm));
    for (const cleanedEntityItem in cleanedEntity) {
      cleanedEntity[cleanedEntityItem] = {
        required: entityForm[cleanedEntityItem].required,
        value: cleanedEntity[cleanedEntityItem],
      };
    }
    setEntityForm(cleanedEntity);
  };

  const clearForm = (): void => {
    setEntityForm(form)
  }

  return {
    saveButtonState,
    handleFormChange,
    getForm,
    sendRequest,
    setProcessingData,
    getFormBuild,
    setFormField,
    loadEntity,
    getSendForm,
    clearForm
  };
}

export default useForm;
