import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { Collapse, IconButton, Menu, MenuItem, Stack } from "@mui/material";
import CustomSkeleton from "../Custom/Skeleton";
import CustomTextField from "../Custom/TextField";
import { MoreVert, Search } from "@mui/icons-material";
import _ from "lodash";
import Row from "./Row";
import { IColumn } from "../Tabla";
import useTable from "src/hooks/useTable";

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: "asc" | "desc";
  orderBy: string;
  rowCount: number;
  headCells: IColumn<any>[];
  collapsable: (row?: any) => React.ReactNode;
  actions: IAction[];
  header: boolean;
  customHeader: React.ReactNode;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    order,
    orderBy,
    onRequestSort,
    headCells,
    collapsable,
    actions,
    header,
    customHeader,
  } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead sx={header && { backgroundColor: "#DDDEF5" }}>
      <TableRow>
        {collapsable && <TableCell />}
        {headCells?.map((headCell) => (
          <TableCell
            key={headCell.property}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.property ? order : false}
            sx={!header && { opacity: 0, maxHeight: 0, padding: 0, margin: 0 }}
          >
            <TableSortLabel
              active={orderBy === headCell.property}
              direction={orderBy === headCell.property ? order : "asc"}
              onClick={createSortHandler(headCell.property)}
              disabled={headCell.notSortable}
            >
              {headCell.label}
              {orderBy === headCell.property ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {actions && <TableCell key={"actions"} width={2}></TableCell>}
      </TableRow>
      {!header && customHeader}
    </TableHead>
  );
}

export function CellGenerator({
  row,
  column: { property, format, numeric, sx },
}: {
  row;
  column: IColumn<any>;
}) {
  return (
    <TableCell scope="row" key={`${row.id}.${property}`} sx={sx}>
      {format
        ? format(row, _.get(row, property))
          ? format(row, _.get(row, property))
          : "--"
        : _.get(row, property)
        ? _.get(row, property)
        : "--"}
    </TableCell>
  );
}

export interface IAction {
  label: string;
  onClick: (row?: any) => void;
  disabled?: (row?: any) => boolean;
  color?: string;
  hide?: (row?: any) => boolean;
}

interface ITablaBack {
  columns: IColumn<any>[];
  searchBar?: boolean;
  filters?: React.ReactNode;
  actions?: IAction[];
  collapsable?: (row?: any) => React.ReactNode;
  searchPlaceholder?: string;
  header?: boolean;
  pagination?: boolean;
  customHeader?: React.ReactNode;
  defaultRowsPerPage?: number;
  entity: string;
  searchFields: string[];
  filtersParam: string;
}

export default function TablaBack({
  columns,
  searchBar = false,
  filters,
  actions,
  collapsable,
  searchPlaceholder = null,
  header = true,
  pagination = true,
  customHeader,
  defaultRowsPerPage = 5,
  entity,
  searchFields,
  filtersParam,
}: ITablaBack) {
  const [order, setOrder] = React.useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = React.useState<string>("");
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(defaultRowsPerPage);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage ? newPage : 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const [api, setApi] = React.useState<string>(
    `${entity}?page=${page}&limit=${rowsPerPage}`
  );

  const { isLoading, getData, data: rows, metadata } = useTable(api);

  React.useEffect(() => {
    getData();
  }, [api]);

  const [search, setSearch] = React.useState<string>("");

  const [timeoutSearch, setTimeoutSearch] = React.useState<any>(false);

  const setterSearch = (input) => {
    setTimeoutSearch(true)
    setTimeout(() => setTimeoutSearch(false), 2000);
    setSearch(input);
  };

  function buildApi() {
    if (timeoutSearch) return;
    let builder = `${entity}?page=${page}&limit=${rowsPerPage}`;
    if (filtersParam && !builder?.includes(filtersParam)) builder += filtersParam;
    if (search) {
      builder += `&search=${search}`;
      searchFields.forEach((field) => {
        builder += `&search=${field}`;
      });
    }
    if (order && orderBy) {
      builder += `&order=${orderBy}&order=${order}`;
    }
    if (filtersParam?.length) {
      builder += filtersParam;
    }
    setApi(builder);
  }

  React.useEffect(() => {
    !timeoutSearch && buildApi();
  }, [timeoutSearch]);

  React.useEffect(() => {
    buildApi();
  }, [order, orderBy, page, rowsPerPage, search, filtersParam]);

  React.useEffect(() => {
    setPage(1);
  }, [filtersParam, search]);

  return (
    <>
      {searchBar && (
        <Stack
          p={2}
          paddingBottom={5}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          flexWrap={"wrap"}
        >
          <CustomTextField
            disabled={isLoading}
            placeholder={
              searchPlaceholder ?? "Buscar por dato de la tabla o nombre"
            }
            label="Buscador"
            onChange={(e) => setterSearch(e.target.value)}
            value={search}
            InputProps={{
              startAdornment: <Search sx={{ marginRight: 1 }} />,
            }}
            sx={{
              width: "100%",
              maxWidth: "327px",
            }}
          ></CustomTextField>
          {filters}
        </Stack>
      )}
      <Box sx={{ width: "100%" }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
              headCells={columns}
              collapsable={collapsable}
              actions={actions}
              header={header}
              customHeader={customHeader}
            />
            {Boolean(!rows?.length) && isLoading && (
              <>
                {columns?.map((item) => (
                  <TableRow key={_.indexOf(columns, item)}>
                    {columns?.map((column) => (
                      <TableCell key={column.property}>
                        <CustomSkeleton
                          value={""}
                          loading={isLoading}
                          variant="rectangular"
                          width="100%"
                          height="30px"
                        ></CustomSkeleton>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </>
            )}
            <TableBody>
              {rows?.map((row) => (
                <Row
                  key={row.id}
                  row={row}
                  columns={columns}
                  collapsable={collapsable}
                  actions={actions}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {pagination && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={metadata?.total || 0}
            rowsPerPage={metadata?.per_page || 0}
            page={metadata?.current_page || 0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            showFirstButton
            showLastButton
          />
        )}
      </Box>
    </>
  );
}
