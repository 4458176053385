import { Delete } from "@mui/icons-material";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BorrarModal from "src/components/shared/Modals/BorrarModal";
import useTable from "src/hooks/useTable";

export default function TableWrapper({ refresher }) {
  const { data, getData } = useTable("opciones-intereses?order=tipo&order=asc");

  useEffect(() => {
    getData();
  }, [refresher]);

  const [openBorrarModal, setOpenBorrarModal] = useState<boolean>(false);
  const [deleteEntity, setDeleteEntity] = useState(null);

  return (
    <>
      <BorrarModal
        entity={deleteEntity}
        endpoint="opciones-intereses"
        type="interés"
        open={openBorrarModal}
        handleClose={() => setOpenBorrarModal(false)}
        onDelete={() => {
          setOpenBorrarModal(false);
          getData();
        }}
      />
      <TableContainer sx={{ border: "2px solid #c3c5f2" }} component={Paper}>
        <Table size="small">
          <TableHead sx={{ backgroundColor: "#dddef5" }}>
            <TableRow>
              <TableCell sx={{ textAlign: "center" }}>Tipo</TableCell>
              <TableCell sx={{ textAlign: "center" }}>Descripción</TableCell>
              <TableCell sx={{ textAlign: "center" }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Boolean(data) &&
              data
                .filter((item) => item.cantidadCuotas)
                .map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{row.tipo}</TableCell>
                    <TableCell>{row.descripcion}</TableCell>
                    <TableCell>
                      <IconButton
                        sx={{ color: "#f2788e" }}
                        onClick={() => {
                          setDeleteEntity(row);
                          setOpenBorrarModal(true);
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
