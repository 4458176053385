import { Checkbox, checkboxClasses, CheckboxProps } from "@mui/material";
import React from "react";

export default function CustomCheckbox({...props}: CheckboxProps) {
  return (
    <Checkbox
      {...props}
      sx={{
        [`&, &.${checkboxClasses.checked}`]: {
          color: "#0060A6",
        },
        [`&, &.${checkboxClasses.colorSecondary}`]: {
          color: "#666666",
        },
        ...props.sx
      }}
    />
  );
}
