import { Select, SelectProps } from "@mui/material";
import React, { useState } from "react";
import _ from 'lodash'

interface ICustomSelect extends SelectProps {
  children: React.ReactNode;
}

export default function CustomSelect({ children, ...props }: ICustomSelect) {
    const [active, setActive] = useState(false)

    function getBorderColor(){
      if(props.error){
        return '#d32f2f'
      }

      if(active){
        return 'primary.main'
      }

      if(props.value){
        return 'secondary.main'
      }

      return "#0000003b"
    }

  return (
    <Select
      disableUnderline
      onFocus={()=>setActive(true)}
      onBlur={()=>setActive(false)}
      variant={props.variant ? props.variant : "standard"}
      MenuProps={{
        style: {
           maxHeight: 200,
              },
        }}
      sx={{
        boxShadow: "none",
        ".MuiOutlinedInput-notchedOutline": { borderWidth: props.value && 2, borderColor: props.value && "secondary.main"},
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: getBorderColor()
          },
          ...props.sx
      }}
      {..._.omit(props, ['sx'])}
    >
      {children}
    </Select>
  );
}
