import {
  Box,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Close from "@mui/icons-material/Close";
import CustomButton from "../Custom/Button";
import CustomDialog from "../Custom/Dialog";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { ICliente } from "src/utils/interfaces";
import _ from "lodash";
import { IGpsButton } from "./GpsButton";

interface IGpsModal {
  open: boolean;
  handleClose: () => void;
  entity: ICliente | IGpsButton
}

export interface IFormattedGpsModal {
  type: string;
  link: string;
  direction: string;
}

export default function GpsModal({ open, handleClose, entity }: IGpsModal) {
  const openGmaps = (link: string) => {
    window.open(link, "_blank", "location=yes");
  };

  const [gpsModalLinks, setGpsModalLinks] = useState<IFormattedGpsModal[]>([]);

  useEffect(() => {
    if (open && entity) {
      handleOpenGpsModal(entity);
    }
  }, [open, entity]);

  const handleOpenGpsModal = (row: ICliente | IGpsButton) => {
    const formattedGps: IFormattedGpsModal[] = [];
    if (row.gpsTrabajo) {
      formattedGps.push({
        type: "D. Laboral",
        link: row.gpsTrabajo,
        direction: row.direccionTrabajo,
      });
    }
    if (row.gpsPersonal) {
      formattedGps.push({
        type: "D. Personal",
        link: row.gpsPersonal,
        direction: row.direccionPersonal,
      });
    }
    setGpsModalLinks(formattedGps);
  };

  return (
    <CustomDialog
      open={open}
      onClose={() => handleClose()}
    >
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between" gap={5}>
          <Box fontSize={16} fontWeight={400}>
            Elige una dirección para abrir en google maps:
          </Box>
          <Box>
            <IconButton onClick={() => handleClose()}>
              <Close />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Stack gap={3}>
          {gpsModalLinks.map((gps: IFormattedGpsModal) => (
            <CustomButton
              onClick={() => openGmaps(gps.link)}
              key={_.indexOf(gpsModalLinks, gps)}
              startIcon={<LocationOnOutlinedIcon />}
              variant="contained"
              fullWidth
              buttonColor="#EA8EEF"
            >
              {gps.type}: {gps.direction}
            </CustomButton>
          ))}
        </Stack>
      </DialogContent>
    </CustomDialog>
  );
}
