import { CalendarMonth, CalendarViewDay } from "@mui/icons-material";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import CustomSelect from "src/components/shared/Custom/Select";
import { IFormComponent } from "src/hooks/useForm";
import { IPrestamo } from "src/utils/interfaces";
import {
  SUBTIPOSMensual,
  SUBTIPOSSemanal,
  TIPOSEnum,
} from "src/utils/PrestamoEnums";

export default function Dia({handleFormChange, setFormField, getForm}: IFormComponent) {
  return (
    <Stack gap={1}>
      <Stack direction={"row"} alignItems={"center"} gap={1}>
        <CalendarMonth sx={{ color: "#1B23BE" }} />
        <Typography fontSize={18} fontWeight={400} color={"black"}>
          Cambiar día de cobro
        </Typography>
      </Stack>
      <FormControl sx={{ flexGrow: 1 }}>
        <InputLabel sx={{ color: getForm()?.subtipo ? "secondary.main" : "black" }}>
          Día a cobrar
        </InputLabel>
        <CustomSelect
          label="Día a cobrar"
          variant="outlined"
          placeholder="Elegir una opción"
          sx={{ minWidth: "240px", width: "100%" }}
          name="subtipo"
          value={getForm()?.subtipo}
          disabled={!getForm()?.tipo}
          onChange={(e) => handleFormChange(e.target)}
        >
          {getForm()?.tipo === TIPOSEnum.MENSUAL &&
            SUBTIPOSMensual.map((subtipo) => (
              <MenuItem key={subtipo} value={subtipo}>
                {subtipo}
              </MenuItem>
            ))}
          {getForm()?.tipo === TIPOSEnum.SEMANAL &&
            SUBTIPOSSemanal.map((subtipo) => (
              <MenuItem key={subtipo} value={subtipo}>
                {subtipo}
              </MenuItem>
            ))}
        </CustomSelect>
      </FormControl>
    </Stack>
  );
}
