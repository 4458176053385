import React from 'react'
import DocumentService from 'src/services/DocumentService'
import Login from '../../components/base/Login'

export default function LoginPage() {

  DocumentService.setTitle('Acceso')

  return (
    <Login/>
  )
}
