import { Schedule } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { IFormComponent } from "src/hooks/useForm";
import { format, parse } from "date-fns";
import CustomDatePicker from "src/components/shared/Custom/DatePicker";

interface IFechaEntrega extends IFormComponent {}

export default function FechaEntrega({
  handleFormChange,
  getForm,
}: IFechaEntrega) {
  return (
    <Stack gap={1}>
      <Stack direction={"row"} alignItems={"center"} gap={1}>
        <Schedule sx={{ color: "#1B23BE" }} />
        <Typography fontSize={18} fontWeight={400} color={"black"}>
          Fecha de entrega
        </Typography>
      </Stack>
      <CustomDatePicker
        value={
          getForm()?.fechaEntrega
            ? parse(getForm()?.fechaEntrega, "yyyy-MM-dd", new Date())
            : null
        }
        onChange={(e) => {
          handleFormChange({ value: e, name: "fechaEntrega" }, (value) =>
            format(value, "yyyy-MM-dd")
          );
        }}
        label="Fecha de entrega"
      />
    </Stack>
  );
}
