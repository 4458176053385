import axios from "axios"
import AuthService from "./AuthService"

declare const process: {
    env: {
      NODE_ENV: string;
      REACT_APP_API_URL: string;
    }
  }

export default class ApiService {

    private static baseUrl = (process.env.REACT_APP_API_URL as string)

    public static async login(credentials){
        try {
            return await axios.post(`${this.baseUrl}/login`, null, {auth: credentials})
        } catch (error) {
            return error
        }
    }

    public static async isLogged(){
        let isLogged = false
        try {
            const auth = AuthService.getAuth()
            const {success} = (await axios.post(`${this.baseUrl}/login`, null, {auth})).data
            isLogged = success
        } catch (error) {
            return error
        }
        return isLogged
    }

    public static async get(endpoint: string){
        try {
            const auth = AuthService.getAuth()
            const url = `${this.baseUrl}/${endpoint}`
            return await axios.get(url, {auth})
        } catch (error) {
            return error
        }
    }

    public static async post(endpoint: string, payload: any){
        try {
            const auth = AuthService.getAuth()
            const url = `${this.baseUrl}/${endpoint}`
            return await axios.post(url, payload, {auth})
        } catch (error) {
            return error
        }
    }

    public static async put(endpoint: string, payload: any){
        try {
            const auth = AuthService.getAuth()
            const url = `${this.baseUrl}/${endpoint}`
            return await axios.put(url, payload, {auth})
        } catch (error) {
            return error
        }
    }

    public static async delete(endpoint: string){
        try {
            const auth = AuthService.getAuth()
            const url = `${this.baseUrl}/${endpoint}`
            return await axios.delete(url, {auth})
        } catch (error) {
            return error
        }
    }

}