import { Stack } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomMenu from "src/components/shared/Custom/Menu";
import BorrarModal from "src/components/shared/Modals/BorrarModal";
import CompartirModal from "src/components/shared/Modals/CompartirModal";
import { IWhatsappButton } from "src/components/shared/Modals/WhatsappButton";
import { IAction } from "src/components/shared/Tabla";
import { ICliente } from "src/utils/interfaces";
import EntityForm from "../../EntityForm";
import Header from "./Header";
import AuthService from "src/services/AuthService";
import { Roles } from "src/utils/RolEnum";

export default function Infobar({ client }: {client: ICliente}) {

    let [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()

    const openEntityForm = () => {
      client && setSearchParams({type: "update", entity: "cliente", id: client?.id as unknown as string,})
    }

  const actions: IAction[] = [
    {
      label: "Editar datos del cliente",
      onClick(row) {
        openEntityForm()
      },
    },
    {
      label: "Compartir cliente",
      onClick(row) {
        setOpenCompartir(true)
      },
    },
    {
      label: "Borrar cliente",
      onClick(row) {
        setOpenBorrar(true)
      },
      color: "#F2788E",
      disabled(row) {
        return AuthService.getAuth().rolId !== Roles.ADMINISTRADOR
      },
    },
  ];

  const [openCompartir, setOpenCompartir] = useState<boolean>(false)
  const [openBorrar, setOpenBorrar] = useState<boolean>(false)

  return (
    <>
      <BorrarModal open={openBorrar} entity={client} type={'cliente'} onDelete={() => {navigate('/clientes')}} handleClose={() => {setOpenBorrar(false)}} endpoint={'clientes'} />
      <CompartirModal telefonos={_.pick(client, ['telefono', 'telefonoWp', 'telefonoAlt', 'telefonoAltWp']) as IWhatsappButton} clienteName={client?.alias} prestamos={client?.prestamos} type="cliente" open={openCompartir} handleClose={() => setOpenCompartir(false)} />
      <EntityForm />
      <Stack
        direction="row"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="space-between"
        p={2}
        paddingTop={0}
      >
        <Header data={client} />
        <CustomMenu data={client} actions={actions} />
      </Stack>
    </>
  );
}
