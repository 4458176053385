import { Stack } from '@mui/material'
import React, { useState } from 'react'
import Totales from './Totales'
import TableWrapper from './TableWrapper'
import { IFinanzasFilter } from '../..'

export default function Results({filter, api}: {filter: IFinanzasFilter, api: string}) {

  return (
    <Stack gap={4} minWidth={'75%'} maxWidth={'100%'}>
      <Totales filter={filter} api={api}/>
      <TableWrapper filter={filter} api={api}/>
    </Stack>
  )
}
