import { InsertComment } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import React from "react";
import CustomTextField from "src/components/shared/Custom/TextField";
import { IFormComponent } from "src/hooks/useForm";
import { IPrestamo } from "src/utils/interfaces";

export default function Observacion({setFormField, getForm, handleFormChange}: IFormComponent) {
  return (
    <Stack gap={1}>
      <Stack direction={"row"} alignItems={"center"} gap={1}>
        <InsertComment sx={{ color: "#1B23BE" }} />
        <Typography fontSize={18} fontWeight={400} color={"black"}>
          Observación
        </Typography>
      </Stack>
      <CustomTextField
        name="observacion"
        placeholder="Escribe un comentario"
        sx={{ minWidth: "240px", width: "100%" }}
        value={getForm()?.observacion}
        onChange={(e) => handleFormChange(e.target)}
      ></CustomTextField>
    </Stack>
  );
}
