import { MoreVert, RoomOutlined, WhatsApp } from "@mui/icons-material";
import { IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButton from "src/components/shared/Custom/Button";
import GpsModal, {
  IFormattedGpsModal,
} from "src/components/shared/Modals/GpsModal";
import WhatsappModal, {
  IFormattedWpModal,
} from "src/components/shared/Modals/WhatsappModal";
import CurrencyService from "src/services/CurrencyService";
import { ICliente, IPrestamo } from "src/utils/interfaces";
import { ESTADOS } from "src/utils/PrestamoEnums";
import useTable from "../../../hooks/useTable";
import TablaEstandar, { IAction, IColumn } from "../../shared/Tabla";
import { parse } from "date-fns";
import TableFilters from "./TableFilters";

function getFormattedEstado(estadoId) {
  let color;
  let text;

  if (estadoId === ESTADOS.ACTIVO) {
    color = "#09AD00";
    text = "Activo";
  }
  if (estadoId === ESTADOS.PENDIENTE) {
    color = "#767676";
    text = "Pendiente";
  }
  if (estadoId === ESTADOS.FINALIZADO) {
    color = "#F2788E";
    text = "Finalizado";
  }

  return <span style={{ color, fontWeight: "bold" }}>{text}</span>;
}

export default function TableWrapper() {
  const [api, setApi] = useState<string>(
    `prestamos?estadoId=${ESTADOS.PENDIENTE}`
  );

  const [estadoId, setEstadoId] = useState<number>(ESTADOS.PENDIENTE)

  const searchFields: string[] = [
    "cliente.alias",
    "cliente.nombre"
  ];

  const { isLoading, getData, data, setSearch, search } = useTable(
    api,
    searchFields
  );

  const verifyMinimum = (row: ICliente, field: "GPS" | "WHATSAPP") => {
    if (field === "GPS") {
      return Boolean(row.gpsPersonal) || Boolean(row.gpsTrabajo);
    } else {
      return row.telefonoWp || row.telefonoAltWp;
    }
  };

  const columns: IColumn<IPrestamo>[] = [
    {
      property: "cliente.alias",
      label: "Alias cliente",
      format(row) {
        return (
          <Typography
            fontWeight={500}
            onClick={() => navigate(`/clientes/${row?.cliente?.id}`)}
            sx={{ ":hover": {
              cursor: 'pointer',
              textDecoration: 'underline'
            } }}
          >
            {row?.cliente?.alias}
          </Typography>
        );
      },
    },
    {
      property: "estadoId",
      label: "Estado",
      format: (row) => getFormattedEstado(row.estadoId),
      notSortable: true,
    },
    {
      property: "tipo",
      label: "Tipo de préstamo",
      format(row) {
        return (
          <span>
            {row.tipo ?? "No definido"} - <i>{row.subtipo ?? "No definido"}</i>
          </span>
        );
      },
      notSortable: true,
    },
    {
      property: "valorPrestamo",
      label: "Monto del préstamo",
      format(row) {
        return (
          <span>
            {row.valorPrestamo
              ? CurrencyService.formatStatic(row.valorPrestamo)
              : "No calculable"}
          </span>
        );
      },
    },
    {
      property: "cuotasCompletadas",
      label: "C. completadas / c. totales",
      notSortable: true,
      format(row) {
        return (
          <span>{`${row.cuotasCompletadas}/${
            row.cantidadCuotas ?? "No definido"
          }`}</span>
        );
      },
    },
    {
      property: "pagos",
      label: "Último pago",
      format(row: IPrestamo) {
        if (!row.pagos.length) return "--";
        row.pagos.sort(function compare(a, b): any {
          const A = parse(a.fecha, "dd/MM/yyyy", new Date()).toISOString();
          const B = parse(b.fecha, "dd/MM/yyyy", new Date()).toISOString();
          if (B < A) {
            return -1;
          }
          if (B > A) {
            return 1;
          }
          return 0;
        });
        const ultimoPago = row.pagos[0];
        return (
          <Stack>
            <span>{CurrencyService.formatStatic(ultimoPago.importe)}</span>
            <span>{ultimoPago.fecha}</span>
          </Stack>
        );
      },
      notSortable: true,
    },
    {
      property: "telefono",
      label: "Teléfono",
      format(row) {
        return (
          <CustomButton
            variant="contained"
            buttonColor="#8EC3EF"
            disabled={!verifyMinimum(row.cliente, "WHATSAPP")}
            onClick={() => handleOpenWpModal(row.cliente)}
          >
            <WhatsApp />
          </CustomButton>
        );
      },
      notSortable: true,
    },
    {
      property: "direccion_trabajo",
      label: "Dirección",
      format(row) {
        return (
          <CustomButton
            variant="contained"
            buttonColor="#EA8EEF"
            disabled={!verifyMinimum(row.cliente, "GPS")}
            onClick={() => handleOpenGpsModal(row.cliente)}
          >
            <RoomOutlined />
          </CustomButton>
        );
      },
      notSortable: true,
    },
    {
      property: estadoId === ESTADOS.PENDIENTE ? 'fechaEntrega' : "observacion",
      label: estadoId === ESTADOS.PENDIENTE ? 'Fecha de entrega' : "Observación",
      sx: estadoId === ESTADOS.PENDIENTE ? {fontWeight: 500} : {
        fontWeight: 300,
        fontStyle: "italic",
      },
      isDate: estadoId === ESTADOS.PENDIENTE ? true : false
    },
    {
      property: "createdAt",
      label: "Fecha de creación",
      isDate: true,
    },
  ];

  const [openWhatsappModal, setOpenWhatsappModal] = useState<boolean>(false);
  const [wpEntity, setWpEntity] = useState<ICliente>(null);
  const handleOpenWpModal = (row: ICliente) => {
    setWpEntity(() => row);
    setOpenWhatsappModal(true);
  };

  const [openGpsModal, setOpenGpsModal] = useState<boolean>(false);
  const [gpsEntity, setGpsEntity] = useState<ICliente>(null);
  const handleOpenGpsModal = (row: ICliente) => {
    setGpsEntity(() => row);
    setOpenGpsModal(true);
  };

  const navigate = useNavigate();
  const location = useLocation();

  const actions: IAction[] = [
    {
      label: "Ver detalles del préstamo",
      onClick(row: IPrestamo) {
        navigate(row.estadoId === ESTADOS.FINALIZADO ? `/clientes/${row.clienteId}?prestamoId=${row.id}&finalizado` : `/clientes/${row.clienteId}?prestamoId=${row.id}`);
      },
    },
    {
      label: "Ver cliente",
      onClick: (row: IPrestamo) => {
        navigate(`/clientes/${row.clienteId}`);
      },
    },
  ];

  return (
    <>
      <GpsModal
        entity={gpsEntity}
        open={openGpsModal}
        handleClose={() => setOpenGpsModal(false)}
      />
      <WhatsappModal
        entity={wpEntity}
        open={openWhatsappModal}
        handleClose={() => setOpenWhatsappModal(false)}
      />
      <TablaEstandar
        isLoading={isLoading}
        rows={data}
        columns={columns}
        searchBar={true}
        search={search}
        setterSearch={setSearch}
        searchPlaceholder="Buscar por alias o nombre del cliente"
        actions={actions}
        filters={<TableFilters isLoading={isLoading} setApi={setApi} setEstadoId={setEstadoId} />}
      />
    </>
  );
}
