import { Dialog, DialogActions, DialogActionsProps, DialogProps } from "@mui/material";
import _ from "lodash";
import React from "react";

interface ICustomDialog extends DialogProps {
  children: React.ReactNode;
}

interface ICustomDialogActions extends DialogActionsProps {
  children: React.ReactNode
}

export default function CustomDialog({ children, ...props }: ICustomDialog) {
  return (
    <Dialog {...props} PaperProps={{ sx: { borderRadius: "16px", zIndex: "9999" } }}>
      {children}
    </Dialog>
  );
}

export function CustomDialogActions({children, ...props}: ICustomDialogActions) {
  return (
    <DialogActions sx={{paddingX: 3, paddingBottom: 3, ...props.sx}} {..._.omit(props, ['sx'])}>
      {children}
    </DialogActions>
  )
}