import React, { useState } from "react";
import { useSnackbar } from "react-simple-snackbar";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Stack } from "@mui/system";
import { isMobile } from "react-device-detect";

function useSnack() {
  const [openSnackbarOriginal, closeSnackbar] = useSnackbar({
    position: isMobile ? "bottom-right" : "top-right",
    style: { backgroundColor: "#FCCBFF", width: "30%", maxWidth: "630px", borderRadius: '16px'},
    closeStyle: {
      color: "black",
    },
  });

  function openSnackbar(text: string) {
    openSnackbarOriginal(
      <Stack direction="row" width={"100%"} gap={2} color="black" alignItems='center'>
        <InfoOutlinedIcon />
        {text}
      </Stack>,
      2500
    );
  }

  return [openSnackbar];
}

export default useSnack;
