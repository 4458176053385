import {
  AttachMoney,
  Close,
  MonetizationOn,
  MonetizationOnOutlined,
  PersonAdd,
} from "@mui/icons-material";
import { DialogContent, DialogTitle, IconButton, Stepper } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import CustomButton from "src/components/shared/Custom/Button";
import CustomCheckbox from "src/components/shared/Custom/Checkbox";
import CustomDialog, {
  CustomDialogActions,
} from "src/components/shared/Custom/Dialog";
import useForm, { IFormObject } from "src/hooks/useForm";
import useSnack from "src/hooks/useSnackbar";
import ApiService from "src/services/ApiService";
import CurrencyService from "src/services/CurrencyService";
import { IFormField } from "src/services/FormService";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import useFetch from "src/hooks/useFetch";
import CreateForm from "./CreateForm";
import UpdateForm from "./UpdateForm";
import { ESTADOS } from "src/utils/PrestamoEnums";

export default function EntityForm() {
  const endpoint: string = "prestamos";

  let [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();
  const [type, setType] = useState<"create" | "update">("create");

  const [open, setOpen] = useState<boolean>(false);

  const handleClose = () => {
    setSearchParams({});
  };

  useEffect(() => {
    if (searchParams.get("type") && searchParams.get("entity") === "prestamo") {
      setOpen(true);
      setType(searchParams.get("type") as "create" | "update");
    } else {
      setOpen(false);
      clearForm()
    }
  }, [searchParams]);

  const [openSnackbar] = useSnack();

  const sendForm = async () => {

      const method = type === "create" ? "post" : "put";
      const response = await ApiService[method](
        type === "create" ? endpoint : `${endpoint}/${searchParams.get("id")}`,
        {...getSendForm(), clienteId: params?.id}
      );

      if (response?.response?.status === 400) {
        openSnackbar(
          `Hubo un error al intentar ${
            type === "create" ? "crear" : "editar"
          }  el préstamo.`
        );
      } else {
        setSearchParams({})
        openSnackbar(
          `Se ha ${type === "create" ? "creado un nuevo" : "editado un"} préstamo`
        );
        clearForm()
      }
  };

  const fields: IFormObject = {
    estadoId: { required: true, value: ESTADOS.PENDIENTE },
    fechaEntrega: { required: false, value: null },
    tipo: { required: true, value: null },
    subtipo: { required: true, value: null },
    valorPrestamo: { required: true, value: null },
    cantidadCuotas: { required: true, value: null },
    valorCuota: { required: true, value: null },
    observacion: { required: false, value: null },
    interesTotal: { required: false, value: null }
  };

  const { getForm, getSendForm, handleFormChange, setFormField, saveButtonState, sendRequest, clearForm, loadEntity} = useForm(fields, sendForm);

  return (
    <CustomDialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap={5}
        >
          <Box
            fontSize={18}
            fontWeight={500}
            display={"flex"}
            alignItems="center"
            gap={2}
          >
            {type === "create" ? (
              <AttachMoney
                sx={{
                  fontSize: 32,
                  color: "white",
                  background: "#8EC3EF",
                  borderRadius: "100%",
                  height: 30,
                  width: 30,
                  padding: 0.5,
                }}
              />
            ) : (
              <BorderColorOutlinedIcon
                sx={{
                  fontSize: 32,
                  color: "white",
                  background: "#8EC3EF",
                  borderRadius: "100%",
                  height: 30,
                  width: 30,
                  padding: 0.5,
                }}
              />
            )}
            {type === "create" ? "Agregar préstamo" : "Editar préstamo"}
          </Box>
          <Box>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box paddingTop={1} display="flex" flexWrap="wrap" gap={3}>
          {type === 'create' ? <CreateForm getForm={getForm} handleFormChange={handleFormChange} setFormField={setFormField} /> : <UpdateForm loadForm={loadEntity} getForm={getForm} handleFormChange={handleFormChange} setFormField={setFormField} />}
        </Box>
      </DialogContent>
      <CustomDialogActions>
        <CustomButton
          variant="contained"
          buttonColor="#1B75BE"
          disabled={getForm()?.estadoId === ESTADOS.PENDIENTE ? false : saveButtonState()}
          onClick={sendRequest}
        >
          {type === "create" ? "Agregar préstamo" : "Guardar"}
        </CustomButton>
      </CustomDialogActions>
    </CustomDialog>
  );
}
