import { Stack } from '@mui/material'
import React from 'react'
import SpecificDate from './SpecificDate'
import SpecificMonth from './SpecificMonth'
import BetweenDates from './BetweenDates'
import { IFinanzasFilter } from '../..'

export default function Filters({filter, setFilter, handleSearch}: {handleSearch: () => void, filter: IFinanzasFilter, setFilter: React.Dispatch<React.SetStateAction<IFinanzasFilter>>}) {
  return (
    <Stack gap={4} maxWidth={'100%'} minWidth={'20%'}>
      <SpecificDate handleSearch={handleSearch} filter={filter} setFilter={setFilter} />
      <SpecificMonth handleSearch={handleSearch} filter={filter} setFilter={setFilter} />
      <BetweenDates handleSearch={handleSearch} filter={filter} setFilter={setFilter} />
    </Stack>
  )
}
