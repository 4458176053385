import {
  History,
  InfoOutlined,
  LocalPhone,
  LocationOnOutlined,
  NoteAddOutlined,
  PriceCheckOutlined,
  RoomOutlined,
  WhatsApp,
} from "@mui/icons-material";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { parse } from "date-fns";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import CustomButton from "src/components/shared/Custom/Button";
import CurrencyService from "src/services/CurrencyService";
import { ICliente, IPrestamo } from "src/utils/interfaces";
import { ESTADOS } from "src/utils/PrestamoEnums";
import TableWrapper from "./Pagos/TableWrapper";
import _ from "lodash";
import WhatsappModal from "src/components/shared/Modals/WhatsappModal";
import GpsModal from "src/components/shared/Modals/GpsModal";
import Resumen from "./Resumen";

export default function Collapsable({ prestamo }: { prestamo: IPrestamo }) {
  const items: {
    icon: any;
    label: string;
    property: string;
    format?: (row) => any;
  }[] = [
    {
      icon: <LocationOnOutlined sx={{ color: "#1B23BE" }} />,
      label: "Domicilio laboral",
      property: "cliente.direccionTrabajo",
    },
    {
      icon: <LocationOnOutlined sx={{ color: "#1B23BE" }} />,
      label: "Domicilio personal",
      property: "cliente.direccionPersonal",
    },
    {
      icon: <LocalPhone sx={{ color: "#1B23BE" }} />,
      label: "Teléfono",
      property: "cliente.telefono",
    },
    {
      icon: <LocalPhone sx={{ color: "#1B23BE" }} />,
      label: "Teléfono alternativo",
      property: "cliente.telefonoAlt",
    },
    {
      icon: <PriceCheckOutlined sx={{ color: "#1B23BE" }} />,
      label: "Último pago",
      property: "pagos",
      format(row: IPrestamo) {
        if (!row.pagos.length) return "--";
        row.pagos.sort(function compare(a, b): any {
          const A = parse(a.fecha, "dd/MM/yyyy", new Date()).toISOString();
          const B = parse(b.fecha, "dd/MM/yyyy", new Date()).toISOString();
          if (B < A) {
            return -1;
          }
          if (B > A) {
            return 1;
          }
          return 0;
        });
        const ultimoPago = row.pagos[0];
        return (
          <>
            {CurrencyService.formatStatic(ultimoPago.importe)} -{" "}
            {ultimoPago.fecha}{" "}
            <i>{ultimoPago.observacion && `- ${ultimoPago.observacion}`}</i>
          </>
        );
      },
    },
  ];

  const itemRenderizer = ({ icon, label, property, format }) => {
    return (
      <Stack direction="row" gap={1} paddingY={0.5}>
        {icon}
        <Typography fontWeight={500} fontSize={15}>
          {label}:
        </Typography>
        {prestamo && (
          <Typography fontSize={15}>
            {format
              ? format(prestamo)
              : _.get(prestamo, property)
              ? _.get(prestamo, property)
              : "--"}
          </Typography>
        )}
      </Stack>
    );
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const verifyMinimum = (row: ICliente, field: "GPS" | "WHATSAPP") => {
    if (field === "GPS") {
      return Boolean(row.gpsPersonal) || Boolean(row.gpsTrabajo);
    } else {
      return row.telefonoWp || row.telefonoAltWp;
    }
  };

  const [openWhatsappModal, setOpenWhatsappModal] = useState<boolean>(false);
  const [wpEntity, setWpEntity] = useState<ICliente>(null);
  const handleOpenWpModal = (row: ICliente) => {
    setWpEntity(() => row);
    setOpenWhatsappModal(true);
  };

  const [openGpsModal, setOpenGpsModal] = useState<boolean>(false);
  const [gpsEntity, setGpsEntity] = useState<ICliente>(null);
  const handleOpenGpsModal = (row: ICliente) => {
    setGpsEntity(() => row);
    setOpenGpsModal(true);
  };

  return (
    <>
      <GpsModal
        entity={gpsEntity}
        open={openGpsModal}
        handleClose={() => setOpenGpsModal(false)}
      />
      <WhatsappModal
        entity={wpEntity}
        open={openWhatsappModal}
        handleClose={() => setOpenWhatsappModal(false)}
      />
      <Stack paddingY={2}>
        <Stack direction="row" gap={2} paddingBottom={2}>
          <CustomButton
            sx={{ minWidth: "135px" }}
            startIcon={<WhatsApp />}
            variant="contained"
            buttonColor="#8EC3EF"
            disabled={!verifyMinimum(prestamo?.cliente as ICliente, "WHATSAPP")}
            onClick={() => handleOpenWpModal(prestamo?.cliente)}
          >
            Whatsapp
          </CustomButton>
          <CustomButton
            sx={{ minWidth: "135px" }}
            startIcon={<RoomOutlined />}
            variant="contained"
            buttonColor="#EA8EEF"
            disabled={!verifyMinimum(prestamo?.cliente as ICliente, "GPS")}
            onClick={() => handleOpenGpsModal(prestamo?.cliente)}
          >
            GPS
          </CustomButton>
        </Stack>
        {items.map(({ icon, label, property, format }) =>
          itemRenderizer({ icon, label, property, format })
        )}
        {
          <>
            <Stack direction="row" alignItems="center" gap={1} paddingTop={.5} paddingBottom={2}>
              <InfoOutlined sx={{ color: "#1B23BE" }} />
              <Typography fontWeight={500} fontSize={15}>Detalle del préstamo</Typography>
            </Stack>
            <Box sx={{ border: "2px solid #acafe7" }}>
              <Resumen prestamo={prestamo}/>
            </Box>
          </>
        }
        {
          <>
            <Stack direction="row" alignItems="center" gap={1} paddingY={2}>
              <History sx={{ color: "#1B23BE" }} />
              <Typography fontWeight={500} fontSize={15}>Historial de pagos</Typography>
            </Stack>
            <Box sx={{ border: "2px solid #acafe7" }}>
              <TableWrapper prestamo={prestamo ? prestamo : prestamo} />
            </Box>
          </>
        }
      </Stack>
    </>
  );
}
