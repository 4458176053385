import React from 'react'
import { Typography } from '@mui/material'

export default function CustomTitle({children, ...props}) {
  return (
    <Typography fontSize={20} fontWeight={500} {...props}>
        {children}
    </Typography>
  )
}
