import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { IFormComponent } from "src/hooks/useForm";
import CurrencyService from "src/services/CurrencyService";
import { IPrestamo } from "src/utils/interfaces";

interface IResumen extends IFormComponent {
  entity: IPrestamo
}

export default function Resumen({handleFormChange, getForm, setFormField, entity}: IResumen) {
  return (
    <Box sx={{ overflow: "auto" }}>
      <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
        <Table sx={{ border: "2px solid #acafe7" }}>
          <TableHead sx={{ backgroundColor: "#1B23BE26" }}>
            <TableRow>
              <TableCell>Tipo de préstamo</TableCell>
              <TableCell>Monto del préstamo</TableCell>
              <TableCell>Interés total aplicado</TableCell>
              <TableCell>Cantidad total de cuotas</TableCell>
              <TableCell>Valor de la cuota</TableCell>
              <TableCell>Monto a recaudar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                {getForm()?.tipo ? getForm()?.tipo : "--"}
                {getForm()?.subtipo && ` - ${getForm()?.subtipo}`}
              </TableCell>
              <TableCell sx={{whiteSpace: 'nowrap'}}>
                {getForm()?.valorPrestamo
                  ? CurrencyService.formatStatic(getForm()?.valorPrestamo)
                  : "--"}
              </TableCell>
              <TableCell sx={{whiteSpace: 'nowrap'}}>
                {entity?.interesTotalGenerado}
              </TableCell>
              <TableCell sx={{whiteSpace: 'nowrap'}}>
                {getForm()?.cantidadCuotas ? getForm()?.cantidadCuotas : "--"}
              </TableCell>
              <TableCell sx={{whiteSpace: 'nowrap'}}>
                {getForm()?.valorCuota
                  ? CurrencyService.formatStatic(getForm()?.valorCuota)
                  : "--"}
              </TableCell>
              <TableCell sx={{whiteSpace: 'nowrap'}}>
                {typeof entity?.bruto === 'number'
                  ? CurrencyService.formatStatic(entity?.bruto)
                  : entity?.bruto}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
}
