import { CalendarToday } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { format, parse } from 'date-fns'
import React from 'react'
import CustomDatePicker from 'src/components/shared/Custom/DatePicker'

export default function FechaAplazo({form, setForm}) {
  return (
    <Stack gap={2} maxWidth={'240px'} flexGrow={1}>
        <Stack direction='row' gap={1}>
            <CalendarToday sx={{color: '#1B23BE'}}/>
            <Typography>Seleccionar fecha de visita</Typography>
        </Stack>
        <CustomDatePicker
        minDate={new Date()}
        value={
          form?.fechaAplazo
            ? parse(form?.fechaAplazo, "yyyy-MM-dd", new Date())
            : null
        }
        onChange={(e) => {
          setForm({...form, fechaAplazo: format(e, "yyyy-MM-dd")})
        }}
        label="Fecha a visitar"
      />
    </Stack>
  )
}
