import { StepContent, StepLabel, Typography } from "@mui/material";
import { format, parse } from "date-fns";
import React from "react";
import CustomDatePicker from "src/components/shared/Custom/DatePicker";
import { IFormComponent } from "src/hooks/useForm";

export default function Fecha({handleFormChange, getForm}: IFormComponent) {
  return (
    <>
      <StepLabel>
        <Typography fontSize={18} fontWeight={400} color={"black"}>
          Seleccionar fecha del pago
        </Typography>
      </StepLabel>
      <StepContent>
        <CustomDatePicker
          value={
            getForm()?.fecha
              ? parse(getForm()?.fecha, "yyyy-MM-dd", new Date())
              : null
          }
          onChange={(e) => {
            handleFormChange({ value: e, name: "fecha" }, (value) =>
              format(value, "yyyy-MM-dd")
            );
          }}
          label="Fecha del pago"
        />
      </StepContent>
    </>
  );
}
