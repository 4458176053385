import React, { useState } from "react";
import Infobar from "./Infobar";
import TableWrapper from "./TableWrapper";

export default function Prestamos() {
  return (
    <>
      <Infobar  />
      <TableWrapper />
    </>
  );
}
