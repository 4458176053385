import {
  Alarm,
  Comment,
  EventNoteOutlined,
  History,
  NoteAddOutlined,
  PaidOutlined,
  PriceCheckOutlined,
  ReceiptOutlined,
  Schedule,
} from "@mui/icons-material";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { parse } from "date-fns";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import CustomButton from "src/components/shared/Custom/Button";
import useFetch from "src/hooks/useFetch";
import CurrencyService from "src/services/CurrencyService";
import { IPrestamo } from "src/utils/interfaces";
import { ESTADOS } from "src/utils/PrestamoEnums";
import TableWrapper from "../Pagos/TableWrapper";

export default function Collapsable({ prestamo }: { prestamo: IPrestamo }) {
  const { isLoading, data, fetchData } = useFetch(
    "get",
    `prestamos/${prestamo?.id}`
  );

  useEffect(() => {
    if (prestamo) {
      fetchData();
    }
  }, [prestamo]);

  const items: {
    icon: any;
    label: string;
    property: string;
    format?: (row) => any;
  }[] = [
    {
      icon: <EventNoteOutlined sx={{ color: "#1B23BE" }} />,
      label: "Día a cobrar",
      property: "subtipo",
    },
    {
      icon: <ReceiptOutlined sx={{ color: "#1B23BE" }} />,
      label: "Cuota actual",
      property: "cuotaActual",
    },
    {
      icon: <PaidOutlined sx={{ color: "#1B23BE" }} />,
      label: "Valor restante para completar la cuota",
      property: "valorRestanteCuotaActual",
      format(row) {
        if (typeof row.valorRestanteCuotaActual === "string")
          return row.valorRestanteCuotaActual;
        else
          return (
            <span>
              {CurrencyService.formatStatic(row.valorRestanteCuotaActual)}{" "}
              <i>{`(Pagó ${CurrencyService.formatStatic(
                row.valorCuota - row.valorRestanteCuotaActual
              )} de ${CurrencyService.formatStatic(row.valorCuota)})`}</i>
            </span>
          );
      },
    },
    {
      icon: <PriceCheckOutlined sx={{ color: "#1B23BE" }} />,
      label: "Último pago",
      property: "pagos",
      format(row: IPrestamo) {
        if (!row.pagos.length) return "--";
        row.pagos.sort(function compare(a, b): any {
          const A = parse(a.fecha, "dd/MM/yyyy", new Date()).toISOString();
          const B = parse(b.fecha, "dd/MM/yyyy", new Date()).toISOString();
          if (B < A) {
            return -1;
          }
          if (B > A) {
            return 1;
          }
          return 0;
        });
        const ultimoPago = row.pagos[0];
        return (
          <>
            {CurrencyService.formatStatic(ultimoPago.importe)} -{" "}
            {ultimoPago.fecha}{" "}
            <i>{ultimoPago.observacion && `- ${ultimoPago.observacion}`}</i>
          </>
        );
      },
    },
    {
      icon: <Schedule sx={{ color: "#1B23BE" }} />,
      label: "Fecha de entrega",
      property: "fechaEntrega",
    },
    {
      icon: <Comment sx={{ color: "#1B23BE" }} />,
      label: "Observación",
      property: "observacion",
    },
  ];

  const itemRenderizer = ({ icon, label, property, format }) => {
    if (property === "fechaEntrega" && data?.estadoId !== ESTADOS.PENDIENTE) {
      return "";
    }

    if (
      (property === "cuotaActual" || property === "valorRestanteCuotaActual") &&
      data?.estadoId !== ESTADOS.ACTIVO
    ) {
      return "";
    }

    if (property === "pagos" && data?.estadoId === ESTADOS.PENDIENTE) {
      return "";
    }

    return (
      <Stack direction="row" gap={1} paddingY={0.5}>
        {icon}
        <Typography fontWeight={500} fontSize={15}>
          {label}:
        </Typography>
        {data && (
          <Typography fontSize={15}>
            {format ? format(data) : data[property] ? data[property] : "--"}
          </Typography>
        )}
      </Stack>
    );
  };

  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <Stack paddingY={2}>
      {items.map(({ icon, label, property, format }) =>
        itemRenderizer({ icon, label, property, format })
      )}
      {prestamo?.estadoId !== ESTADOS.PENDIENTE && (
        <>
          <Stack direction="row" alignItems="center" gap={1} paddingY={2}>
            <History sx={{ color: "#1B23BE" }} />
            <Typography fontWeight={500}>Historial de pagos</Typography>
            {prestamo?.estadoId === ESTADOS.ACTIVO && (
              <>
                <Divider flexItem orientation="vertical" variant="middle" />
                <CustomButton
                  startIcon={<NoteAddOutlined />}
                  sx={{
                    color: "#57DF4F",
                    borderColor: "#57DF4F",
                    "&:hover": {
                      backgroundColor: "white",
                      borderColor: "#57DF4F",
                    },
                  }}
                  variant="outlined"
                  onClick={() => {
                    setSearchParams({
                      type: "create",
                      entity: "pago",
                      prestamoId: `${data?.id}`,
                    });
                  }}
                >
                  Añadir pago
                </CustomButton>
              </>
            )}
          </Stack>
          <Box sx={{ border: "2px solid #acafe7" }}>
            <TableWrapper prestamo={data ? data : prestamo} />
          </Box>
        </>
      )}
    </Stack>
  );
}
