import _ from 'lodash';
import * as showdown from 'showdown';
import { IPago, IPrestamo } from 'src/utils/interfaces';
import { ESTADOS } from 'src/utils/PrestamoEnums';
import CurrencyService from './CurrencyService';

export type ISSItem<T> = {
    label: string;
    property: string;
    format?: (row: T) => string;
  } | 'short_separator'

export default class ShareService {

    public static openWhatsapp (text: string): void {
      const encodedURI = encodeURIComponent(text)
      window.open(`https://api.whatsapp.com/send?text=${encodedURI}`, "_blank")
    }

    public static async copyToClipboard (text: string): Promise<void> {
        await navigator.clipboard.writeText(text);
    }

    public static markdownToHTML (text: string): string {
        const converter = new showdown.Converter();
        return converter.makeHtml(text)
    }

    public static getWpTextified (clienteName, prestamos, itemsPrestamo): string {
        const arrayPrestamos = Array.isArray(prestamos) ? prestamos : [prestamos];
        let text = `👤 *Cliente:* ${clienteName}\n_______________________________________________\n\n`;
        arrayPrestamos?.forEach((prestamo: IPrestamo) => {
          let textPrestamo = `🏦 *PRÉSTAMO #${
            arrayPrestamos.indexOf(prestamo) + 1
          } | ${prestamo.createdAt}*\n\n`;
          itemsPrestamo?.forEach((item: ISSItem<IPrestamo> | "short_separator") => {
            if (item === "short_separator") {
              textPrestamo += "--------------\n\n";
            } else {
              if (prestamo[item.property] !== "No calculable") {
                textPrestamo += `*${item.label}:* ${
                  item.format
                    ? item.format(prestamo)
                    : _.get(prestamo, item.property) ?? "--"
                }\n\n`;
              }
            }
          });
          text += textPrestamo;
          if (prestamo.estadoId !== ESTADOS.PENDIENTE) {
            text += "🕒 *Historial de pagos:*\n\n";
            let textPagosPrestamo = "";
            prestamo.pagos?.forEach((pago: IPago) => {
              textPagosPrestamo += `| ${pago.fecha} - ${
                pago.importe ? CurrencyService.formatStatic(pago.importe) : "--"
              }\n\n`;
            });
            text += textPagosPrestamo;
          }
          text += "_______________________________________________\n\n";
        });
        return text;
    }
}