import React from "react";
import MiniDrawer from "../../components/layout/Drawer";
import CustomPaper, { IBreadcrumb } from "../../components/shared/Custom/Paper";
import Clientes from "../../components/base/Clientes";
import DocumentService from "src/services/DocumentService";

const breadcrumb: IBreadcrumb[] = [
  {
    icon: "PeopleAltOutlined",
    text: "Clientes",
    path: "/clientes"
  },
];

export default function ClientesPage() {

  DocumentService.setTitle('Clientes')

  return (
    <MiniDrawer>
      <CustomPaper breadcrumb={breadcrumb}>
        <Clientes/>
      </CustomPaper>
    </MiniDrawer>
  );
}
