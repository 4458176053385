import { List } from "@mui/icons-material";
import { DialogActions, DialogContent, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import CustomButton from "src/components/shared/Custom/Button";
import CustomDialog, {
  CustomDialogActions,
} from "src/components/shared/Custom/Dialog";
import CustomDialogTitle from "src/components/shared/Custom/DialogTitle";
import useFetch from "src/hooks/useFetch";
import axios, { AxiosError } from "axios";
import useSnack from "src/hooks/useSnackbar";
import { format, parse } from "date-fns";
import FechaAplazo from "./Fields/FechaAplazo";
import Observacion from "./Fields/Observacion";

export default function CrearVisita({entity, open, setOpen}) {

  useEffect(() => {
    if(open){
      setForm({
        fechaAplazo: null,
        comentario: '',
        prestamoId: entity?.id})
    }
  }, [open]);

  const handleClose = () => {
    setOpen(false)
  };

  const [form, setForm] = useState({
    fechaAplazo: null,
    comentario: '',
    prestamoId: null,
  });

  const [openSnackbar] = useSnack();

  const { fetchData, isLoading } = useFetch("post", "listas-generar-visita", form);

  const handleSubmit = async () => {
    const response: any = await fetchData();
    if (axios.isAxiosError(response)) {
      openSnackbar(response?.response?.data?.message);
    } else {
      openSnackbar(
        `Se generó una visita`
      );
      handleClose();
    }
  };

  return (
    <CustomDialog open={open} onClose={handleClose}>
      <CustomDialogTitle
        handleClose={handleClose}
        title={'Crear visita'}
        icon="List"
        iconColor="#8EC3EF"
      />
      <DialogContent>
        <Stack gap={3} paddingTop={2}>
          <FechaAplazo form={form} setForm={setForm} />
          <Observacion form={form} setForm={setForm} />
        </Stack>
      </DialogContent>
      <CustomDialogActions>
        <CustomButton
          variant="contained"
          buttonColor="#1B75BE"
          disabled={!form?.fechaAplazo || isLoading}
          onClick={() => handleSubmit()}
        >
          Crear visita
        </CustomButton>
      </CustomDialogActions>
    </CustomDialog>
  );
}
