import { createTheme } from "@mui/material/styles";
import { esES } from '@mui/material/locale';

export const appTheme = createTheme(
  {
    palette: {
      primary: {
        main: "#0094ff",
      },
      secondary: {
        main: '#1b75be',
      },
      info: {
        main: "#1b23be",
      },
    },
  },
  esES
);
