import { MoreVert } from "@mui/icons-material";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import {
  Collapse,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
} from "@mui/material";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { CellGenerator, IAction } from "./index";

interface IRow<T> {
  row: any;
  columns: any;
  actions: any;
  collapsable?: (row?: T) => React.ReactNode;
}

export default function Row({
  row,
  columns,
  actions,
  collapsable,
}: IRow<typeof row>) {
  // Collapsable
  const [open, setOpen] = React.useState(false);

  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const openMenu = Boolean(menuAnchorEl);
  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const [searchParams] = useSearchParams()
  const ref = React.useRef(null)

  React.useEffect(() => {
    if(searchParams.get('prestamoId')){
      if(collapsable && row?.id == searchParams.get('prestamoId')){
        setOpen(() => true)
        ref?.current?.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [searchParams, ref?.current])

  return (
    <React.Fragment key={`rf=${row.id}`}>
      <TableRow
        id={`id-${row.id}`}
        hover
        sx={{ "& > *": { borderBottom: collapsable ? "unset" : "" }}}
      >
        {collapsable && (
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
        )}
        {columns.map((column) => {
          return (
            <CellGenerator
              key={`${row.id}-${column.label}`}
              row={row}
              column={column}
            />
          );
        })}
        {actions && (
          <TableCell>
            <IconButton onClick={(e) => handleClickMenu(e)}>
              <MoreVert />
            </IconButton>

            <Menu
              elevation={1}
              id="basic-menu"
              anchorEl={menuAnchorEl}
              open={openMenu}
              onClose={handleCloseMenu}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {actions.map((action: IAction) => (
                <>
                  {action.hide && action.hide(row) && (
                    <MenuItem
                      key={`${row.id}-${action.label}`}
                      onClick={() => {
                        handleCloseMenu();
                        action.onClick(row);
                      }}
                      disabled={action.disabled ? action.disabled(row) : false}
                      sx={{ color: action.color }}
                    >
                      {action.label}
                    </MenuItem>
                  )}
                  {!action.hide &&                     <MenuItem
                      key={`${row.id}-${action.label}`}
                      onClick={() => {
                        handleCloseMenu();
                        action.onClick(row);
                      }}
                      disabled={action.disabled ? action.disabled(row) : false}
                      sx={{ color: action.color }}
                    >
                      {action.label}
                    </MenuItem>}
                </>
              ))}
            </Menu>
          </TableCell>
        )}
      </TableRow>
      {collapsable && (
        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0, borderBottom: 'unset' }}
            colSpan={columns.length + 1}
          >
            <Collapse ref={ref} in={open} timeout="auto" unmountOnExit>
              {collapsable(row)}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}
