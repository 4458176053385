import React, { useEffect, useState } from "react";
import MiniDrawer from "../../components/layout/Drawer";
import CustomPaper, { IBreadcrumb } from "../../components/shared/Custom/Paper";
import DocumentService from "src/services/DocumentService";
import DetalleCliente from "src/components/base/Clientes/DetalleCliente";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";
import useFetch from "src/hooks/useFetch";

export default function ClientePage() {

  const { id } = useParams()

  const [clientAlias, setClientAlias] = useState<string>('')

  const breadcrumb: IBreadcrumb[] = [
    {
      icon: "PeopleAltOutlined",
      text: "Clientes",
      path: "/clientes"
    },
    {
      icon: "PersonOutlineOutlined",
      text: `Cliente: ${clientAlias}`,
      path: `/cliente/${id}`
    }
  ];

  DocumentService.setTitle(`Cliente: ${clientAlias}`)

  const {data, fetchData} = useFetch('get', `clientes/${id}`)

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if(data){
      setClientAlias(data?.alias)
    }
  }, [data])

  return (
    <MiniDrawer>
      <CustomPaper breadcrumb={breadcrumb}>
        <DetalleCliente/>
      </CustomPaper>
    </MiniDrawer>
  );
}
