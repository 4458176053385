import React, { useState } from "react";
import Filters from "./Sections/Filters";
import Results from "./Sections/Results";
import { Stack } from "@mui/material";

export interface IFinanzasFilter {
  type: null | "specificDate" | "specificMonth" | "betweenDates";
  value: any;
}

export default function Finanzas() {
  const [filter, setFilter] = useState<IFinanzasFilter>({
    type: null,
    value: null,
  });
  const [api, setApi] = useState<string>(null);

  const handleSearch = () => {
    let endpoint = "pagos?order=fecha&order=desc";
    if (filter.type === "specificDate") {
      endpoint += `&specificDate=${filter.value}`;
    }
    if (filter.type === "specificMonth") {
      endpoint += `&specificMonth=${filter.value}`;
    }
    if (filter.type === "betweenDates") {
      if (filter.value.startDate) {
        endpoint += `&betweenDatesStart=${filter.value.startDate}`;
      }
      if (filter.value.endDate) {
        endpoint += `&betweenDatesEnd=${filter.value.endDate}`;
      }
    }
    setApi(endpoint)
  };

  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      p={2}
      gap={2}
      justifyContent={"space-between"}
    >
      <Filters filter={filter} setFilter={setFilter} handleSearch={handleSearch} />
      <Results filter={filter} api={api} />
    </Stack>
  );
}
