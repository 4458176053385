import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CurrencyService from "src/services/CurrencyService";
import { ESTADOS } from "src/utils/PrestamoEnums";
import TablaEstandar, { IColumn, IAction } from "src/components/shared/Tabla";
import { IPrestamo } from "src/utils/interfaces";
import useTable from "src/hooks/useTable";
import { Box, Typography } from "@mui/material";
import Collapsable from "./Collapsable";
import TablaOrdenable from "src/components/shared/TablaOrdenable";
import useFetch from "src/hooks/useFetch";
import axios from "axios";
import useSnack from "src/hooks/useSnackbar";
import CustomButton from "src/components/shared/Custom/Button";
import { NoteAddOutlined } from "@mui/icons-material";
import TableWrapperPagados from "./Pagados/TableWrapper";
import Aplazar from "./Dialogs/Aplazar";
import Pago from "./Dialogs/Pago/Pago";
import { format } from "date-fns";

export default function TableWrapper({ editing, openProximos, setTotales, totales, refresher, setRefresher}: {refresher, setRefresher, totales, setTotales, editing: boolean, openProximos: boolean }) {
  const { isLoading, data, setData, getData } = useTable(
    `listas-diario?fecha=${format(new Date(), 'yyyy-MM-dd')}`
  );

  useEffect(() => {
    getData()
  }, [openProximos, refresher])

  const columns: IColumn<any>[] = [
    {
      property: "prestamo.cliente.alias",
      label: "Alias cliente",
      notSortable: true,
      format(row) {
        return (
          <Typography
            fontWeight={500}
            onClick={() => navigate(`/clientes/${row?.prestamo?.cliente?.id}`)}
            sx={{ ":hover": {
              cursor: 'pointer',
              textDecoration: 'underline'
            } }}
          >
            {row?.prestamo?.cliente?.alias}
          </Typography>
        );
      },
    },
    {
      property: "pagar",
      label: "Pagar",
      notSortable: true,
      format(row: any, value) {
        return (
          <CustomButton
            buttonColor="#93EF8E"
            variant="contained"
            disabled={editing}
            onClick={() => setSearchParams({entity: 'pago', prestamoId: row?.prestamo?.id, listaId: row?.id})}
          >
            <NoteAddOutlined sx={{ color: "white" }} />
          </CustomButton>
        );
      },
    },
    {
      property: "prestamo.cuotaActual",
      label: "Cuota actual",
      notSortable: true,
    },
    {
      property: "prestamo.valorCuota",
      label: "Valor de la cuota",
      format(row, value) {
        return CurrencyService.formatStatic(value);
      },
    },
    {
      property: "prestamo.cuotasCompletadas",
      label: "Cuotas completadas / Cuotas totales",
      notSortable: true,
      format(row: any) {
        return (
          <>
            {row.prestamo.cuotasCompletadas}/{row.prestamo.cantidadCuotas}
          </>
        );
      },
    },
    {
      property: "prestamo.valorRestanteCuotaActual",
      label: "Restante cuota",
      notSortable: true,
      format(row, value) {
        return CurrencyService.formatStatic(value);
      },
    },
    {
      property: "fechaAplazo",
      label: "¿Es aplazo?",
      notSortable: true,
      format(row, value) {
        return value ? <b>Sí</b> : 'No'
      }
    }
  ];

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const actions: IAction[] = [
    {
      label: "Aplazar visita",
      onClick: (row) => {
        setSearchParams({aplazar: row.id})
      },
    },
    {
      label: "Ver cliente",
      onClick: (row) => {
        navigate(`/clientes/${row.prestamo.clienteId}`);
      },
    },
  ];

  const [openSnackbar] = useSnack();

  const { fetchData: handlePost, response } = useFetch("post");

  const sendArrayMovement = async (arrayMovement) => {
    await handlePost("listas-ordenamiento", arrayMovement);
    if (axios.isAxiosError(response)) {
      openSnackbar(response?.response?.data?.message);
    }
  };

  const { isLoading: isLoadingPagados, getData: getDataPagados, data: dataPagados } = useTable(
    `listas?fecha=${format(new Date(), 'yyyy-MM-dd')}&pagado&order=updatedAt&order=desc`
  );

  const refreshTables = () => {
    getData()
    getDataPagados()
  }

  useEffect(() => {
    if(data && dataPagados && !isLoading && !isLoadingPagados){
      setTotales({total: data?.length + dataPagados?.length, totalPagados: dataPagados?.length})
    } else {
      setTotales({total: null, totalPagados: null})
    }
  }, [data, dataPagados])

  return (
    <Box paddingBottom={2} sx={{overflowY: 'auto', maxHeight:'75vh'}}>
      <Aplazar getData={refreshTables} />
      <Pago getData={refreshTables} />
      <TablaOrdenable
        collapsable={
          editing ? null : (row) => <Collapsable prestamo={row?.prestamo} />
        }
        isLoading={isLoading}
        rows={data}
        columns={columns}
        actions={editing ? null : actions}
        setterData={setData}
        handleArrayMovement={sendArrayMovement}
        editing={editing}
      />
      <TableWrapperPagados data={dataPagados} isLoading={isLoadingPagados} getData={refreshTables} />
    </Box>
  );
}
