import { PointOfSaleOutlined } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CurrencyService from "src/services/CurrencyService";
import { IPago } from "src/utils/interfaces";
import { ESTADOS } from "src/utils/PrestamoEnums";
import TablaEstandar, { IColumn } from "src/components/shared/Tabla";
import useTable from "src/hooks/useTable";
import { IFinanzasFilter } from "../..";
import AuthService from "src/services/AuthService";

export default function TableWrapper({
  filter,
  api,
}: {
  api: string;
  filter: IFinanzasFilter;
}) {
  const { isLoading, getData, data } = useTable(api);

  const columns: IColumn<IPago>[] = [
    {
      property: "prestamo.cliente.alias",
      label: "Alias",
    },
    {
      property: "importe",
      label: "Valor del pago",
      format(row) {
        return row.importe
          ? `${CurrencyService.formatStatic(row.importe)}`
          : "--";
      },
    },
    {
      property: "metodoPago.denominacion",
      label: "Medio de pago",
    },
    {
      property: "observacion",
      label: "Observaciones",
      format(row) {
        return <i>{row.observacion ? row.observacion : "--"}</i>;
      },
    },
    {
      property: "fecha",
      label: "Fecha del pago",
      isDate: true,
    },
    {
      property: "esInteres",
      label: "Es pago de interés",
      format: (row) => (row.esInteres ? "Sí" : "No"),
    },
    {
      property: "creadoPor",
      label: "Creado por",
    },
  ];

  useEffect(() => {
    if (api) {
      getData();
    }
  }, [api]);

  return (
    <Stack gap={2}>
      <Stack direction="row" gap={1}>
        <PointOfSaleOutlined sx={{ color: "#1B23BE" }} />
        <Typography fontSize={"18px"} fontWeight={"500"}>
          Detalle de pagos
        </Typography>
      </Stack>
      {(!data || !data.length) && (
        <Box
          sx={{
            border: "1px solid gray",
            borderRadius: "5px",
            minHeight: "250px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <span>Sin datos</span>
        </Box>
      )}
      {data && Boolean(data?.length) && (
        <Box sx={{ border: "2px solid #acafe7" }}>
          <TablaEstandar
            isLoading={isLoading}
            rows={data}
            columns={columns}
            searchBar={false}
          />
        </Box>
      )}
    </Stack>
  );
}
