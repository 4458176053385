import React, { useState } from "react";
import Infobar from "./Main/Infobar";
import { Stack } from "@mui/material";
import Aplazados from "./Aplazados";
import CustomPaper, { IBreadcrumb } from "src/components/shared/Custom/Paper";
import Main from "./Main";

export default function Home() {
  const [refresher, setRefresher] = useState<boolean>(false)
  return (
    <>
    <Stack direction="row" flexWrap="wrap" gap={3} >
      <Main refresher={refresher} setRefresher={setRefresher} />
      <CustomPaper sx={{minWidth: '250px', flexGrow: '1', maxWidth: '350px'}}>
        <Aplazados refresher={refresher} setRefresher={setRefresher} />
      </CustomPaper>
    </Stack>
    </>
  );
}
