import { ArrowBackOutlined, ArrowForwardOutlined } from "@mui/icons-material";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { format } from "date-fns";
import es from "date-fns/locale/es";
import _ from "lodash";
import React, { useState } from "react";

function formattedFullDate(date) {
    const fnsFullDate = format(date, "eeee d 'de' LLLL", { locale: es });
    let splitted = fnsFullDate.split(" ");
    splitted[0] = _.capitalize(splitted[0]);
    splitted[3] = _.capitalize(splitted[3]);
    return splitted.join(" ");
  }

  function formattedCuttedFullDate(date) {
    const fnsFullDate = format(date, "eee'.' d LLL'.'", { locale: es });
    let splitted = fnsFullDate.split(" ");
    splitted[0] = _.capitalize(splitted[0]);
    splitted[2] = _.capitalize(splitted[2]);
    return splitted.join(" ");
  }

export default function Picker({pickedDate, setPickedDate, availableDays, editing}) {

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      gap={4}
    >
      <IconButton
        sx={{
          "&:hover": { backgroundColor: "#ddebf5" },
          backgroundColor: "#ddebf5",
          padding: 1,
          borderRadius: "100%",
          height: "40px",
          width: "40px",
        }}
        disabled={pickedDate === availableDays?.at(0) || editing}
        onClick={() =>
          setPickedDate(availableDays[availableDays?.indexOf(pickedDate) - 1])
        }
      >
        <ArrowBackOutlined />
      </IconButton>
      <Stack
        direction="row"
        gap={2}
        sx={{
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {availableDays
          ?.slice(availableDays?.indexOf(pickedDate))
          ?.map((item) => {
            if (pickedDate === item) {
              return (
                <Typography
                  sx={{
                    display: "inline",
                    backgroundColor: pickedDate === item ? "#DDEBF5" : "none",
                    padding: 1,
                    minWidth: "170px",
                    borderRadius: "7px",
                    textAlign: "center",
                  }}
                  key={item.sqlDate}
                >
                  {formattedFullDate(item.jsDate)}
                </Typography>
              );
            } else {
              return (
                <Typography
                  sx={{
                    display: "inline",
                    backgroundColor: pickedDate === item ? "#DDEBF5" : "none",
                    padding: 1,
                    minWidth: pickedDate === item ? "170px" : "120px",
                    textAlign: "center",
                  }}
                  key={item.sqlDate}
                >
                  {formattedCuttedFullDate(item.jsDate)}
                </Typography>
              );
            }
          })}
        {[1, 2, 3, 4, 5].map((item) => (
          <Typography
            sx={{
              display: "inline",
              backgroundColor: "none",
              color: "gray",
              padding: 1,
              minWidth: "120px",
              textAlign: "center",
            }}
            key={item}
          >
            No disponible
          </Typography>
        ))}
      </Stack>
      <IconButton
        sx={{
          "&:hover": { backgroundColor: "#ddebf5" },
          backgroundColor: "#ddebf5",
          padding: 1,
          borderRadius: "100%",
          height: "40px",
          width: "40px",
        }}
        onClick={() =>
          setPickedDate(availableDays[availableDays?.indexOf(pickedDate) + 1])
        }
        disabled={pickedDate === availableDays?.at(-1) || editing}
      >
        <ArrowForwardOutlined />
      </IconButton>
    </Stack>
  );
}
