import { Stack, Typography } from '@mui/material'
import React from 'react'
import Table from './Table'

export default function Aplazados({refresher, setRefresher}) {
  return (
    <Stack p={2} gap={1}>
        <Typography fontSize={20} fontWeight={500}>Aplazados</Typography>
        <Table refresher={refresher} setRefresher={setRefresher} />
    </Stack>
  )
}
