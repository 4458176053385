import { Close, PersonAdd } from "@mui/icons-material";
import { DialogContent, DialogTitle, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CustomButton from "src/components/shared/Custom/Button";
import CustomCheckbox from "src/components/shared/Custom/Checkbox";
import CustomDialog, {
  CustomDialogActions,
} from "src/components/shared/Custom/Dialog";
import useForm from "src/hooks/useForm";
import useSnack from "src/hooks/useSnackbar";
import ApiService from "src/services/ApiService";
import CurrencyService from "src/services/CurrencyService";
import { IFormField } from "src/services/FormService";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import useFetch from "src/hooks/useFetch";

export default function EntityForm() {
  const endpoint: string = "clientes";

  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [type, setType] = useState<'create'|'update'>("create");

  const [open, setOpen] = useState<boolean>(false);

  const handleClose = () => {
    setSearchParams({});
  };

  useEffect(() => {
    if (searchParams.get("type") && searchParams.get("entity") === 'cliente') {
      setOpen(true);
      setType(searchParams.get("type") as 'create'|'update');
    } else {
      setOpen(false);
    }
  }, [searchParams]);

  const formFields: IFormField[] = [
    {
      label: "Alias",
      name: "alias",
      required: true,
    },
    {
      label: "Nombre completo",
      name: "nombre",
      required: true,
    },
    {
      label: "DNI",
      name: "dni",
      formatDisplay(value) {
        if (value) {
          return CurrencyService.formatInput(value);
        } else {
          return value;
        }
      },
      formatInput(value) {
        return value.replace(/\D/g, "");
      },
    },
    {
      label: "Trabajo",
      name: "trabajo",
    },
    {
      label: "Domicilio laboral",
      name: "direccionTrabajo",
    },
    {
      label: "GPS dom. laboral",
      name: "gpsTrabajo",
    },
    {
      label: "Domicilio personal",
      name: "direccionPersonal",
    },
    {
      label: "GPS dom. personal",
      name: "gpsPersonal",
    },
    {
      label: "Teléfono principal",
      name: "telefono",
      helperText: function helperText(mainValue) {
        return (
          <>
            <CustomCheckbox
              name="telefonoWp"
              disabled={!mainValue}
              checked={getForm()?.["telefonoWp"]}
              onChange={(e) => handleFormChange(e.target)}
              size="small"
            />
            Este teléfono es un whatsapp.
          </>
        );
      },
      helperName: "telefonoWp",
      onChange: () => {
        if (getForm()?.["telefono"].substring(1) === "") {
          setFormField("telefonoWp", false);
        }
      },
      formatDisplay(value) {
        if (getForm()?.["telefonoWp"]) {
          return `+549${getForm()?.["telefono"]}`;
        } else {
          return value;
        }
      },
      formatInput(value) {
        if (getForm()?.["telefonoWp"]) {
          value = value.substring(4);
        }
        return value.replace(/\D/g, "");
      },
    },
    {
      name: "telefonoWp",
      type: Boolean,
    },
    {
      label: "Teléfono alternativo",
      name: "telefonoAlt",
      helperText: function helperText(mainValue) {
        return (
          <>
            <CustomCheckbox
              name="telefonoAltWp"
              disabled={!mainValue}
              checked={getForm()?.["telefonoAltWp"]}
              onChange={(e) => handleFormChange(e.target)}
              size="small"
            />
            Este teléfono es un whatsapp.
          </>
        );
      },
      helperName: "telefonoAltWp",
      onChange: () => {
        if (getForm()?.["telefonoAlt"].substring(1) === "") {
          setFormField("telefonoAltWp", false);
        }
      },
      formatDisplay(value) {
        if (getForm()?.["telefonoAltWp"]) {
          return `+549${getForm()?.["telefonoAlt"]}`;
        } else {
          return value;
        }
      },
      formatInput(value) {
        if (getForm()?.["telefonoAltWp"]) {
          value = value.substring(4);
        }
        return value.replace(/\D/g, "");
      },
    },
    {
      name: "telefonoAltWp",
      type: Boolean,
    },
    {
      label: "Observación",
      name: "observacion",
    },
  ];

  const [openSnackbar] = useSnack();

  const send = async () => {
    try {
      const method = type === 'create' ? 'post' : 'put'
      const { data } = await ApiService[method](type === 'create' ? endpoint : `${endpoint}/${searchParams.get('id')}`, getSendForm());
      navigate(type === 'create' ? `${location.pathname}/${data.id}` : location.pathname);
      openSnackbar(`Se ha ${type === 'create' ? 'creado' : 'editado'} el cliente ${data.nombre}`);
    } catch (error) {
      openSnackbar(`Hubo un error al intentar ${type === 'create' ? 'crear' : 'editar'}  el cliente.`);
    }
  };

  const {
    saveButtonState,
    getForm,
    getSendForm,
    sendRequest,
    getFormBuild,
    handleFormChange,
    setFormField,
    loadEntity
  } = useForm(undefined, send, formFields);

  useEffect(() => {
    if (open && type === "update") {
      fetchData(`clientes/${searchParams.get('id')}`)
    }
  }, [open]);

  const {fetchData, isLoading, data} = useFetch('get')

  useEffect(() => {
    if(data){
      loadEntity(data)
    }
  }, [data])

  return (
    <CustomDialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap={5}
        >
          <Box
            fontSize={18}
            fontWeight={500}
            display={"flex"}
            alignItems="center"
            gap={2}
          >
            {type === "create" ? (
              <PersonAdd
                sx={{
                  fontSize: 32,
                  color: "white",
                  background: "#8EC3EF",
                  borderRadius: "100%",
                  height: 30,
                  width: 30,
                  padding: 0.5,
                }}
              />
            ) : (
              <BorderColorOutlinedIcon
                sx={{
                  fontSize: 32,
                  color: "white",
                  background: "#8EC3EF",
                  borderRadius: "100%",
                  height: 30,
                  width: 30,
                  padding: 0.5,
                }}
              />
            )}
            {type === "create"
              ? "Agregar nuevo cliente"
              : "Editar datos del cliente"}
          </Box>
          <Box>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box paddingTop={1} display="flex" flexWrap="wrap" gap={3}>
          {getFormBuild()}
        </Box>
      </DialogContent>
      <CustomDialogActions>
        <CustomButton
          variant="contained"
          buttonColor="#1B75BE"
          disabled={saveButtonState()}
          onClick={sendRequest}
        >
          {type === 'create' ? 'Crear' : 'Guardar'}
        </CustomButton>
      </CustomDialogActions>
    </CustomDialog>
  );
}
