import React, { useState } from "react";
import ApiService from "../services/ApiService";

interface IRUseFetch {
  isLoading: boolean;
  data: any;
  metadata: any;
  response: any;
  fetchData: (customEndpoint?, customPayload?) => Promise<void>;
  setData: React.Dispatch<React.SetStateAction<any[]>>;
}

function useFetch(
  method: "get" | "delete" | "put" | "post",
  endpoint: string = null,
  payload: { [x: string]: any } = null
): IRUseFetch {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [response, setResponse] = useState([]);
  const [data, setData] = useState(null);
  const [metadata, setMetadata] = useState({});

  const fetchData = async (
    selfEndpoint: string = null,
    selfPayload: any = null
  ): Promise<any> => {
    setIsLoading(() => true);
    if (!endpoint && selfEndpoint) {
      endpoint = selfEndpoint;
    }
    const customPayload = !payload ? selfPayload : payload;

    const apiResponse =
      method === "post" || method === "put"
        ? await ApiService[method](endpoint, customPayload)
        : await ApiService[method](endpoint);
    setResponse(() => apiResponse);
    setData(() => apiResponse.data);
    const metadataCleaner = { ...apiResponse };
    delete metadataCleaner.data;
    setMetadata(() => metadataCleaner);
    setIsLoading(() => false);

    return apiResponse;
  };

  return { isLoading, data, metadata, response, fetchData, setData };
}

export default useFetch;
