import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React from 'react'
import CurrencyService from 'src/services/CurrencyService'
import { IPrestamo } from 'src/utils/interfaces'

export default function Resumen({prestamo}: {prestamo: IPrestamo}) {
  return (
    <Table>
        <TableHead sx={{backgroundColor: '#dddef5'}}>
            <TableRow>
                <TableCell>Tipo de préstamo</TableCell>
                <TableCell>Monto del préstamo</TableCell>
                <TableCell>Cuotas completadas / Cuotas totales</TableCell>
                <TableCell>Valor de la cuota</TableCell>
                <TableCell>Monto recaudado / Monto a recaudar</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            <TableRow>
                <TableCell>{prestamo?.tipo} - <i>{prestamo?.subtipo}</i></TableCell>
                <TableCell>{CurrencyService.formatStatic(prestamo?.valorPrestamo)}</TableCell>
                <TableCell>{prestamo?.cuotasCompletadas}/{prestamo?.cantidadCuotas}</TableCell>
                <TableCell>{CurrencyService.formatStatic(prestamo?.valorCuota)}</TableCell>
                <TableCell>{CurrencyService.formatStatic(prestamo?.cobrado)}/{CurrencyService.formatStatic(prestamo?.bruto)}</TableCell>
            </TableRow>
        </TableBody>
    </Table>
  )
}
