import { format } from 'date-fns'
import es from 'date-fns/locale/es'
import _ from 'lodash'
import React from 'react'
import CustomTitle from '../../../shared/Custom/Title'
import Actions from './Actions'


export default function TopBar() {

  function formattedFullDate(){
    const fnsFullDate = format(new Date(), "eeee d 'de' LLLL", { locale: es })
    let splitted = fnsFullDate.split(' ')
    splitted[0] = _.capitalize(splitted[0])
    splitted[3] = _.capitalize(splitted[3])
    return splitted.join(' ')
  }

  return (
    <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: '12px', marginBottom: '15px', alignItems: 'center'}}>
        <CustomTitle>
        {formattedFullDate()}
        </CustomTitle>
        <Actions/>
    </div>
  )
}
