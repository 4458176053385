import { Stack } from '@mui/material'
import React from 'react'
import CustomButton from '../../../shared/Custom/Button'
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Header from './Header';
import { useSearchParams } from 'react-router-dom';

export default function Infobar() {

  let [searchParams, setSearchParams] = useSearchParams();

  const openEntityForm = () => {
    setSearchParams({type: "create", entity: "cliente"})
  }

  return (
    <Stack direction='row' flexWrap='wrap' alignItems='center' justifyContent='space-between' p={2} paddingTop={0} rowGap={2}>
        <Header/>
        <CustomButton sx={{flexGrow: '1', maxWidth: '320px'}} buttonColor='#93EF8E' variant='contained' startIcon={<PersonAddIcon/>} onClick={() => openEntityForm()}>Añadir cliente</CustomButton>
    </Stack>
  )
}
