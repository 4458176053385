import { MoreVert, RoomOutlined, WhatsApp } from "@mui/icons-material";
import { IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButton from "src/components/shared/Custom/Button";
import GpsModal, {
  IFormattedGpsModal,
} from "src/components/shared/Modals/GpsModal";
import WhatsappModal, {
  IFormattedWpModal,
} from "src/components/shared/Modals/WhatsappModal";
import CurrencyService from "src/services/CurrencyService";
import { ICliente } from "src/utils/interfaces";
import { ESTADOS } from "src/utils/PrestamoEnums";
import useTable from "../../../hooks/useTable";
import TablaEstandar, { IAction, IColumn } from "../../shared/Tabla";
import TableFilters from "./TableFilters";
import TablaBack from "src/components/shared/TablaBack";

function formatPrestamosQuantity(row) {
  const pActivos = row.prestamos.filter(
    (prestamo) => prestamo.estadoId === ESTADOS.ACTIVO
  );
  const pFinalizados = row.prestamos.filter(
    (prestamo) => prestamo.estadoId === ESTADOS.FINALIZADO
  );
  const pPendientes = row.prestamos.filter(
    (prestamo) => prestamo.estadoId === ESTADOS.PENDIENTE
  );

  return (
    <Stack>
      <Typography
        fontSize={14}
        fontWeight={400}
        color={pActivos.length && "#09AD00"}
      >
        {pActivos.length} {pActivos.length === 1 ? "activo" : "activos"}
      </Typography>
      <Typography fontSize={14} fontWeight={300} fontStyle={"italic"}>
        {pFinalizados.length}{" "}
        {pFinalizados.length === 1 ? "finalizado" : "finalizados"}
      </Typography>
      <Typography fontSize={14} fontWeight={300} fontStyle={"italic"}>
        {pPendientes.length}{" "}
        {pPendientes.length === 1 ? "pendiente" : "pendientes"}
      </Typography>
    </Stack>
  );
}

export default function TableWrapper() {
  const [api, setApi] = useState<string>("clientes");

  const searchFields: string[] = [
    "alias",
    "nombre",
    "dni",
    "trabajo",
    "direccion_trabajo",
    "direccion_personal",
    "telefono",
    "telefono_alt",
    "observacion",
  ];

  const verifyMinimum = (row: ICliente, field: "GPS" | "WHATSAPP") => {
    if (field === "GPS") {
      return Boolean(row.gpsPersonal) || Boolean(row.gpsTrabajo);
    } else {
      return row.telefonoWp || row.telefonoAltWp;
    }
  };

  const columns: IColumn<ICliente>[] = [
    {
      property: "alias",
      label: "Alias cliente",
      format(row) {
        return (
          <Typography
            fontWeight={500}
            onClick={() => navigate(`${location.pathname}/${row.id}`)}
            sx={{ ":hover": {
              cursor: 'pointer',
              textDecoration: 'underline'
            } }}
          >
            {row?.alias}
          </Typography>
        );
      },
    },
    {
      property: "prestamos",
      label: "Préstamos",
      format(row) {
        return formatPrestamosQuantity(row);
      },
      notSortable: true,
    },
    {
      property: "telefono",
      label: "Teléfono",
      format(row) {
        return (
          <CustomButton
            sx={{ minWidth: "135px" }}
            startIcon={<WhatsApp />}
            variant="contained"
            buttonColor="#8EC3EF"
            disabled={!verifyMinimum(row, "WHATSAPP")}
            onClick={() => handleOpenWpModal(row)}
          >
            Whatsapp
          </CustomButton>
        );
      },
      notSortable: true,
    },
    {
      property: "direccion_trabajo",
      label: "Dirección",
      format(row) {
        return (
          <CustomButton
            sx={{ minWidth: "135px" }}
            startIcon={<RoomOutlined />}
            variant="contained"
            buttonColor="#EA8EEF"
            disabled={!verifyMinimum(row, "GPS")}
            onClick={() => handleOpenGpsModal(row)}
          >
            GPS
          </CustomButton>
        );
      },
      notSortable: true,
    },
    {
      property: "trabajo",
      label: "Trabajo",
    },
    {
      property: "dni",
      label: "DNI/CUIL",
      format(row) {
        return row.dni ? CurrencyService.formatInput(row.dni) : "";
      },
    },
    {
      property: "observacion",
      label: "Observación",
      sx: {
        fontWeight: 300,
        fontStyle: "italic",
      },
    },
    {
      property: "createdAt",
      label: "Fecha de creación",
      isDate: true,
    },
  ];

  const [openWhatsappModal, setOpenWhatsappModal] = useState<boolean>(false);
  const [wpEntity, setWpEntity] = useState<ICliente>(null);
  const handleOpenWpModal = (row: ICliente) => {
    setWpEntity(() => row);
    setOpenWhatsappModal(true);
  };

  const [openGpsModal, setOpenGpsModal] = useState<boolean>(false);
  const [gpsEntity, setGpsEntity] = useState<ICliente>(null);
  const handleOpenGpsModal = (row: ICliente) => {
    setGpsEntity(() => row);
    setOpenGpsModal(true);
  };

  const navigate = useNavigate();
  const location = useLocation();

  const actions: IAction[] = [
    {
      label: "Ver cliente",
      onClick: (row) => {
        navigate(`${location.pathname}/${row.id}`);
      },
    },
  ];

  const [filters, setFilters] = useState<string>('')

  return (
    <>
      <GpsModal
        entity={gpsEntity}
        open={openGpsModal}
        handleClose={() => setOpenGpsModal(false)}
      />
      <WhatsappModal
        entity={wpEntity}
        open={openWhatsappModal}
        handleClose={() => setOpenWhatsappModal(false)}
      />
      <TablaBack
        columns={columns}
        searchBar={true}
        filters={<TableFilters setFilters={setFilters} />}
        actions={actions}
        entity={'clientes'}
        filtersParam={filters}
        searchFields={searchFields}
        defaultRowsPerPage={10}
      />
    </>
  );
}
