import React, { useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { es } from "date-fns/locale";

export default function CustomDatePicker({ ...props }) {
  function getBorderColor() {
    if (props.value) {
      return "secondary.main";
    }

    return "#0000003b";
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
      <DatePicker
        format="dd/MM/yyyy"
        sx={{
          label: { color: props.value ? "secondary.main" : "black" },
          boxShadow: "none",
          ".MuiOutlinedInput-notchedOutline": {
            borderWidth: props.value && 2,
            borderColor: props.value && "secondary.main",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: getBorderColor(),
          },
          ...props.sx,
        }}
        {...props}
      />
    </LocalizationProvider>
  );
}
