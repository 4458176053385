import React from "react";
import MiniDrawer from "../../components/layout/Drawer";
import CustomPaper, { IBreadcrumb } from "../../components/shared/Custom/Paper";
import DocumentService from "src/services/DocumentService";
import Prestamos from "src/components/base/Prestamos";

const breadcrumb: IBreadcrumb[] = [
  {
    icon: "PaidOutlined",
    text: "Prestamos",
    path: "/prestamos"
  },
];

export default function PrestamosPage() {

  DocumentService.setTitle('Prestamos')

  return (
    <MiniDrawer>
      <CustomPaper breadcrumb={breadcrumb}>
        <Prestamos/>
      </CustomPaper>
    </MiniDrawer>
  );
}
