import { IFormObject } from "src/hooks/useForm";

export interface IFormField {
    label?: string;
    name: string;
    required?: boolean;
    order?: { xs: number; sm: number };
    helperText?: any
    formatDisplay?: (value: string) => string
    formatInput?: (value: string) => any
    helperName?: string;
    type?: NumberConstructor | BooleanConstructor | StringConstructor;
    onChange?: () => any
  }

export default class FormService {

    public static getFormObject = (formFields: IFormField[]): {[prop: string]: {required: boolean, value: string}} => {
        let formObject = {}
        formFields.forEach((formField: IFormField) => {
            formObject[formField.name] = {
                required: formField.required,
                value: formField.type === Boolean ? false : ''
            }
        })
        return formObject
    }

    public static getFormattedForm = (form: IFormObject, formFields: IFormField[]): {[prop: string]: any} => {
        let formattedForm = {}
        formFields.forEach((formField) => {
            formattedForm[formField.name] = formField.type(form[formField.name])
        })
        return formattedForm
    }
}