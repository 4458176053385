import { ExpandMore, Phone, RoomOutlined, WhatsApp } from "@mui/icons-material";
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import CustomAccordion from "src/components/shared/Custom/Accordion";
import GpsButton from "src/components/shared/Modals/GpsButton";
import WhatsappButton from "src/components/shared/Modals/WhatsappButton";
import CurrencyService from "src/services/CurrencyService";
import { ICliente } from "src/utils/interfaces";
import _ from "lodash";
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';

interface IInfoBuilder {
  property: string;
  label: string;
  icon?: any;
  format?: (row: any) => any;
  order?: {xs: number, sm: number, md: number}
}

export default function Card({ client }: { client: ICliente }) {
  const targets: IInfoBuilder[] = [
    {
      property: "alias",
      label: "Alias",
      order: { xs: 1, sm: 1, md: 1 }
    },
    {
      property: "direccionLaboral",
      label: "Domicilio laboral",
      icon: <RoomOutlined sx={{color: '#1B23BE'}} />,
      format(row) {
        return row.gpsLaboral ? <>{row.direccionLaboral} <PinDropOutlinedIcon sx={{fontSize: '15px', color: '#F2788E'}}/></> : row.direccionLaboral
      },
      order: { xs: 5, sm: 5, md: 2 }
    },
    {
      property: "nombre",
      label: "Nombre completo",
      order: { xs: 2, sm: 2, md: 3 }
    },
    {
      property: "direccionPersonal",
      label: "Domicilio personal",
      icon: <RoomOutlined sx={{color: '#1B23BE'}} />,
      format(row) {
        return row.gpsPersonal ? <>{row.direccionPersonal} <PinDropOutlinedIcon sx={{fontSize: '15px', color: '#F2788E'}}/></> : row.direccionPersonal
      },
      order: { xs: 6, sm: 6, md: 4 }
    },
    {
      property: "dni",
      label: "DNI/CUIL",
      format(row) {
        return row.dni ? CurrencyService.formatInput(row.dni) : "";
      },
      order: { xs: 3, sm: 3, md: 5 }
    },
    {
      property: "telefono",
      label: "Teléfono",
      icon: <Phone sx={{color: '#1B23BE'}} />,
      format(row) {
        return row.telefonoWp ? <>{row.telefono} <WhatsApp sx={{fontSize: '15px', color: '#09AD00'}}/></> : row.telefono
      },
      order: { xs: 7, sm: 7, md: 6 }
    },
    {
      property: "trabajo",
      label: "Trabajo",
      order: { xs: 4, sm: 4, md: 7 }
    },
    {
      property: "telefonoAlt",
      label: "Teléfono Alternativo",
      icon: <Phone sx={{color: '#1B23BE'}} />,
      format(row) {
        return row.telefonoAltWp ? <>{row.telefonoAlt} <WhatsApp sx={{fontSize: '15px', color: '#09AD00'}}/></> : row.telefonoAlt
      },
      order: { xs: 8, sm: 8, md: 8 }
    },
    {
      property: "observacion",
      label: "Observación",
      order: { xs: 9, sm: 9, md: 9 }
    },
  ];

  const infoBuilder = (
    client: ICliente,
    targets: IInfoBuilder[]
  ): React.ReactNode => {
    return (
      <Grid container spacing={2}>
        {targets.map((target) => {
          return (
            <Grid
              item
              xs={12}
              sm={12}
              md={_.last(targets) === target ? 12 : 6}
              key={target.label}
              order={target.order}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  borderRadius: 1,
                  backgroundColor: "white",
                }}
                p={1}
              >
                {target.icon ?? ""}
                <Typography fontWeight={500} textOverflow='ellipsis' overflow='hidden' whiteSpace='nowrap'>{target.label}:</Typography>
                <Typography fontStyle={_.last(targets) === target ? 'italic' : 'normal'} whiteSpace='nowrap'>{target.format ? target.format(client) ? target.format(client) : '--' : client[target.property] ? client[target.property] : '--'}</Typography>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  return (
    <Box p={2}>
      <Stack direction="row" gap={3} paddingBottom={2}>
        <WhatsappButton data={client} />
        <GpsButton data={client} />
      </Stack>
      <CustomAccordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ flexDirection: "row-reverse", gap: 2 }}
        >
          <Typography fontWeight={500}>Datos del cliente</Typography>
        </AccordionSummary>
        <AccordionDetails>
            {client && infoBuilder(client, targets)}
        </AccordionDetails>
      </CustomAccordion>
    </Box>
  );
}
