import { Skeleton } from "@mui/material";
import React from "react";

interface ICustomSkeleton {
  value: string | number;
  loading: boolean;
  variant?: "text" | "rectangular" | "rounded" | "circular";
  width?: string | number
  height?: string | number
  minWidth?: string | number
}

export default function CustomSkeleton({
  value,
  loading,
  variant = "text",
  width = '25px',
  height = '30px',
  minWidth = '50px'
}: ICustomSkeleton) {
  return (
    <>
      {loading ? (
        <Skeleton
          sx={{
            lineHeight: "27px",
            height,
            width,
            display: "inline-block",
            minWidth
          }}
          variant={variant}
        />
      ) : (
        value
      )}
    </>
  );
}
