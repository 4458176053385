import { ExpandMore } from "@mui/icons-material";
import {
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import _ from "lodash";
import React from "react";
import CustomAccordion from "src/components/shared/Custom/Accordion";
import CurrencyService from "src/services/CurrencyService";
import stc from "string-to-color";

export default function MetodoPago({ data }) {

  const getPercentage = (total) => {
    const sumTotal = _.sum(data.map(item => Number(item.total)))
    return (Number(total)*100/sumTotal).toFixed(2)
  }

  return (
    <>
      {data &&
        data.map(
          ({
            id,
            denominacion,
            total_con_interes,
            total_sin_interes,
            total,
          }) => (
            <CustomAccordion key={id}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={2}
                  paddingRight={"50px"}
                >
                  <Box
                    sx={{
                      backgroundColor: stc(denominacion),
                      height: "20px",
                      width: "20px",
                      borderRadius: 1,
                    }}
                  ></Box>
                  <Stack direction="row" gap={1}>
                    <Typography fontWeight={500}>{denominacion}:</Typography>
                    <Typography>
                      {CurrencyService.formatStatic(Number(total))} <i>(aprox {getPercentage(total)}%)</i>
                    </Typography>
                  </Stack>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Stack>
                  <Stack direction="row" gap={1}>
                    <Typography fontWeight={500}>En pago nominal:</Typography>
                    <Typography>
                      {total_sin_interes
                        ? CurrencyService.formatStatic(Number(total_sin_interes))
                        : "$ 0"}
                    </Typography>
                  </Stack>
                  <Stack direction="row" gap={1}>
                    <Typography fontWeight={500}>
                      En pago de interes:
                    </Typography>
                    <Typography>
                      {total_con_interes
                        ? CurrencyService.formatStatic(Number(total_con_interes))
                        : "$ 0"}
                    </Typography>
                  </Stack>
                </Stack>
              </AccordionDetails>
            </CustomAccordion>
          )
        )}
    </>
  );
}
