import { DialogContent, Stack } from "@mui/material";
import React, { useState } from "react";
import CustomDialog from "src/components/shared/Custom/Dialog";
import CustomDialogTitle from "src/components/shared/Custom/DialogTitle";
import Form from "./Form";
import TableWrapper from "./TableWrapper";

export default function MetodosPago({ open, setOpen }) {

    const [refresher, setRefresher] = useState<boolean>(false)

  return (
    <CustomDialog open={open} onClose={() => setOpen(false)}>
      <CustomDialogTitle
        handleClose={() => setOpen(false)}
        title="Modificar métodos de pago"
        icon="AccountBalanceWalletOutlined"
        iconColor="#1B23BE"
      />
      <DialogContent>
      <Stack gap={3}>
            <Form refresher={refresher} setRefresher={setRefresher} />
            <TableWrapper refresher={refresher} />
          </Stack>
      </DialogContent>
    </CustomDialog>
  );
}
