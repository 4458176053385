import { Stack, Switch, Typography } from '@mui/material'
import React from 'react'
import { IFormComponent } from 'src/hooks/useForm'

export default function Tipo({handleFormChange, getForm}: IFormComponent) {
  return (
    <Stack>
    <Typography fontSize={16} fontWeight={500}>
      Es pago de interés ?
    </Typography>
    <Stack direction={"row"} alignItems={"center"}>
      <Switch
        value={getForm()?.esInteres}
        checked={getForm()?.esInteres}
        name='esInteres'
        onChange={(e) => {handleFormChange(e.target)}}
        sx={{
          "& .MuiSwitch-switchBase": {
            "&.Mui-checked": {
              color: "#0DFF00",
              "& + .MuiSwitch-track": {
                background: "#0DFF00",
              },
            },
          },
        }}
      />
      <Typography>{getForm()?.esInteres ? 'Sí' : 'No'}</Typography>
    </Stack>
  </Stack>
  )
}