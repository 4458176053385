import React, { useEffect } from "react";
import CustomTitle from "src/components/shared/Custom/Title";
import { Stack, Typography } from "@mui/material";
import { ICliente } from "src/utils/interfaces";
import { ESTADOS } from "src/utils/PrestamoEnums";
import PersonIcon from '@mui/icons-material/Person';

export default function Header({data}: {data: ICliente}) {

  return (
    <Stack direction="row" alignItems="center" maxWidth='85%'>
      <PersonIcon
        sx={{
          fontSize: 38,
          color: "white",
          background: "#E3F0FF",
          borderRadius: "100%",
          height: 45,
          width: 45,
          padding: 0.5,
        }}
      />
      <Stack paddingLeft={2}>
        <CustomTitle>{data?.alias}</CustomTitle>
        <Stack direction='row' gap={2} flexWrap='wrap'>
          <Typography>
            Cliente #{data?.id}
          </Typography>
          <Typography>
            {`Préstamos activos: ${data?.prestamos?.filter((prestamo) => prestamo?.estadoId === ESTADOS.ACTIVO)?.length ?? ''}`}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
