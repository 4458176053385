import _ from "lodash";
import React, { useState, useEffect } from "react";
import ApiService from "../services/ApiService";

const filterFunction = (originalData, searchFields, search: string) => {
  let filteredDataUnseted = [];

  for (const field of searchFields) {
    filteredDataUnseted = [
      ...filteredDataUnseted,
      ...originalData.filter((item) => {
        if(_.get(item, field)){
          if(_.get(item, field).toLowerCase().includes(search.toLowerCase())) return item
        }
      }),
    ];
  }

  return _.uniqBy(filteredDataUnseted, _.property("id"));
};

function useTable(endpoint: string, searchFields?: string[]) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [response, setResponse] = useState([]);
  const [data, setData] = useState([]);
  const [metadata, setMetadata] = useState<{total?: number, per_page?: number, current_page?: number}>({});
  const [search, setSearch] = useState<string>("");
  const [originalData, setOriginalData] = useState([]);

  const getData = async (filter: string = ""): Promise<void> => {
    setIsLoading(() => true);

    try {
      const response = await ApiService.get(
        Boolean(filter.length) ? `${endpoint}?=${filter}` : endpoint
      );
      setResponse(() => response);
      setOriginalData(() => response.data);
      if (search) {
        handleSearch();
      } else {
        setData(() => response?.data?.data || response?.data);
      }
      setMetadata(() => response?.data?.meta);
      setIsLoading(() => false);
    } catch (error) {
      setIsLoading(() => false);
    }
  };

  const handleSearch = () => {
    if (search) {
      setData(() => filterFunction(originalData, searchFields, search));
    } else {
      setData(() => originalData);
    }
  };

  useEffect(() => {
    handleSearch();
  }, [search]);

  useEffect(() => {
    getData();
  }, [endpoint]);

  return {
    setIsLoading,
    isLoading,
    data,
    metadata,
    response,
    getData,
    setData,
    search,
    setSearch,
    originalData
  };
}

export default useTable;
