import { PaymentsOutlined, PercentOutlined } from "@mui/icons-material";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Stack,
  StepContent,
  StepLabel,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import CustomSelect from "src/components/shared/Custom/Select";
import CustomStep from "src/components/shared/Custom/Step";
import CustomTextField from "src/components/shared/Custom/TextField";
import useFetch from "src/hooks/useFetch";
import { IFormComponent } from "src/hooks/useForm";

interface IInteres extends IFormComponent {
  interesTotal: string;
  setterInteresTotal: React.Dispatch<React.SetStateAction<string>>;
}

export default function Interes({
  handleFormChange,
  getForm,
  setFormField,
  interesTotal,
  setterInteresTotal,
}: IInteres) {
  const { isLoading, fetchData, data } = useFetch("get", "opciones-intereses");

  useEffect(() => {
    fetchData();
  }, []);

  const handleInteresChange = (optionId: number) => {
    const option = data?.find((item) => item.id === optionId);
    let cantidadCuotas = option?.cantidadCuotas;
    if (!cantidadCuotas) cantidadCuotas = null;
    setFormField("cantidadCuotas", cantidadCuotas);
    setTipoInteres(String(optionId));
  };

  const [tipoInteres, setTipoInteres] = useState<string>("");

  useEffect(() => {
    if (data) {
      if (!tipoInteres && getForm()?.cantidadCuotas) {
        if (
          data?.find((item) => item.cantidadCuotas == getForm()?.cantidadCuotas && item.tipo === getForm()?.tipo)
        ) {
          setTipoInteres(
            String(
              data?.find(
                (item) =>
                  item.cantidadCuotas == getForm()?.cantidadCuotas &&
                  item.tipo === getForm()?.tipo
              ).id
            )
          );
          return
        }
        if (getForm()?.interesTotal) {
          setTipoInteres("99");
          return
        } else {
          setTipoInteres(
            String(
              data?.find(
                (item) => !item.cantidadCuotas && item.tipo === getForm()?.tipo
              ).id
            )
          );
          return
        }
      }
    }
  }, [data]);

  useEffect(() => {
    const option = data?.find((item) => item.id === Number(tipoInteres));
    if (option && option.cantidadCuotas && option.porcentaje) {
      setterInteresTotal(
        String(
          Math.round(option.cantidadCuotas * parseFloat(option.porcentaje))
        )
      );
      setFormField("interesTotal", null);
      return;
    }
    if (
      option &&
      !option.cantidadCuotas &&
      option.porcentaje &&
      getForm()?.cantidadCuotas
      ) {
        setterInteresTotal(
          String(
            Number(getForm()?.cantidadCuotas) * parseFloat(option.porcentaje)
            )
            );
            setFormField("interesTotal", null);
      return;
    }
    setterInteresTotal("");
  }, [tipoInteres, getForm()?.cantidadCuotas]);

  const disabledCuotas = () => {
    if (tipoInteres === "99") return false;
    if (
      tipoInteres &&
      tipoInteres !== "99" &&
      !data?.find((option) => option.id === Number(tipoInteres))?.cantidadCuotas
    )
      return false;
    return true;
  };

  const calcularValorCuota = () => {
    if (getForm()?.valorPrestamo && getForm()?.cantidadCuotas && interesTotal) {
      const valorCuota = Math.round(
        (Number(getForm()?.valorPrestamo) +
          (Number(getForm()?.valorPrestamo) * Number(interesTotal)) / 100) /
          Number(getForm()?.cantidadCuotas)
      );
      setFormField("valorCuota", String(valorCuota));
    }
    return false;
  };

  useEffect(() => {
    calcularValorCuota();
  }, [getForm()?.valorPrestamo, getForm()?.cantidadCuotas, interesTotal]);

  return (
    <>
      <StepLabel>
        <Typography fontSize={18} fontWeight={400} color={"black"}>
          Seleccionar tipo de interés
        </Typography>
      </StepLabel>
      <StepContent>
        <Stack gap={2}>
          <FormControl fullWidth>
            <InputLabel
              sx={{ color: tipoInteres ? "secondary.main" : "black" }}
            >
              Tipo de interés
            </InputLabel>
            <CustomSelect
              value={tipoInteres}
              label="Tipo de interés"
              variant="outlined"
              placeholder="Elegir una opción"
              sx={{ minWidth: "240px" }}
              disabled={isLoading || !getForm()?.tipo}
              onChange={(e) =>
                handleInteresChange(Number(e.target.value as string))
              }
            >
              {data
                ?.filter((option) => option?.tipo === getForm()?.tipo)
                .map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option?.descripcion}
                  </MenuItem>
                ))}
              <MenuItem value={99}>Personalizado...</MenuItem>
            </CustomSelect>
          </FormControl>
          {tipoInteres === "99" && (
            <CustomTextField
              value={getForm()?.interesTotal}
              onChange={(e) => {
                const value = e.target.value.replace(/\D/g, "");
                setFormField("interesTotal", value);
              }}
              placeholder="Valor del interés total"
              sx={{
                minWidth: "240px",
                width: "100%",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PercentOutlined />
                  </InputAdornment>
                ),
              }}
            />
          )}
          <CustomTextField
            name="cantidadCuotas"
            placeholder="Cantidad de cuotas"
            sx={{
              minWidth: "240px",
              width: "100%",
              "& .MuiInputBase-root.Mui-disabled": {
                fontWeight: getForm()?.cantidadCuotas && "600",
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#bdbdbd",
                },
              },
            }}
            disabled={disabledCuotas()}
            value={getForm()?.cantidadCuotas}
            onChange={(e) =>
              handleFormChange(e.target, (value) => {
                value = value.replace(/\D/g, "");
                return value;
              })
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PaymentsOutlined />
                </InputAdornment>
              ),
            }}
          />
        </Stack>
      </StepContent>
    </>
  );
}
