import {
  Box,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Close from "@mui/icons-material/Close";
import CustomButton from "../Custom/Button";
import CustomDialog from "../Custom/Dialog";
import CustomTitle from "../Custom/Title";
import CustomTextField from "../Custom/TextField";
import useFetch from "src/hooks/useFetch";
import useSnack from "src/hooks/useSnackbar";

interface IBorrarModal<T> {
  open: boolean;
  handleClose: () => void;
  entity: T;
  type: "préstamo" | "cliente" | "pago" | "usuario" | "interés";
  endpoint: string;
  onDelete: () => any
}

export default function BorrarModal({
  open,
  handleClose,
  entity,
  type,
  endpoint,
  onDelete
}: IBorrarModal<typeof entity>) {

  const [borradoText, setBorradoText] = useState<string>('')

  const {fetchData, isLoading} = useFetch('delete', `${endpoint}/${entity?.id}`)
  const [openSnackbar] = useSnack();

  const sendDelete = async () => {
    try {
        await fetchData()
        handleClose();
        openSnackbar(`El ${type} ${type === 'cliente' ? entity?.alias : ''} ${type === 'usuario' ? `${entity?.nombre} ${entity?.apellido}` : '' } ha sido eliminado.`)
        onDelete()
    } catch(error) {
        openSnackbar(`Hubo un error al intentar eliminar.`)
    }
    setBorradoText('')
  }

  return (
    <>
      <CustomDialog open={open} onClose={() => {handleClose(); setBorradoText('')}}>
        <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap={5}
          >
            <CustomTitle color={'#F2788E'} >¡Cuidado! Estas por eliminar un {type}</CustomTitle>
            <Box>
              <IconButton onClick={() => {handleClose(); setBorradoText('')}}>
                <Close />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Stack gap={2} paddingBottom={2}>
            <Typography>
              Esto implica borrar permanentemente el {type} {type === 'cliente' && 'y sus prestamos con pagos realizados'} {type === 'préstamo' && 'y sus pagos realizados'} (y sin vuelta atrás)
              de la base de datos. ¿Está seguro que desea hacerlo?
            </Typography>
            <CustomTextField value={borradoText} onChange={(e) => setBorradoText(e.target.value)} placeholder='Escriba "Borrar" sin las comillas para habilitar el botón de borrado' />
          </Stack>
          <Stack direction="row" justifyContent="flex-end" gap={1}>
            <CustomButton disabled={isLoading} sx={{color: 'black', '&:hover': {shadow: 0, backgroundColor: 'white'}}} onClick={() => {handleClose(); setBorradoText('')}}>Cancelar</CustomButton>
            <CustomButton
              variant="outlined"
              sx={{
                color: "#F2788E",
                borderColor: "#F2788E",
                "&:hover": { backgroundColor: "white", borderColor: "#F2788E" },
              }}
              disabled={borradoText !== 'Borrar' || isLoading}
              onClick={() => sendDelete()}
            >
              Eliminar
            </CustomButton>
          </Stack>
        </DialogContent>
      </CustomDialog>
    </>
  );
}
