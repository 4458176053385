import { WhatsApp } from "@mui/icons-material";
import React, { useState } from "react";
import { ICliente } from "src/utils/interfaces";
import CustomButton from "../Custom/Button";
import WhatsappModal from "./WhatsappModal";

export interface IWhatsappButton {
  telefono: string;
  telefonoAlt: string;
  telefonoWp: boolean;
  telefonoAltWp: boolean;
}

export default function WhatsappButton({ data }: { data: IWhatsappButton | ICliente }) {
  const verifyMinimum = () => {
    return data?.telefonoWp || data?.telefonoAltWp;
  };

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [wpEntity, setWpEntity] = useState<IWhatsappButton | ICliente>(null);
  const handleOpenWpModal = () => {
    setWpEntity(() => data);
    setOpenModal(true);
  };

  return (
    <>
      <CustomButton
        sx={{ minWidth: "135px" }}
        startIcon={<WhatsApp />}
        variant="contained"
        buttonColor="#8EC3EF"
        disabled={!verifyMinimum()}
        onClick={() => handleOpenWpModal()}
      >
        Whatsapp
      </CustomButton>
      <WhatsappModal
        entity={wpEntity}
        open={openModal}
        handleClose={() => setOpenModal(false)}
      />
    </>
  );
}
