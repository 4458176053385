import {
  Add,
  BorderColorOutlined,
  Edit,
  ExpandMore,
  PostAdd,
} from "@mui/icons-material";
import {
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Input,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import CustomAccordion from "src/components/shared/Custom/Accordion";
import CustomButton from "src/components/shared/Custom/Button";
import useFetch from "src/hooks/useFetch";

export default function LiveForm({ refresher, setRefresher }) {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [form, setForm] = useState<
    { tipo: "SEMANAL" | "MENSUAL"; porcentaje: string }[]
  >([
    {
      tipo: "SEMANAL",
      porcentaje: null,
    },
    {
      tipo: "MENSUAL",
      porcentaje: null,
    },
  ]);

  const { fetchData: fetchDataPut } = useFetch("put");

  const handleFormChange = (field: "SEMANAL" | "MENSUAL", value) => {
    if (!/^\d+[,]?\d{0,2}$/.test(value) && value !== "") return;
    const type = form.find((item) => item.tipo === field);
    type.porcentaje = value;
    const alterType = form.find((item) => item.tipo !== field);
    setForm([type, alterType]);
  };

  const handleSubmit = async () => {
    const formattedForm = form.map((item) => ({
      ...item,
      porcentaje: Number(item.porcentaje.replace(",", ".")),
    }));
    await fetchDataPut("editar-opciones-intereses", { tipos: formattedForm });
    setRefresher(!refresher);
    setExpanded(false);
  };

  const { data, fetchData } = useFetch("get", "opciones-intereses");

  const loadData = async () => {
    await fetchData();
    setForm([
      {
        tipo: "SEMANAL",
        porcentaje: data
          .find((item) => item.tipo === "SEMANAL" && !item.cantidadCuotas)
          .porcentaje.replace(".", ","),
      },
      {
        tipo: "MENSUAL",
        porcentaje: data
          .find((item) => item.tipo === "MENSUAL" && !item.cantidadCuotas)
          .porcentaje.replace(".", ","),
      },
    ]);
  };

  useEffect(() => {
    loadData();
  }, [expanded]);

  return (
    <CustomAccordion sx={{ backgroundColor: "#f2f2f2" }} expanded={expanded}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ flexDirection: "row-reverse", gap: 2 }}
        onClick={() => setExpanded(!expanded)}
      >
        <Stack direction="row" gap={1}>
          <BorderColorOutlined sx={{ color: "#8ec3ef" }} />
          <Typography fontWeight={500}>Editar intereses base</Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Stack gap={2}>
          <Stack direction="row" gap={1} alignItems="center" flexWrap="nowrap">
            <Typography>
              Porcentaje de interés <b>semanal</b>:{" "}
            </Typography>
            <FormControl variant="standard">
              <Input
                sx={{maxWidth: '110px'}}
                onChange={(e) => handleFormChange("SEMANAL", e.target.value)}
                name="SEMANAL"
                value={form.find((item) => item.tipo === "SEMANAL").porcentaje}
                endAdornment={
                  <InputAdornment position="start">%</InputAdornment>
                }
              />
            </FormControl>
          </Stack>
          <Stack direction="row" gap={1} alignItems="center" flexWrap="nowrap">
            <Typography>
              Porcentaje de interés <b>mensual</b>:{" "}
            </Typography>
            <FormControl variant="standard">
              <Input
                sx={{maxWidth: '110px'}}
                onChange={(e) => handleFormChange("MENSUAL", e.target.value)}
                name="MENSUAL"
                value={form.find((item) => item.tipo === "MENSUAL").porcentaje}
                endAdornment={
                  <InputAdornment position="start">%</InputAdornment>
                }
              />
            </FormControl>
          </Stack>
          <Stack justifyContent="flex-end" direction="row">
            <CustomButton
              onClick={() => handleSubmit()}
              buttonColor="#1b75be"
              variant="contained"
              startIcon={<Edit />}
              disabled={form.some(
                (item) =>
                  !item.porcentaje ||
                  item.porcentaje === "" ||
                  item.porcentaje === "0" ||
                  !Number(item.porcentaje.replace(",", ""))
              )}
            >
              Guardar
            </CustomButton>
          </Stack>
        </Stack>
      </AccordionDetails>
    </CustomAccordion>
  );
}
