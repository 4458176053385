import { AccountBalanceWalletOutlined, PercentOutlined, SupervisorAccountOutlined } from '@mui/icons-material'
import { DialogContent, Divider, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import CustomButton from 'src/components/shared/Custom/Button'
import CustomDialog from 'src/components/shared/Custom/Dialog'
import CustomDialogTitle from 'src/components/shared/Custom/DialogTitle'
import Usuarios from './Usuarios'
import MetodosPago from './MetodosPago'
import Intereses from './Intereses'

export default function ConfiguracionModal({open, setOpen}) {

  const [openUsuarios, setOpenUsuarios] = useState<boolean>(false)
  const [openMetodosPago, setOpenMetodosPago] = useState<boolean>(false)
  const [openValoresInteres, setOpenValoresInteres] = useState<boolean>(false)

  return (
    <>
    <Usuarios open={openUsuarios} setOpen={setOpenUsuarios} />
    <MetodosPago open={openMetodosPago} setOpen={setOpenMetodosPago} />
    <Intereses open={openValoresInteres} setOpen={setOpenValoresInteres} />
    <CustomDialog open={open} onClose={() => setOpen(false)}>
        <CustomDialogTitle handleClose={() => setOpen(false)} icon='SettingsOutlined' title='Configuración' iconColor='#1B23BE' />
        <DialogContent>
            <Stack gap={4} padding={2}>
                <Stack direction='row' alignItems='center' sx={{cursor: 'pointer'}} gap={2} onClick={() => setOpenUsuarios(true)}>
                    <SupervisorAccountOutlined sx={{color: '#1B23BE'}} />
                    <Typography>Administrar usuarios</Typography>
                </Stack>
                <Stack direction='row' alignItems='center' sx={{cursor: 'pointer'}} gap={2} onClick={() => setOpenMetodosPago(true)}>
                    <AccountBalanceWalletOutlined sx={{color: '#1B23BE'}} />
                    <Typography>Modificar métodos de pago</Typography>
                </Stack>
                <Stack direction='row' alignItems='center' sx={{cursor: 'pointer'}} gap={2} onClick={() => setOpenValoresInteres(true)}>
                    <PercentOutlined sx={{color: '#1B23BE'}} />
                    <Typography>Modificar valores de interés</Typography>
                </Stack>
            </Stack>
        </DialogContent>
    </CustomDialog>
    </>
  )
}
