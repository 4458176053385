import { Box, Stack, Typography } from "@mui/material";
import axios from "axios";
import { format } from "date-fns";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomMenu from "src/components/shared/Custom/Menu";
import VisitarHoyModal from "src/components/shared/Modals/VisitarHoyModal";
import { IAction } from "src/components/shared/Tabla";
import useFetch from "src/hooks/useFetch";
import useSnack from "src/hooks/useSnackbar";

export default function Card({ item, refreshTables }) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [openVisitarHoy, setOpenVisitarHoy] = useState<boolean>(false)

  const actions: IAction[] = [
    {
      label: "Visitar hoy",
      onClick(row) {
        setOpenVisitarHoy(true)
      },
      disabled(row) {
        if(item.fechaAplazo === format(new Date(), 'dd/MM/yyyy')) return true
        else return false
      },
    },
    {
      label: "Modificar aplazo",
      onClick(row) {
        setSearchParams({ aplazar: item.id });
      },
    },
    {
      label: "Ver cliente",
      onClick(row) {
        navigate(`/clientes/${item.prestamo.clienteId}`);
      },
    },
  ];

  const { fetchData } = useFetch("post", "aplazos-listas", {
    fechaAplazo: format(new Date(), 'yyyy-MM-dd'),
    comentario: null,
    listaId: item?.id,
  });

  const [openSnackbar] = useSnack();

  const handleSubmit = async () => {
    const response: any = await fetchData();
    if (axios.isAxiosError(response)) {
      openSnackbar(response?.response?.data?.message);
    } else {
      openSnackbar(
        `La visita de ${response?.data?.lista?.prestamo?.cliente?.alias} fue agregada a la lista de hoy.`
      );
      setOpenVisitarHoy(false);
      refreshTables()
    }
  };

  return (
    <>
      <VisitarHoyModal handleSubmit={handleSubmit} open={openVisitarHoy} setOpen={setOpenVisitarHoy} />
      <Stack
        gap={1}
        sx={{
          boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
          borderRadius: "7px",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ backgroundColor: "#FCF1E8", padding: 1 }}
          alignItems="center"
        >
          <Typography fontWeight={600} onClick={() => navigate(`/clientes/${item?.prestamo?.cliente?.id}`)}
            sx={{ ":hover": {
              cursor: 'pointer',
              textDecoration: 'underline'
            } }}>
            {item?.prestamo?.cliente?.alias}
          </Typography>
          <CustomMenu data={null} actions={actions} />
        </Stack>
        <Stack gap={1} p={1}>
          <Stack direction="row" gap={1}>
            <Typography fontWeight={600} display="inline">
              Fecha aplazo:
            </Typography>
            <Typography display="inline">{item?.fechaAplazo}</Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            <Typography fontWeight={600} display="inline">
              Fecha inicial:
            </Typography>
            <Typography display="inline">{item?.fechaLista}</Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            <Typography fontWeight={600} display="inline">
              Observación:
            </Typography>
            <Typography display="inline">
              {item?.historialAplazos?.at(-1)?.comentario ?? ""}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
