import { Close, NoteAddOutlined } from "@mui/icons-material";
import {
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Stepper,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import CustomButton from "src/components/shared/Custom/Button";
import CustomDialog, {
  CustomDialogActions,
} from "src/components/shared/Custom/Dialog";
import useForm, { IFormObject } from "src/hooks/useForm";
import useSnack from "src/hooks/useSnackbar";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import Resumen from "./Fields/resumen";
import CustomStep from "src/components/shared/Custom/Step";
import Monto from "./Fields/monto";
import Fecha from "./Fields/fecha";
import Metodo from "./Fields/metodo";
import Observacion from "./Fields/observacion";
import Tipo from "./Fields/tipo";
import { IPago, IPrestamo } from "src/utils/interfaces";
import useFetch from "src/hooks/useFetch";
import ApiService from "src/services/ApiService";
import { format, parse } from "date-fns";

export default function Pago({ getData }) {
  const endpoint: string = "pagos";

  let [searchParams, setSearchParams] = useSearchParams();

  const [open, setOpen] = useState<boolean>(false);

  const handleClose = () => {
    setSearchParams({});
  };

  useEffect(() => {
    if (
      searchParams.get("entity") === "pago" &&
      searchParams.get("prestamoId") &&
      searchParams.get("listaId")
    ) {
      setOpen(true);
    } else {
      setOpen(false);
      clearForm();
    }
  }, [searchParams]);

  const [openSnackbar] = useSnack();

  const sendForm = async () => {
    const response = await ApiService["post"](endpoint, {
      ...getSendForm(),
      importe: Number(getForm().importe),
      prestamoId: searchParams.get("prestamoId"),
    });
    if (response?.response?.status === 400) {
      openSnackbar(
        `Hubo un error al intentar crear el préstamo: ${response.response.data.message}`
      );
    } else {
      await ApiService["put"](`listas/${searchParams.get("listaId")}`, {
        pagado: true,
        pagoAsociadoId: response?.data?.id,
      });
      getData();
      clearForm();
      setSearchParams({});
      openSnackbar(`Se agregó un pago`);
    }
  };

  const fields: IFormObject = {
    fecha: { required: true, value: null },
    importe: { required: true, value: null },
    metodoPagoId: { required: true, value: null },
    observacion: { required: false, value: null },
    esInteres: { required: false, value: false },
  };

  const {
    getForm,
    getSendForm,
    handleFormChange,
    setFormField,
    saveButtonState,
    sendRequest,
    clearForm,
    loadEntity,
  } = useForm(fields, sendForm);

  const { data, fetchData } = useFetch(
    "get",
    `prestamos/${searchParams.get("prestamoId")}`
  );

  useEffect(() => {
    if (searchParams.get("prestamoId")) {
      fetchData();
    }
  }, [searchParams.get("prestamoId")]);

  return (
    <CustomDialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap={5}
        >
          <Box
            fontSize={18}
            fontWeight={500}
            display={"flex"}
            alignItems="center"
            gap={2}
          >

              <NoteAddOutlined
                sx={{
                  fontSize: 32,
                  color: "white",
                  background: "#8EC3EF",
                  borderRadius: "100%",
                  height: 30,
                  width: 30,
                  padding: 0.5,
                }}
              />

            Agregar pago
          </Box>
          <Box>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Stack paddingTop={1} gap={2} >
          <Resumen data={data} type={"create"} />
          <Tipo handleFormChange={handleFormChange} getForm={getForm} />
          <Stepper orientation="vertical">
            <CustomStep>
              <Monto
                handleFormChange={handleFormChange}
                getForm={getForm}
                data={data}
                setFormField={setFormField}
                type={"create"}
              />
            </CustomStep>
            <CustomStep>
              <Fecha handleFormChange={handleFormChange} getForm={getForm} />
            </CustomStep>
            <CustomStep completed>
              <Metodo
                handleFormChange={handleFormChange}
                getForm={getForm}
                setFormField={setFormField}
              />
            </CustomStep>
          </Stepper>
          <Observacion handleFormChange={handleFormChange} getForm={getForm} />
        </Stack>
      </DialogContent>
      <CustomDialogActions>
        <CustomButton
          variant="contained"
          buttonColor="#1B75BE"
          disabled={saveButtonState()}
          onClick={sendRequest}
        >
          Agregar
        </CustomButton>
      </CustomDialogActions>
    </CustomDialog>
  );
}
