import { Event } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomButton from "src/components/shared/Custom/Button";
import CustomDatePicker from "src/components/shared/Custom/DatePicker";
import { IFinanzasFilter } from "../..";
import { format } from "date-fns";

export default function SpecificDate({filter, setFilter, handleSearch}: {handleSearch: () => void, filter: IFinanzasFilter, setFilter: React.Dispatch<React.SetStateAction<IFinanzasFilter>>}) {

  useEffect(() => {
    if(filter.type !== 'specificDate'){
      setValue(null)
    }
  }, [filter.type])

  const [value, setValue] = useState(null)

  const handleChange = (e) => {
    setFilter({type: 'specificDate', value: e ? format(e, 'yyyy-MM-dd') : null})
    setValue(e)
  }

  return (
    <Stack gap={1}>
      <Stack direction="row" gap={1}>
        <Event sx={{ color: "#1B23BE" }} />
        <Typography fontSize={"18px"} fontWeight={"500"}>
          Fecha específica
        </Typography>
      </Stack>
      <Stack direction="row" gap={1}>
        <CustomDatePicker value={value} onChange={(e) => {handleChange(e)}} label="Fecha" />
        <CustomButton onClick={handleSearch} variant="contained" buttonColor="#1B75BE" disabled={!filter.type || filter.type !== 'specificDate'}>
          Calcular
        </CustomButton>
      </Stack>
    </Stack>
  );
}
