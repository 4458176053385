import React, { useEffect } from "react";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import { Stack, Typography } from "@mui/material";
import { DirectionsCarOutlined } from "@mui/icons-material";
import CustomTitle from "src/components/shared/Custom/Title";
import useFetch from "src/hooks/useFetch";
import { format } from "date-fns";

export default function Header({totales}: {totales: {total, totalPagados}}) {

  return (
    <Stack direction="row" alignItems="center">
      <DirectionsCarOutlined
        sx={{
          fontSize: 32,
          color: "white",
          background: "#8EC3EF",
          borderRadius: "100%",
          height: 35,
          width: 35,
          padding: 0.5,
        }}
      />
      <Stack paddingLeft={2}>
        <CustomTitle>Lista de clientes a visitar hoy</CustomTitle>
        <Stack direction='row' gap={2} flexWrap='wrap'>
          <Typography>
            Total:{" "}{totales?.total ?? ''}
          </Typography>
          <Typography>
            Pagos:{" "}{totales?.totalPagados ?? ''}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
