import { Box, Divider, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomButton from "src/components/shared/Custom/Button";
import { ICliente } from "src/utils/interfaces";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import TableWrapper from "./TableWrapper";
import Finalizados from "./Finalizados";
import EntityForm from "./EntityForms/EntityForm";
import { useSearchParams } from "react-router-dom";

export default function Prestamos({ client }: { client: ICliente }) {
  let [searchParams, setSearchParams] = useSearchParams();

  const openEntityForm = () => {
    setSearchParams({ type: "create", entity: "prestamo" });
  };

  return (
    <>
      <Box p={2} paddingTop={1}>
        <Stack direction="row" alignItems="center" gap={2} paddingBottom={3}>
          <Stack direction="row" alignItems="center" gap={1}>
            <PaidOutlinedIcon sx={{ color: "#1B23BE" }} />
            <Typography fontWeight={500}>Préstamos</Typography>
          </Stack>
          <Divider flexItem orientation="vertical" variant="middle" />
          <CustomButton
            startIcon={<PaidOutlinedIcon />}
            sx={{
              color: "#57DF4F",
              borderColor: "#57DF4F",
              "&:hover": { backgroundColor: "white", borderColor: "#57DF4F" },
            }}
            onClick={openEntityForm}
            variant="outlined"
          >
            Añadir préstamo
          </CustomButton>
        </Stack>
        <TableWrapper client={client} />
        <Finalizados client={client} />
      </Box>
    </>
  );
}
