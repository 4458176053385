import React from "react";
import Header from "./Header";
import { Box, Stack } from "@mui/material";
import CustomMenu from "src/components/shared/Custom/Menu";
import { IAction } from "src/components/shared/Tabla";
import CustomButton from "src/components/shared/Custom/Button";

export default function Infobar({
  editing,
  setEditing,
  setOpenProximos,
  totales
}: {
  editing: boolean;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenProximos: React.Dispatch<React.SetStateAction<boolean>>;
  totales: {total, totalPagados}
}) {
  const actions: IAction[] = [
    {
      label: "Editar lista",
      onClick(row) {
        setEditing(!editing);
      },
    },
    {
      label: "Ver próximos préstamos",
      onClick(row) {
        setOpenProximos(true)
      },
    },
  ];

  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      alignItems="center"
      justifyContent="space-between"
      p={2}
      rowGap={2}
      paddingTop={0}
    >
      <Box sx={{maxWidth: '85%'}}>
      <Header totales={totales} />
      </Box>
      <Box>

      {editing ? (
        <CustomButton
        variant="outlined"
        sx={{
          minWidth: '310px',
          color: "black",
          border: '3px solid',
          borderColor: "#EFB98E",
          "&:hover": { backgroundColor: "white",  border: '3px solid', borderColor: "#EFB98E"},
        }}
        onClick={() => setEditing(!editing)}
        >
          Finalizar edición
        </CustomButton>
      ) : (
        <CustomMenu data={null} actions={actions} />
        )}
        </Box>
    </Stack>
  );
}
