import React from 'react'
import CustomDialog, { CustomDialogActions } from '../Custom/Dialog'
import CustomDialogTitle from '../Custom/DialogTitle'
import CustomButton from '../Custom/Button'
import { DialogContent, Typography } from '@mui/material'

export default function VisitarHoyModal({open, setOpen, handleSubmit}) {
  return (
    <CustomDialog open={open} onClose={() => setOpen(false)}>
        <CustomDialogTitle iconColor='#1B23BE' icon='NotificationsActiveOutlined' title='Visitar hoy' handleClose={() => setOpen(false)} />
        <DialogContent>
            <Typography>
                Confirmá si querés visitar hoy al cliente
            </Typography>
        </DialogContent>
        <CustomDialogActions>
            <CustomButton sx={{color: 'black', '&:hover': {shadow: 0, backgroundColor: 'white'}}} onClick={() => setOpen(false)}>Cancelar</CustomButton>
            <CustomButton variant='contained' buttonColor='#1B75BE' onClick={handleSubmit}>Confirmar</CustomButton>
        </CustomDialogActions>
    </CustomDialog>
  )
}
