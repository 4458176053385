export default class AuthService {

    public static getAuth(): {username: string, password: string, fullName: string, rolId: number} {
        try {
            if(localStorage.getItem('auth')){
                return JSON.parse(localStorage.getItem('auth') as string)
            } else {
                return null
            }
        } catch (error) {
            return error
        }
    }

    public static setAuth(username: string, password: string, fullName: string, rolId: number) {
        try {
            let JSONAuth = JSON.stringify({username, password, fullName, rolId})
            localStorage.setItem('auth', JSONAuth)
        } catch (error) {
            return error
        }
    }

    public static deslog() {
        try {
            localStorage.removeItem('auth')
        } catch (error) {
            return error
        }
    }
}