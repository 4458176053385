import React, { useState } from 'react'
import EntityForm from './EntityForm'
import Infobar from './Infobar'
import TableWrapper from './TableWrapper'

export default function Clientes() {

  return (
    <>
      <EntityForm/>
      <Infobar/>
      <TableWrapper/>
    </>
  )
}
