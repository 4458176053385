import { EventNote } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import React, {useEffect, useState} from 'react'
import CustomButton from 'src/components/shared/Custom/Button';
import CustomDatePicker from 'src/components/shared/Custom/DatePicker';
import { IFinanzasFilter } from '../..';
import { format } from 'date-fns';

export default function BetweenDates({filter, setFilter, handleSearch}: {handleSearch: () => void, filter: IFinanzasFilter, setFilter: React.Dispatch<React.SetStateAction<IFinanzasFilter>>}) {

  useEffect(() => {
    if(filter.type !== 'betweenDates'){
      setValueStart(null)
      setValueEnd(null)
    }
  }, [filter.type])

  const [valueStart, setValueStart] = useState(null)
  const [valueEnd, setValueEnd] = useState(null)

  const handleChange = (e, type: 'startDate' | 'endDate') => {
    setFilter({type: 'betweenDates', value: {...filter.value, [type]: e ? format(e, 'yyyy-MM-dd') : null}})
    type === 'startDate' ? setValueStart(e) : setValueEnd(e)
  }

  return (
        <Stack gap={1}>
          <Stack direction="row" gap={1}>
            <EventNote sx={{ color: "#1B23BE" }} />
            <Typography fontSize={"18px"} fontWeight={"500"}>
              Entre fechas
            </Typography>
          </Stack>
          <Stack gap={1}>
          <CustomDatePicker value={valueStart} onChange={(e) => {handleChange(e, 'startDate')}} label="Desde (inclusive)" />
          <Stack direction='row' gap={1}>
          <CustomDatePicker minDate={valueStart} value={valueEnd} onChange={(e) => {handleChange(e, 'endDate')}} label="Hasta (inclusive)" />
            <CustomButton onClick={handleSearch} disabled={!filter.type || filter.type !== 'betweenDates'}  variant="contained" buttonColor="#1B75BE">Calcular</CustomButton>
          </Stack>
          </Stack>
        </Stack>
      );
}
