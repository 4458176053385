import React from "react";
import { createBrowserRouter, redirect } from "react-router-dom";
import ClientePage from "src/pages/clientes/detalle";
import PrestamosPage from "src/pages/prestamos";
import ClientesPage from "../pages/clientes";
import HomePage from "../pages/home";
import LoginPage from "../pages/login";
import ApiService from "../services/ApiService";
import FinanzasPage from "src/pages/finanzas";

const loaderLogin = async () => {
  const isLoggedIn = await ApiService.isLogged();

  if (isLoggedIn) {
    return redirect("/inicio");
  }
  return null
};

const loaderCommon = async () => {
  const isLoggedIn = await ApiService.isLogged();

  if (!isLoggedIn) {
    return redirect("/");
  }
  return null
};

const router = createBrowserRouter([
  {
    path: "/",
    element: (
        <LoginPage />
    ),
    loader: loaderLogin
  },
  {
    path: "/inicio",
    element: (
        <HomePage />
    ),
    loader: loaderCommon,
  },
  {
    path: "/clientes",
    element: (
      <ClientesPage />
    ),
    loader: loaderCommon,
  },
  {
    path: '/clientes/:id',
    element: (
      <ClientePage  />
    ),
    loader: loaderCommon
  },
  {
    path: '/prestamos',
    element: (
      <PrestamosPage />
    ),
    loader: loaderCommon
  },
  {
    path: '/finanzas',
    element: (
      <FinanzasPage />
    ),
    loader: loaderCommon
  }
]);

export default router;
