import { DialogContent, Stack } from "@mui/material";
import React, { useState } from "react";
import CustomDialog from "src/components/shared/Custom/Dialog";
import CustomDialogTitle from "src/components/shared/Custom/DialogTitle";
import TableWrapper from "./TableWrapper";
import LiveForm from "./LiveForm";
import Form from "./Form";

export default function Intereses({ open, setOpen }) {
  const [refresher, setRefresher] = useState<boolean>(false);

  return (
    <CustomDialog open={open} onClose={() => setOpen(false)}>
      <CustomDialogTitle
        handleClose={() => setOpen(false)}
        title="Modificar valores de interés"
        icon="PercentOutlined"
        iconColor="#1B23BE"
      />
      <DialogContent>
        <Stack gap={3}>
            <LiveForm refresher={refresher} setRefresher={setRefresher} />
            <Form refresher={refresher} setRefresher={setRefresher} />
            <TableWrapper refresher={refresher} />
        </Stack>
      </DialogContent>
    </CustomDialog>
  );
}
