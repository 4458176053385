import _ from "lodash";
import React from "react";
import stc from "string-to-color";

export default function PercentageBar({ data }) {
  const getPercentage = (total) => {
    const sumTotal = _.sum(data.map((item) => Number(item.total)));
    return ((Number(total) * 100) / sumTotal).toFixed(2);
  };

  return (
    <div
      style={{
        display: "flex",
        borderRadius: "5px",
        textAlign: "center",
        minWidth: "300px",
        overflow: "hidden",
        border: (data && data.length) ? '' : '1px solid gray'
      }}
    >
      {(!data || !data.length) && (
        <div
          style={{
            boxSizing: "border-box",
            padding: "5px 0",
            width: `100%`,
          }}
        >
          Sin datos
        </div>
      )}
      {data &&
        data.map(({ id, denominacion, total }) => (
          <div
            key={id}
            style={{
              boxSizing: "border-box",
              padding: "5px 0",
              backgroundColor: stc(denominacion),
              width: `${getPercentage(total)}%`,
              minWidth: 'fit-content',
              color: "white",
              fontWeight: "bold",
            }}
          >
            {`${getPercentage(total)}%`}
          </div>
        ))}
    </div>
  );
}
