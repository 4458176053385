import { CalendarMonth } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import CustomButton from 'src/components/shared/Custom/Button';
import CustomDatePicker from 'src/components/shared/Custom/DatePicker';
import { IFinanzasFilter } from '../..';
import { format, parse } from 'date-fns';

export default function SpecificMonth({filter, setFilter, handleSearch}: {handleSearch: () => void, filter: IFinanzasFilter, setFilter: React.Dispatch<React.SetStateAction<IFinanzasFilter>>}) {

  useEffect(() => {
    if(filter.type !== 'specificMonth'){
      setValue(null)
    }
  }, [filter.type])

  const [value, setValue] = useState(null)

  const handleChange = (e) => {
    setFilter({type: 'specificMonth', value: e ? format(e, 'yyyy-MM') : null})
    setValue(e)
  }

  return (
        <Stack gap={1}>
          <Stack direction="row" gap={1}>
            <CalendarMonth sx={{ color: "#1B23BE" }} />
            <Typography fontSize={"18px"} fontWeight={"500"}>
              Mes específico
            </Typography>
          </Stack>
          <Stack direction='row' gap={1}>
          <CustomDatePicker value={value} format='MM/yyyy' views={['month', 'year']} onChange={(e) => {handleChange(e)}} label="Mes y año" />
            <CustomButton onClick={handleSearch} disabled={!filter.type || filter.type !== 'specificMonth'} variant="contained" buttonColor="#1B75BE">Calcular</CustomButton>
          </Stack>
        </Stack>
      );
}
