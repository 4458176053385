import {
  Add,
  AddBox,
  AddCircle,
  AttachMoney,
  Delete,
  SettingsApplicationsOutlined,
} from "@mui/icons-material";
import {
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import CustomButton from "src/components/shared/Custom/Button";
import CustomCheckbox from "src/components/shared/Custom/Checkbox";
import CustomTextField from "src/components/shared/Custom/TextField";
import useFetch from "src/hooks/useFetch";
import CurrencyService from "src/services/CurrencyService";

export default function Opcion({
  title,
  opciones,
  handleChangeOpcion,
  editMode,
  setEditMode,
  refresher,
  setRefresher,
  elegidos
}: {
  title: "Semanal" | "Mensual";
  opciones: {
    id: number;
    valorPrestamo: number;
    tipo: "SEMANAL" | "MENSUAL";
  }[];
  handleChangeOpcion: (
    checked: boolean,
    value: { valorPrestamo: number; tipo: "SEMANAL" | "MENSUAL" }
  ) => void;
  editMode: "Semanal" | "Mensual";
  setEditMode: React.Dispatch<React.SetStateAction<"Semanal" | "Mensual">>;
  refresher: boolean;
  setRefresher: React.Dispatch<React.SetStateAction<boolean>>;
  elegidos: {
    valorPrestamo: number;
    tipo: "SEMANAL" | "MENSUAL";
  }[];
}) {
  const endpoint = "opciones-tabla-valores";

  const { fetchData: deleteMethod } = useFetch("delete");
  const { fetchData: postMethod } = useFetch("post");

  const handleDelete = async (id) => {
    await deleteMethod(`${endpoint}/${id}`);
    setRefresher(!refresher);
  };

  const handlePost = async (payload) => {
    await postMethod(endpoint, payload);
    setRefresher(!refresher);
    setValorPrestamo(null)
  };

  useEffect(() => setValorPrestamo(null), [editMode])

  const [valorPrestamo, setValorPrestamo] = useState<string>(null);

  return (
    <Stack height={"50%"} gap={2} paddingBottom={2}>
      <Stack
        direction="row"
        p={1}
        justifyContent="space-between"
        sx={{ backgroundColor: "#8EC3EF40" }}
        alignItems="center"
      >
        <Typography paddingLeft={4} fontWeight={500}>
          {title}
        </Typography>
        <CustomButton
          sx={{
            color: editMode === title ? "#1B75BE" : "white",
            borderColor: "#1B75BE",
            backgroundColor: editMode === title ? "white" : "#1B75BE",
            "&:hover": editMode === title && {
              backgroundColor: "white",
              borderColor: "#1B75BE",
            },
          }}
          variant={editMode === title ? "outlined" : "contained"}
          size="small"
          disabled={editMode && editMode !== title}
          onClick={() => {
            editMode === title ? setEditMode(null) : setEditMode(title);
          }}
        >
          {editMode === title ? "Guardar" : "Editar"}
        </CustomButton>
      </Stack>
      <FormGroup
        sx={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row",
          width: "100%",
          gap: editMode === title && "10px",
        }}
      >
        {opciones.map(({ id, valorPrestamo, tipo }) => {
          if (editMode !== title) {
            return (
              <FormControlLabel
                key={id}
                sx={{ width: "32.3%", minWidth: '250px'}}
                control={
                  <CustomCheckbox
                    checked={elegidos.some(item => item.valorPrestamo === valorPrestamo && item.tipo === tipo)}
                    onChange={(e) =>
                      handleChangeOpcion(e.target.checked, {
                        valorPrestamo,
                        tipo,
                      })
                    }
                  />
                }
                label={CurrencyService.formatStatic(valorPrestamo)}
              />
            );
          } else {
            return (
              <Stack
                direction="row"
                alignItems="center"
                sx={{ width: "30%", gap: 1, justifyContent: "flex-start", minWidth: '250px' }}
                key={id}
              >
                <IconButton
                  sx={{ color: "#F2788E" }}
                  onClick={() => handleDelete(id)}
                >
                  <Delete />
                </IconButton>
                <Typography fontSize={16}>
                  {CurrencyService.formatStatic(valorPrestamo)}
                </Typography>
              </Stack>
            );
          }
        })}
        {editMode === title && (
          <Stack direction="row" sx={{ width: "30%", minWidth: '250px' }} gap={.5} alignItems="center">
            <CustomTextField
              inputProps={{
                style: {
                  padding: 6,
                },
              }}
              InputProps={{
                sx: { paddingRight: 0, paddingLeft: 1 },
                startAdornment: (
                  <InputAdornment position="start" sx={{ width: "10px" }}>
                    <AttachMoney />
                  </InputAdornment>
                ),
              }}
              value={
                valorPrestamo ? CurrencyService.formatInput(valorPrestamo) : ""
              }
              onChange={(e) => {
                const value = e.target.value.replace(/\D/g, "");
                setValorPrestamo(value);
              }}
            />
            <IconButton
              sx={{ color: "#57DF4F" }}
              onClick={() => handlePost({valorPrestamo, tipo: title.toUpperCase()})}
              disabled={!valorPrestamo || !Number(valorPrestamo)}
            >
              <AddCircle />
            </IconButton>
          </Stack>
        )}
      </FormGroup>
    </Stack>
  );
}
