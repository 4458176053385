import {
  Box,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Close from "@mui/icons-material/Close";
import CustomButton from "../Custom/Button";
import { WhatsApp } from "@mui/icons-material";
import CustomDialog from "../Custom/Dialog";
import { ICliente } from "src/utils/interfaces";
import _ from "lodash";
import { IWhatsappButton } from "./WhatsappButton";

interface IWhatsappModal {
  open: boolean;
  handleClose: () => void;
  entity: ICliente | IWhatsappButton;
  shareText?: string;
}

export interface IFormattedWpModal {
  type: string;
  number: string;
}

export default function WhatsappModal({
  open,
  handleClose,
  entity,
  shareText
}: IWhatsappModal) {

  const openWhatsappApi = (number: string) => {
    if(shareText){
      const encodedURI = encodeURIComponent(shareText)
      window.open(`https://api.whatsapp.com/send?phone=549${number}&text=${encodedURI}`, "_blank")
    } else {
      window.open(`https://api.whatsapp.com/send?phone=549${number}`, "_blank");
    }
  };

  const [whatsappModalTels, setWhatsappModalTels] = useState<
    IFormattedWpModal[]
  >([]);

  useEffect(() => {
    if (open && entity) {
      handleOpenWpModal(entity);
    }
  }, [open, entity]);

  const handleOpenWpModal = (row: ICliente | IWhatsappButton) => {
    const formattedTels: IFormattedWpModal[] = [];
    if (row.telefonoWp) {
      formattedTels.push({ type: "T. Principal", number: row.telefono });
    }
    if (row.telefonoAltWp) {
      formattedTels.push({ type: "T. Alternativo", number: row.telefonoAlt });
    }
    setWhatsappModalTels(formattedTels);
  };

  return (
    <CustomDialog
      open={open}
      onClose={() => handleClose()}
    >
      <DialogTitle>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap={5}
        >
          <Box fontSize={16} fontWeight={400}>
            Elige un whatsapp para comunicarte:
          </Box>
          <Box>
            <IconButton onClick={() => handleClose()}>
              <Close />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Stack gap={3}>
          {whatsappModalTels.map((tel: IFormattedWpModal) => (
            <CustomButton
              onClick={() => openWhatsappApi(tel.number)}
              key={_.indexOf(whatsappModalTels, tel)}
              startIcon={<WhatsApp />}
              variant="contained"
              fullWidth
              buttonColor="#8EC3EF"
            >
              {tel.type}: {tel.number}
            </CustomButton>
          ))}
        </Stack>
      </DialogContent>
    </CustomDialog>
  );
}
