import React, { useEffect } from "react";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import CustomTitle from "../../../shared/Custom/Title";
import { Stack, Typography } from "@mui/material";
import useFetch from "../../../../hooks/useFetch";

export default function Header() {
  const { data, fetchData, isLoading } = useFetch("get", "clientes-stats");

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Stack direction="row" alignItems="center">
      <PeopleAltOutlinedIcon
        sx={{
          fontSize: 32,
          color: "white",
          background: "#8EC3EF",
          borderRadius: "100%",
          height: 35,
          width: 35,
          padding: 0.5,
        }}
      />
      <Stack paddingLeft={2}>
        <CustomTitle>Lista de clientes</CustomTitle>
        <Stack direction='row' columnGap={2} flexWrap='wrap'>
          <Typography>
            Total:{" "}{data?.totalClientes}
          </Typography>
          <Typography>
            Con préstamos activos:{" "}{data?.totalClientesActivos}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
