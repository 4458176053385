import React from "react";
import MiniDrawer from "../../components/layout/Drawer";
import CustomPaper, { IBreadcrumb } from "../../components/shared/Custom/Paper";
import DocumentService from "src/services/DocumentService";
import Finanzas from "src/components/base/Finanzas";

const breadcrumb: IBreadcrumb[] = [
  {
    icon: "TrendingUp",
    text: "Finanzas",
    path: "/finanzas"
  },
];

export default function FinanzasPage() {

  DocumentService.setTitle('Finanzas')

  return (
    <MiniDrawer>
      <CustomPaper breadcrumb={breadcrumb}>
        <Finanzas/>
      </CustomPaper>
    </MiniDrawer>
  );
}
