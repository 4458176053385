import * as React from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { isMobile } from "react-device-detect";
import { AppBar, Stack, Toolbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import BackgroundLetterAvatars from "./Avatar";
import CustomButton from "../../shared/Custom/Button";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import TopBar from "./TopBar";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/context";
import AuthService from "src/services/AuthService";
import Deslogin from "src/components/base/Deslogin";
import { SettingsOutlined } from "@mui/icons-material";
import { Roles } from "src/utils/RolEnum";
import Configuracion from "./Configuracion";

const drawerWidth = 270;

const CustomList = styled(List)(() => ({
  color: "white",
  backgroundColor: "#1B23BE",
}));

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(14)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(17)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#1B23BE",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const DrawerEnd = styled(Stack)(() => ({
  width: "100%",
  padding: `0px 35px 0px 35px`,
  color: "white",
  display: "flex",
  gap: "30px",
  marginTop: "15px",
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  zIndex: 99,
  flexShrink: 0,
  whiteSpace: "nowrap",
  border: "none",
  position: isMobile ? "absolute" : "static",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const options = [
  {
    label: "Inicio",
    key: "inicio",
    icon: <HomeOutlinedIcon />,
    path: "inicio",
  },
  {
    label: "Clientes",
    key: "clientes",
    icon: <PeopleAltOutlinedIcon />,
    path: "clientes",
  },
  {
    label: "Préstamos",
    key: "prestamos",
    icon: <MonetizationOnOutlinedIcon />,
    path: "prestamos",
  },
  {
    label: "Finanzas",
    key: "finanzas",
    icon: <TrendingUpIcon />,
    path: "finanzas",
  },
];

const BarraClicked = () => {
  return (
    <div
      style={{
        position: "absolute",
        left: "10px",
        width: "7px",
        height: "26px",
        backgroundColor: "#93EF8E",
        borderRadius: "7px",
      }}
    ></div>
  );
};

export default function MiniDrawer({ ...props }) {
  const {
    appContext,
    appContext: {
      drawerContext: { open },
    },
    setAppContext,
  } = React.useContext(AppContext);

  const [clicked, setClicked] = React.useState<string>("");

  const handleDrawerOpen = () => {
    handleDrawer(true);
  };

  const handleDrawerClose = () => {
    handleDrawer(false);
  };

  const handleDrawer = (open: boolean) => {
    setAppContext({
      ...appContext,
      drawerContext: { ...appContext.drawerContext, open },
    });
  };

  function showMobileNavbar() {
    if (!open && isMobile) {
      return true;
    } else {
      return false;
    }
  }

  function showDrawer() {
    if (open && isMobile) {
      return true;
    } else if (open && !isMobile) {
      return true;
    } else if (!isMobile && !open) {
      return true;
    } else {
      return false;
    }
  }

  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    setClicked(location.pathname.split("/")[1]);
  }, [location.pathname]);

  const [openDeslogin, setOpenDeslogin] = React.useState<boolean>(false);

  return (
    <>
      <Deslogin open={openDeslogin} setOpen={setOpenDeslogin} />
      {showDrawer() && (
        <Box
          sx={{
            display: "flex",
            backgroundColor: "#E3F0FF",
            height: "100%",
            minHeight: "100vh",
          }}
        >
          <CssBaseline />
          <Drawer
            PaperProps={{
              elevation: 8,
              sx: {
                backgroundColor: "#1B23BE",
                borderTopRightRadius: open ? "32px" : "56px",
                borderBottomRightRadius: open ? "32px" : "56px",
                border: "none",
                display: "flex",
                justifyContent: "space-between",
                height: "100vh",
                paddingY: isMobile ? "5%" : "2%",
                direction: "rtl",
                "&::-webkit-scrollbar": {
                  width: "5px",
                },
                "&::-webkit-scrollbar-track": {
                  boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                  webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "rgba(0,0,0,.3)",
                },
              },
            }}
            variant="permanent"
            open={open}
          >
            <div>
              <DrawerHeader sx={{ padding: 5 }}>
                <img
                  src={
                    open ? "/logonavbarexpand.png" : "/logonavbarcollapse.png"
                  }
                  alt="logo move"
                  style={{ height: "80px", padding: !open && "15px" }}
                  onClick={() => navigate('/inicio')}
                />
              </DrawerHeader>
              <CustomList sx={{ justifySelf: "flex-start" }}>
                {options.map(({ icon, label, key, path }) => (
                  <ListItem
                    key={key}
                    disablePadding
                    sx={{ display: "block", direction: "ltr" }}
                  >
                    <ListItemButton
                      onClick={() => {
                        path && navigate(`/${path}`);
                        if (isMobile) {
                          handleDrawerClose();
                        }
                      }}
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 5,
                        backgroundColor:
                          clicked === key ? "#FFFFFF59" : "#1B23BE",
                        "&:hover": {
                          backgroundColor:
                            clicked === key ? "#FFFFFF59" : "#1B23BE",
                        },
                      }}
                    >
                      {clicked === key && <BarraClicked />}
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                          color: "white",
                          paddingLeft: "15px",
                        }}
                      >
                        {icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={label}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </CustomList>
            </div>
            <DrawerEnd style={{ direction: "ltr" }}>
              <BackgroundLetterAvatars
                username={AuthService.getAuth().fullName ?? "No User"}
                opened={open}
              />
              {AuthService.getAuth().rolId === Roles.ADMINISTRADOR && (
                <Configuracion open={open} />
              )}
              <CustomButton
                variant="contained"
                sx={{
                  "&.MuiButtonBase-root:hover": {
                    bgcolor: "transparent",
                  },
                  backgroundColor: "transparent",
                  color: "#F2788E",
                  border: "2px solid #F2788E",
                }}
                startIcon={open && <LogoutIcon />}
                onClick={() => setOpenDeslogin(true)}
              >
                {open ? "Cerrar sesión" : <LogoutIcon />}
              </CustomButton>
            </DrawerEnd>
          </Drawer>
          <Box component="main" sx={{ minWidth: "1px", flexGrow: 2, p: 3 }}>
            <IconButton
              sx={{
                position: "fixed",
                color: "white",
                backgroundColor: "#93EF8E",
                "&:hover": { backgroundColor: "#93EF8E" },
                marginLeft: isMobile ? 28 : -5,
                marginTop: isMobile ? 9 : 9,
                zIndex: "999",
                height: isMobile ? "36px" : "30px",
                width: isMobile ? "36px" : "30px",
              }}
              onClick={open ? handleDrawerClose : handleDrawerOpen}
            >
              {isMobile && <CloseIcon />}
              {!isMobile && (open ? <ChevronLeftIcon /> : <ChevronRightIcon />)}
            </IconButton>
            {isMobile &&
              (open ? (
                ""
              ) : (
                <>
                  <TopBar />
                  {props.children}
                </>
              ))}
            {!isMobile && (
              <>
                <TopBar />
                {props.children}
              </>
            )}
          </Box>
        </Box>
      )}
      {showMobileNavbar() && (
        <Box sx={{ flexGrow: 1, backgroundColor: "#E3F0FF", height: "100%" }}>
          <AppBar
            position="sticky"
            sx={{
              backgroundColor: "info.main",
              height: "72px",
              justifyContent: "center",
            }}
          >
            <Toolbar sx={{ justifyContent: "space-between" }}>
              <IconButton
                sx={{
                  color: "white",
                  backgroundColor: "#93EF8E",
                  "&:hover": { backgroundColor: "#93EF8E" },
                  zIndex: "999",
                  height: "36px",
                  width: "36px",
                }}
                onClick={open ? handleDrawerClose : handleDrawerOpen}
              >
                <MenuIcon />
              </IconButton>
              <img
                src="/logonavbarcollapse.png"
                alt="logo move"
                style={{ height: "42px" }}
                onClick={() => navigate('/inicio')}
              />
              <IconButton
                sx={{
                  color: "white",
                  backgroundColor: "#93EF8E",
                  "&:hover": { backgroundColor: "#93EF8E" },
                  zIndex: "999",
                  height: "36px",
                  width: "36px",
                  visibility: "hidden",
                }}
              ></IconButton>
            </Toolbar>
          </AppBar>
          <Box component="main" sx={{ flexGrow: 1, p: 2, height: "100%" }}>
            <TopBar />
            {props.children}
          </Box>
        </Box>
      )}
    </>
  );
}
