import { SettingsOutlined } from "@mui/icons-material";
import React, { useState } from "react";
import ConfiguracionModal from "src/components/base/Configuracion";
import CustomButton from "src/components/shared/Custom/Button";

export default function Configuracion({ open }) {
  const [openModal, setOpenModal] = useState<boolean>(false);

  return (
    <>
      <ConfiguracionModal open={openModal} setOpen={setOpenModal} />
      <CustomButton
        variant="contained"
        sx={{
          "&.MuiButtonBase-root:hover": {
            bgcolor: "white",
          },
          backgroundColor: "white",
          color: "#1B23BE",
        }}
        startIcon={open && <SettingsOutlined />}
        onClick={() => setOpenModal(true)}
      >
        {open ? "Configuración" : <SettingsOutlined />}
      </CustomButton>
    </>
  );
}
