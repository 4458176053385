import React, { useState } from "react";
import CustomPaper, { IBreadcrumb } from "src/components/shared/Custom/Paper";
import Infobar from "./Infobar";
import TableWrapper from "./TableWrapper";
import Proximos from "./Dialogs/Proximos";

const breadcrumb: IBreadcrumb[] = [
  {
    icon: "HomeOutlined",
    text: "Inicio",
    path: "/inicio",
  },
];

export default function Main({refresher, setRefresher}) {
  const [editing, setEditing] = useState<boolean>(false);

  const [openProximos, setOpenProximos] = useState<boolean>(false);
  const [totales, setTotales] = useState<{total: null, totalPagados: null}>({total: null, totalPagados: null})

  return (
    <>
      <Proximos open={openProximos} setOpen={setOpenProximos} refresher={refresher} setRefresher={setRefresher} />
      <CustomPaper
        breadcrumb={breadcrumb}
        sx={{ flexGrow: "1", maxWidth: '100%' }}
      >
        <Infobar editing={editing} setEditing={setEditing} setOpenProximos={setOpenProximos} totales={totales} />
        <TableWrapper refresher={refresher} setRefresher={setRefresher} editing={editing} openProximos={openProximos} setTotales={setTotales} totales={totales} />
      </CustomPaper>
    </>
  );
}
