import { Button } from "@mui/material";
import React, { useState } from "react";

interface ICustomButton {
  [x: string]: any;
  buttonColor?: string;
  variant?: 'text' | 'contained' | 'outlined'
  startIcon?: any
  endIcon?: any
}

export default function CustomButton({ buttonColor, ...props }: ICustomButton) {
  const [shadow, setShadow] = useState<number>(0);
  const sxButtonColor = {
    ...props.sx,
    backgroundColor: buttonColor,
    "&:hover": { boxShadow: shadow, backgroundColor: buttonColor },
    "&:disabled": { color: "white", backgroundColor: "#B1B1B1" },
  };

  return (
    <Button
      disableElevation
      {...props}
      onMouseOver={() => setShadow(3)}
      onMouseLeave={() => setShadow(0)}
      sx={
        buttonColor
          ? sxButtonColor
          : {
            "&:hover": { boxShadow: shadow },
            "&:disabled": { color: "white", backgroundColor: "#B1B1B1" },
              ...props.sx,
            }
      }
    />
  );
}
