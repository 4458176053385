import './App.css';
import React, { useMemo, useState} from 'react'
import {
  RouterProvider,
} from "react-router-dom";
import router from './router';
import { AppContext } from './context/context';
import SnackbarProvider from 'react-simple-snackbar'

function App() {

  const [appContext, setAppContext] = useState(
    {
      drawerContext: {
        open: false,
      },
    }
  )

  const appContextProviderValue = useMemo(() => ({appContext, setAppContext}), [appContext, setAppContext]);

  return (
/*     <div className="App">
      <header className="App-header">
        <input type='text' value={input} onChange={(e) => setInput(CurrencyService.formatInput(e.target.value))}></input>
        <button onClick={()=>getData()}>jaja</button>
        <h1>{CurrencyService.formatStatic(1950)}</h1>
        <h1>{CurrencyService.unformatStatic('$ 1950')}</h1>
      </header>
      <TablaEstandar rows={data} columns={columns} isLoading={isLoading}/>
      <TablaOrdenable rows={data} columns={columns} setterData={setData} />
    </div> */
    <SnackbarProvider>
    <AppContext.Provider value={appContextProviderValue}>
      <RouterProvider router={router} />
    </AppContext.Provider>
    </SnackbarProvider>
  );
}

export default App;
