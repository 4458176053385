import { Accordion, AccordionProps } from "@mui/material";
import React from "react";

interface IAccordion extends AccordionProps {
    children: React.ReactNode
    color?: string
}

export default function CustomAccordion({children, color, ...props}: IAccordion) {
  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true, mountOnEnter: true}}
      {...props}
      sx={{
        backgroundColor: color ?? "#EFB98E26",
        "&:before": {
          display: "none",
        },
        ...props.sx
      }}
      elevation={0}
    >
        {children}
    </Accordion>
  );
}
