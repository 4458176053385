import React, { useEffect } from "react";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import CustomTitle from "../../../shared/Custom/Title";
import { Divider, Stack, Typography } from "@mui/material";
import useFetch from "../../../../hooks/useFetch";
import { MoneyOutlined, PaidOutlined } from "@mui/icons-material";

export default function Infobar() {
  const { data, fetchData, isLoading } = useFetch("get", "prestamos-stats");

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      alignItems="center"
      justifyContent="flex-start"
      p={2}
      paddingTop={0}
      sx={{flexWrap: 'nowrap'}}
    >
      <PaidOutlined
        sx={{
          fontSize: 32,
          color: "white",
          background: "#8EC3EF",
          borderRadius: "100%",
          height: 35,
          width: 35,
          padding: 0.5,
        }}
      />
      <Stack paddingLeft={2}>
        <CustomTitle>Lista de préstamos</CustomTitle>
        <Stack direction="row" columnGap={1.5} flexWrap="wrap">
          <Stack direction="row" gap={0.5} sx={{minWidth: '100px'}}>
            <Typography sx={{ color: "#09AD00" }}>Activos:</Typography>
            <Typography>{data?.totalPrestamosActivos}</Typography>
          </Stack>

          <Stack direction="row" gap={0.5} sx={{minWidth: '100px'}}>
            <Typography sx={{ color: "#F2788E" }}>Finalizados:</Typography>
            <Typography>{data?.totalPrestamosFinalizados}</Typography>
          </Stack>

          <Stack direction="row" gap={0.5} sx={{minWidth: '100px'}}>
            <Typography sx={{ color: "#767676" }}>Pendientes:</Typography>
            <Typography>{data?.totalPrestamosPendientes}</Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
