import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import React, { useState } from "react";
import ApiService from "../../../services/ApiService";
import AuthService from "../../../services/AuthService";
import CustomTextField from "../../shared/Custom/TextField";
import "./styles.css";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CustomButton from "../../shared/Custom/Button";
import useForm from "../../../hooks/useForm";
import { useNavigate } from "react-router-dom";

export default function Login() {

  const navigate = useNavigate();

  async function log() {
    const { success, fullName, rolId } = (
      await ApiService.login({
        username: getForm()?.usuario,
        password: getForm()?.contraseña,
      })
    ).data;
    if (!success) {
      setWrongCredentials(true)
    } else {
      AuthService.setAuth(getForm()?.usuario, getForm()?.contraseña, fullName, rolId);
      setWrongCredentials(false)
      navigate('/inicio')
    }
  }

  const [wrongCredentials, setWrongCredentials] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const { sendRequest, saveButtonState, handleFormChange, getForm } = useForm(
    {
      usuario: {
        required: true,
      },
      contraseña: {
        required: true,
      },
    },
    log
  );

  function handleLoginFormChange(e){
    if(wrongCredentials){
      setWrongCredentials(false)
      handleFormChange(e.target)
    } else {
      handleFormChange(e.target)
    }
  }

  return (
    <div
      id="container"
      style={{
        backgroundImage: "url('loginwallpaper.png')",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper elevation={2} sx={{ padding: 3, borderRadius: 3}}>
        <Stack spacing={4} sx={{ paddingY: "30px" }}>
          <img src="logomove.png" alt="logo move" />
          <CustomTextField
            value={getForm()?.usuario}
            name="usuario"
            label="Usuario"
            onChange={(e) => handleLoginFormChange(e)}
          />
          <CustomTextField
            error={wrongCredentials}
            helperText={wrongCredentials ? 'Credenciales incorrectas.' : ' '}
            label="Contraseña"
            value={getForm()?.contraseña}
            name="contraseña"
            type={showPassword ? "text" : "password"}
            onChange={(e) => handleLoginFormChange(e)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <CustomButton
            variant="contained"
            color="secondary"
            style={{ marginTop: "50px" }}
            disabled={saveButtonState()}
            onClick={() => sendRequest()}
          >
            INGRESAR
          </CustomButton>
        </Stack>
      </Paper>
    </div>
  );
}
