import React, {useState} from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import _ from "lodash";


export default function CustomTextField({...props}: TextFieldProps) {

    const [active, setActive] = useState(false)

    function getBorderColor(){
      if(props.error){
        return '#d32f2f'
      }

      if(active){
        return 'primary.main'
      }

      if(props.value){
        return 'secondary.main'
      }

      return "#0000003b"
    }

    function getCaretColor(){
      if(props.error){
        return '#d32f2f'
      }

      return '#0094FF'
    }

  return (
    <TextField
      onFocus={()=>setActive(true)}
      onBlur={()=>setActive(false)}
      variant="outlined"
      sx={{
        label: { color: active ? 'primary.main' : props.value ? "secondary.main" : "black"},
        fieldset: {
          borderColor: props.value && "secondary.main",
          borderWidth: props.value && 2,
        },
        input: {
            caretColor: getCaretColor(),
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: getBorderColor()
          },
          ...props.sx
      }}
      {..._.omit(props, ['sx'])}
    />
  );
}
