import { InsertCommentOutlined } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import React from "react";
import CustomTextField from "src/components/shared/Custom/TextField";

export default function Observacion({ form, setForm }) {
  return (
    <Stack gap={2}>
      <Stack direction="row" gap={1}>
        <InsertCommentOutlined sx={{ color: "#1B23BE" }} />
        <Typography>Observación</Typography>
      </Stack>
      <CustomTextField
        name="observacion"
        value={form?.comentario}
        onChange={(e) => setForm({ ...form, comentario: e.target.value })}
        placeholder="Escribe un comentario"
        sx={{ minWidth: "240px", width: "100%" }}
      />
    </Stack>
  );
}
