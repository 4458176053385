import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import BorrarModal from "src/components/shared/Modals/BorrarModal";
import TablaEstandar, { IAction, IColumn } from "src/components/shared/Tabla";
import useTable from "src/hooks/useTable";
import CurrencyService from "src/services/CurrencyService";
import { IPago, IPrestamo } from "src/utils/interfaces";
import EntityForm from "./EntityForm";
import AuthService from "src/services/AuthService";
import { Roles } from "src/utils/RolEnum";

export default function TableWrapper({ prestamo }: { prestamo: IPrestamo }) {
  const { isLoading, getData, data } = useTable(
    `pagos?prestamoId=${prestamo?.id}&order=fecha&order=desc`
  );

  const columns: IColumn<IPago>[] = [
    {
      property: "fecha",
      label: "Fecha del pago",
      isDate: true,
    },
    {
      property: "importe",
      label: "Valor del pago",
      format(row) {
        return row.importe
          ? `${CurrencyService.formatStatic(row.importe)}`
          : "--";
      },
    },
    {
      property: "metodoPago.denominacion",
      label: "Medio de pago",
    },
    {
      property: "observacion",
      label: "Observaciones",
      format(row) {
        return <i>{row.observacion ? row.observacion : "--"}</i>;
      },
    },
    {
      property: "esInteres",
      label: "Es pago de interés",
      format: (row) => (row.esInteres ? "Sí" : "No"),
    },
    {
      property: "creadoPor",
      label: "Creado por",
    },
  ];

  const [entity, setEntity] = useState<IPago>(null);
  const [openBorrar, setOpenBorrar] = useState<boolean>(false);

  const actions: IAction[] = [
    {
      label: "Editar pago",
      onClick: (row) => {
        setSearchParams({
          type: "update",
          entity: "pago",
          pagoId: `${row?.id}`,
          prestamoId: `${row?.prestamoId}`
        });
      },
    },
    {
      label: "Borrar pago",
      color: "#F2788E",
      onClick: (row) => {
        setEntity(row);
        setOpenBorrar(true);
        setSearchParams({ type: "delete", entity: "pago" });
      },
      disabled(row) {
        return AuthService.getAuth().rolId !== Roles.ADMINISTRADOR
      },
    },
  ];

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (prestamo) {
      getData();
    }
  }, [prestamo]);

  return (
    <>
      <BorrarModal
        handleClose={() => {
          setOpenBorrar(false);
          setEntity(null);
          setSearchParams({});
        }}
        onDelete={() => {
          getData();
          setSearchParams({});
        }}
        type="pago"
        open={openBorrar}
        entity={entity}
        endpoint={"pagos"}
      />
      <EntityForm />
      <TablaEstandar
        defaultRowsPerPage={50}
        isLoading={isLoading}
        rows={data}
        columns={columns}
        actions={actions}
      />
    </>
  );
}
