import { StepLabel, Stepper, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react";
import CustomStep from "src/components/shared/Custom/Step";
import Estado from "./Fields/estado";
import FechaEntrega from "./Fields/fechaEntrega";
import Tipo from "./Fields/tipo";
import Monto from "./Fields/monto";
import Interes from "./Fields/interes";
import Resumen from "./Fields/resumen";
import Observacion from "./Fields/observacion";
import { ESTADOS } from "src/utils/PrestamoEnums";

export default function CreateForm({getForm, handleFormChange, setFormField}) {

  const [interesTotal, setInteresTotal] = useState<string>('')

  return (
    <Stack gap={2} maxWidth={"100%"}>
      <Estado handleFormChange={handleFormChange} getForm={getForm} />
      {getForm()?.estadoId === ESTADOS.PENDIENTE && <FechaEntrega handleFormChange={handleFormChange} getForm={getForm} />}
      <Stepper orientation="vertical">
        <CustomStep>
          <Tipo handleFormChange={handleFormChange} getForm={getForm} setFormField={setFormField} />
        </CustomStep>
        <CustomStep>
          <Monto handleFormChange={handleFormChange} getForm={getForm} setFormField={setFormField} />
        </CustomStep>
        <CustomStep>
          <Interes handleFormChange={handleFormChange} getForm={getForm} setFormField={setFormField} setterInteresTotal={setInteresTotal} interesTotal={interesTotal} />
        </CustomStep>
        <CustomStep completed>
          <StepLabel>
            <Typography fontSize={18} fontWeight={400} color={"black"}>
              Detalle del préstamo
            </Typography>
          </StepLabel>
        </CustomStep>
      </Stepper>
      <Resumen getForm={getForm} interesTotal={interesTotal} />
      <Observacion handleFormChange={handleFormChange} getForm={getForm} />
    </Stack>
  );
}
