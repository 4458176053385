import { ExpandMore } from "@mui/icons-material";
import { AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomAccordion from "src/components/shared/Custom/Accordion";
import { ICliente } from "src/utils/interfaces";
import TableWrapper from "./TableWrapper";
import { useSearchParams } from "react-router-dom";
import { ESTADOS } from "src/utils/PrestamoEnums";

export default function Finalizados({ client }: { client: ICliente }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    if (searchParams.has('finalizado')) {
      setExpanded(true);
    }
  }, [searchParams.get("finalizado")]);

  return (
    <CustomAccordion color="#0000000A" expanded={expanded}>
      <AccordionSummary
        onClick={() => setExpanded(!expanded)}
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ flexDirection: "row-reverse", gap: 2 }}
      >
        <Typography fontWeight={500}>Préstamos Finalizados</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TableWrapper client={client} />
      </AccordionDetails>
    </CustomAccordion>
  );
}
