import { AttachMoney } from "@mui/icons-material";
import {
  InputAdornment,
  Stack,
  StepContent,
  StepLabel,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomCheckbox from "src/components/shared/Custom/Checkbox";
import CustomTextField from "src/components/shared/Custom/TextField";
import { IFormComponent } from "src/hooks/useForm";
import CurrencyService from "src/services/CurrencyService";
import { IPrestamo } from "src/utils/interfaces";

interface IMonto extends IFormComponent {
  data: IPrestamo;
  type: "create" | "update";
}

export default function Monto({
  getForm,
  handleFormChange,
  setFormField,
  data,
  type,
}: IMonto) {
  const [checkbox, setCheckbox] = useState<boolean>(false);

  const handleCheckbox = (e) => {
    const checked = e.target.checked;
    setCheckbox(checked);
    if (checked) {
      setFormField("importe", String(data?.valorRestanteCuotaActual));
    }
  };

  return (
    <>
      <StepLabel>
        <Typography fontSize={18} fontWeight={400} color={"black"}>
          Ingresar el monto abonado
        </Typography>
      </StepLabel>
      <StepContent>
        <CustomTextField
          disabled={checkbox}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AttachMoney />
              </InputAdornment>
            ),
          }}
          name="importe"
          value={
            getForm()?.importe
              ? CurrencyService.formatInput(getForm()?.importe)
              : ""
          }
          onChange={(e) =>
            handleFormChange(e.target, (value) => {
              value = value.replace(/\D/g, "");
              return value;
            })
          }
          placeholder="Monto abonado"
          sx={{
            minWidth: "240px",
            width: "100%",
            "& .MuiInputBase-root.Mui-disabled": {
              fontWeight: checkbox && "600",
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#bdbdbd",
              },
            },
          }}
        />
        {type === "create" && (
          <>
            <CustomCheckbox
              size="small"
              value={checkbox}
              onChange={(e) => handleCheckbox(e)}
            />
            <span style={{ color: "#00000099" }}>
              Completa el restante de cuota
            </span>
          </>
        )}
      </StepContent>
    </>
  );
}
