export const SUBTIPOS = ["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","26","27","28", 'LUNES', 'MARTES', 'MIERCOLES', 'JUEVES', 'VIERNES', 'SABADO', 'DOMINGO']
export const TIPOS = ['SEMANAL', 'MENSUAL'];
export enum ESTADOS {
    ACTIVO = 1,
    FINALIZADO,
    PENDIENTE
}
export enum TIPOSEnum {
    SEMANAL = 'SEMANAL',
    MENSUAL = 'MENSUAL'
}
export const SUBTIPOSSemanal = ['LUNES', 'MARTES', 'MIERCOLES', 'JUEVES', 'VIERNES', 'SABADO', 'DOMINGO']

export const SUBTIPOSMensual = ["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","26","27","28"]