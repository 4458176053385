import { Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import CustomButton from "src/components/shared/Custom/Button";
import useFetch from "src/hooks/useFetch";

export default function Header({ editing, setEditing, total }) {

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography>Total: {total ?? ''}</Typography>
      <CustomButton
        size="small"
        variant="contained"
        buttonColor={!editing && "#EFB98E"}
        onClick={() => {
          setEditing(!editing);
        }}
        sx={editing && {
          color: "black",
          border: '3px solid',
          borderColor: "#EFB98E",
          backgroundColor: 'white',
          "&:hover": { backgroundColor: "white",  border: '3px solid', borderColor: "#EFB98E"},
        }}
      >
        {editing ? 'Finalizar edición' : 'Editar lista del día'}
      </CustomButton>
    </Stack>
  );
}
