import { DialogContent, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomDialog from "src/components/shared/Custom/Dialog";
import CustomDialogTitle from "src/components/shared/Custom/DialogTitle";
import Header from "./Header";
import { format } from "date-fns";
import es from "date-fns/locale/es";
import _ from "lodash";
import Picker from "./Picker";
import TableWrapper from "./TableWrapper";

  const getDaysList = (todayDate) => {
    const dates = [];
    for (let i = 1; i < 8; i++) {
      dates.push(new Date(new Date().setDate(todayDate.getDate() + i)));
    }
    return dates.map((date) => ({
      jsDate: date,
      sqlDate: format(date, "yyyy-MM-dd"),
    }));
  };

export default function Proximos({ open, setOpen, refresher, setRefresher }) {

  const [editing, setEditing] = useState<boolean>(false)
  const [availableDays] = useState(getDaysList(new Date()));
  const [pickedDate, setPickedDate] = useState<{jsDate: any, sqlDate: string}>(availableDays[0]);

    const handleClose = () => {
        setEditing(false)
        setPickedDate(availableDays[0])
        setOpen(false)
        setRefresher(!refresher)
    }

    const [total, setTotal] = useState(null)

  return (
    <CustomDialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={'md'}
    >
      <CustomDialogTitle
        handleClose={handleClose}
        title="Próximos préstamos"
        iconColor="#8EC3EF"
        icon="DirectionsCarFilledOutlined"
      />
      <DialogContent>
        <Stack gap={4}>
          <Header setEditing={setEditing} editing={editing} total={total} />
          <Picker setPickedDate={setPickedDate} pickedDate={pickedDate} availableDays={availableDays} editing={editing} />
          <TableWrapper editing={editing} fecha={pickedDate?.sqlDate} setTotal={setTotal} />
        </Stack>
      </DialogContent>
    </CustomDialog>
  );
}
