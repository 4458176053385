import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Skeleton,
  StepContent,
  StepLabel,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import useFetch from "src/hooks/useFetch";
import { IFormComponent } from "src/hooks/useForm";
import { IMetodoPago } from "src/utils/interfaces";

export default function Metodo({ handleFormChange, getForm, setFormField }: IFormComponent) {
  const { data, fetchData } = useFetch("get", `metodos-pago`);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <StepLabel>
        <Typography fontSize={18} fontWeight={400} color={"black"}>
          Seleccionar método de pago
        </Typography>
      </StepLabel>
      <StepContent>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          sx={{ flexWrap: "wrap" }}
          onChange={(e) => setFormField('metodoPagoId', e.target.value)}
          value={getForm()?.metodoPagoId}
        >
          {!data && <Skeleton variant='rectangular' width='100%' height='70px'/>}
          {data &&
            data.length &&
            data.map((metodoPago: IMetodoPago) => (
              <FormControlLabel
                key={metodoPago.id}
                value={metodoPago.id}
                control={
                  <Radio
                    sx={{
                      "&, &.Mui-unchecked": {
                        color: "gray",
                      },
                      "&.Mui-checked": {
                        color: "#1B23BE",
                      },
                    }}
                  />
                }
                label={metodoPago.denominacion}
              />
            ))}
        </RadioGroup>
      </StepContent>
    </>
  );
}
