import { RoomOutlined, WhatsApp } from "@mui/icons-material";
import React, { useState } from "react";
import { ICliente } from "src/utils/interfaces";
import CustomButton from "../Custom/Button";
import GpsModal from "./GpsModal";

export interface IGpsButton {
  direccionTrabajo: string;
  direccionPersonal: string;
  gpsTrabajo: boolean;
  gpsPersonal: boolean;
}

export default function GpsButton({ data }: { data: IGpsButton | ICliente }) {
  const verifyMinimum = () => {
    return data?.gpsTrabajo || data?.gpsPersonal;
  };

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [gpsEntity, setGpsEntity] = useState<IGpsButton | ICliente>(null);
  const handleOpenWpModal = () => {
    setGpsEntity(() => data);
    setOpenModal(true);
  };

  return (
    <>
      <CustomButton
        sx={{ minWidth: "135px" }}
        startIcon={<RoomOutlined />}
        variant="contained"
        buttonColor="#EA8EEF"
        disabled={!verifyMinimum()}
        onClick={() => handleOpenWpModal()}
      >
        Gps
      </CustomButton>
      <GpsModal
        entity={gpsEntity}
        open={openModal}
        handleClose={() => setOpenModal(false)}
      />
    </>
  );
}