import { Add, PaymentsOutlined, PercentOutlined } from "@mui/icons-material";
import {
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  InputLabel,
  MenuItem,
  Stack,
  StepContent,
  StepLabel,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomButton from "src/components/shared/Custom/Button";
import CustomCheckbox from "src/components/shared/Custom/Checkbox";
import CustomSelect from "src/components/shared/Custom/Select";
import CustomTextField from "src/components/shared/Custom/TextField";
import useFetch from "src/hooks/useFetch";
import { ICotizadorFields, ICotizadorOpcion } from "..";
import _ from "lodash";

export default function Opciones({
  fields,
  setFields,
}: {
  fields: ICotizadorFields;
  setFields: React.Dispatch<React.SetStateAction<ICotizadorFields>>;
}) {
  const [otrosCheckbox, setOtrosCheckbox] = useState<boolean>(false);
  const [semanalesCheckbox, setSemanalesCheckbox] = useState<boolean>(false);
  const [mensualesCheckbox, setMensualesCheckbox] = useState<boolean>(false);

  const { isLoading, fetchData, data } = useFetch("get", "opciones-intereses");

  useEffect(() => {
    fetchData();
  }, []);

  const [otrosFields, setOtrosFields] = useState<{
    tipo: "SEMANAL" | "MENSUAL";
    interesTotal: string | null;
    cantidadCuotas: string | null;
  }>({ tipo: null, interesTotal: null, cantidadCuotas: null });

  const [otrosOpciones, setOtrosOpciones] = useState<ICotizadorOpcion[]>([]);

  const handleCreateOpcion = () => {
    const opcionCreada = {
      interesTotal: Number(otrosFields.interesTotal),
      cantidadCuotas: Number(otrosFields.cantidadCuotas),
      tipo: otrosFields.tipo,
      esAgregado: true,
    };
    setOtrosOpciones([...otrosOpciones, opcionCreada]);
    setFields({ ...fields, opciones: [...fields.opciones, opcionCreada] });
  };

  const handleOpcionesChange = (
    tipo: "SEMANAL" | "MENSUAL" | boolean,
    incluir: boolean,
    esOtros: boolean = false
  ) => {
    if (incluir) {
      let opciones = [];
      if (!esOtros) {
        opciones = data
          .filter(
            (opcionInteres) =>
              opcionInteres.tipo === tipo && opcionInteres.cantidadCuotas
          )
          .map(
            (opcionInteres): ICotizadorOpcion => ({
              tipo: opcionInteres.tipo,
              cantidadCuotas: Number(opcionInteres.cantidadCuotas),
              interesTotal: Math.round(
                Number(opcionInteres.cantidadCuotas) *
                  parseFloat(opcionInteres.porcentaje)
              ),
            })
          );
      } else {
        opciones = otrosOpciones;
      }
      setFields({ ...fields, opciones: [...fields.opciones, ...opciones] });
    } else {
      let opcionesFiltradas = [];
      if (!esOtros) {
        opcionesFiltradas = fields.opciones.filter(
          (opcion) =>
            (opcion.tipo !== tipo && !opcion.esAgregado) || opcion.esAgregado
        );
      } else {
        opcionesFiltradas = fields.opciones.filter(
          (opcion) => !opcion.esAgregado
        );
      }
      setFields({ ...fields, opciones: opcionesFiltradas });
    }
  };

  const handleDeleteOption = (opcion) => {
    setOtrosOpciones(_.pull(otrosOpciones, opcion));
    setFields({ ...fields, opciones: _.pull(fields.opciones, opcion) });
  };

  return (
    <>
      <StepLabel>
        <Typography fontSize={18} fontWeight={400} color={"black"}>
          Seleccionar opciones
        </Typography>
      </StepLabel>
      <StepContent>
        <Stack gap={1} alignItems="flex-start">
          <FormGroup>
            <FormControlLabel
              disabled={isLoading}
              control={
                <CustomCheckbox
                  onChange={(e) => {
                    setSemanalesCheckbox(!semanalesCheckbox);
                    handleOpcionesChange("SEMANAL", e.target.checked);
                  }}
                />
              }
              label="Incluir semanales"
            />
            <FormControlLabel
              disabled={isLoading}
              control={
                <CustomCheckbox
                  onChange={(e) => {
                    setMensualesCheckbox(!mensualesCheckbox);
                    handleOpcionesChange("MENSUAL", e.target.checked);
                  }}
                />
              }
              label="Incluir mensuales"
            />
            <Stack flexWrap="wrap">
              <FormControlLabel
                disabled={isLoading}
                control={
                  <CustomCheckbox
                    onChange={(e) => {
                      setOtrosCheckbox(!otrosCheckbox);
                      handleOpcionesChange(false, e.target.checked, true);
                    }}
                  />
                }
                label="Otros..."
                value={otrosCheckbox}
              />
              <Stack
                direction="row"
                gap={0.5}
                alignItems="center"
                flexWrap="wrap"
                paddingBottom={1}
              >
                {otrosOpciones.map((otro) => (
                  <Chip
                    size="small"
                    label={`${otro.cantidadCuotas} C - ${otro.tipo.substring(
                      0,
                      3
                    )}`}
                    onDelete={() => {
                      handleDeleteOption(otro);
                    }}
                  />
                ))}
              </Stack>
            </Stack>
            {otrosCheckbox && (
              <Stack gap={1} paddingTop={1} maxWidth={"240px"}>
                <FormControl>
                  <InputLabel>Tipo</InputLabel>
                  <CustomSelect
                    label="Tipo"
                    value={otrosFields["tipo"]}
                    variant="outlined"
                    onChange={(e) =>
                      setOtrosFields({
                        ...otrosFields,
                        tipo: e.target.value as "SEMANAL" | "MENSUAL",
                      })
                    }
                  >
                    <MenuItem value="SEMANAL">SEMANAL</MenuItem>
                    <MenuItem value="MENSUAL">MENSUAL</MenuItem>
                  </CustomSelect>
                </FormControl>
                <CustomTextField
                  value={otrosFields.interesTotal}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, "");
                    setOtrosFields({ ...otrosFields, interesTotal: value });
                  }}
                  placeholder="Valor del interés total"
                  sx={{
                    minWidth: "240px",
                    width: "100%",
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PercentOutlined />
                      </InputAdornment>
                    ),
                  }}
                />
                <CustomTextField
                  name="cantidadCuotas"
                  placeholder="Cantidad de cuotas"
                  sx={{
                    minWidth: "240px",
                    width: "100%",
                  }}
                  value={otrosFields.cantidadCuotas}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, "");
                    setOtrosFields({ ...otrosFields, cantidadCuotas: value });
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PaymentsOutlined />
                      </InputAdornment>
                    ),
                  }}
                />
                <CustomButton
                  variant="outlined"
                  sx={{
                    color: "#1B75BE",
                    borderColor: "#1B75BE",
                    "&:hover": {
                      backgroundColor: "white",
                      borderColor: "#1B75BE",
                    },
                  }}
                  disabled={
                    !otrosFields.cantidadCuotas ||
                    !otrosFields.interesTotal ||
                    !otrosFields.tipo
                  }
                  onClick={handleCreateOpcion}
                  startIcon={<Add />}
                >
                  Agregar
                </CustomButton>
              </Stack>
            )}
          </FormGroup>
        </Stack>
      </StepContent>
    </>
  );
}
