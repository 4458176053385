import React, { useEffect, useState } from "react";
import { FormatListBulleted } from "@mui/icons-material";
import Close from "@mui/icons-material/Close";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import CustomButton from "src/components/shared/Custom/Button";
import CustomDialog from "src/components/shared/Custom/Dialog";
import ShareService from "src/services/ShareService";
import Opcion from "./opcion";
import useFetch from "src/hooks/useFetch";
import { format } from "date-fns";
import _ from "lodash";
import CurrencyService from "src/services/CurrencyService";

export default function TablaValores({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { isLoading, fetchData, data } = useFetch(
    "get",
    "opciones-tabla-valores"
  );

  const {
    isLoading: isLoadingOpcionesInteres,
    fetchData: fetchDataOpcionesInteres,
    data: dataOpcionesInteres,
  } = useFetch("get", "opciones-intereses");

  const [editMode, setEditMode] = useState<'Semanal' | 'Mensual'>(null);
  const [refresher, setRefresher] = useState<boolean>(false)

  useEffect(() => {
      if (open) {
          fetchData();
      fetchDataOpcionesInteres();
      setOpciones([]);
    }
  }, [open, editMode, refresher]);

  useEffect(() => {
    if(open) {
      setEditMode(null)
    }
  }, [open])

  const [opciones, setOpciones] = useState<
    { valorPrestamo: number; tipo: "SEMANAL" | "MENSUAL" }[]
  >([]);

  const calcularValorCuota = (interesTotal, cantidadCuotas, valorPrestamo) => {
    if (valorPrestamo) {
      const result =
        (Number(valorPrestamo) +
          (Number(valorPrestamo) * Number(interesTotal)) / 100) /
        Number(cantidadCuotas);
      return CurrencyService.formatStatic(result);
    }
    return "No calculable";
  };

  const calcularInteresTotal = ({ porcentaje, cantidadCuotas }) => {
    return parseFloat(porcentaje) * Number(cantidadCuotas);
  };

  const getOpcionesText = () => {
    let text = "";
    const opcionesSemanales = _.sortBy(
      opciones.filter((opcion) => opcion.tipo === "SEMANAL"),
      ["valorPrestamo"]
    );
    const opcionesMensuales = _.sortBy(
      opciones.filter((opcion) => opcion.tipo === "MENSUAL"),
      ["valorPrestamo"]
    );
    if (opcionesSemanales.length) {
      opcionesSemanales.forEach((opcion) => {
        text += `*📑 OPCIONES SEMANAL DE ${CurrencyService.formatStatic(
          opcion.valorPrestamo
        )}*\n\n`;
        const interesesSemanales = _.sortBy(
          dataOpcionesInteres.filter(
            (opcion) => opcion.tipo === "SEMANAL" && opcion.cantidadCuotas
          ),
          ["cantidadCuotas"]
        );
        interesesSemanales.forEach((interesSemanal) => {
          text += `- ${interesSemanal.cantidadCuotas} ${
            interesSemanal.cantidadCuotas == 1 ? "cuota" : "cuotas"
          } de ${calcularValorCuota(
            calcularInteresTotal(interesSemanal),
            interesSemanal.cantidadCuotas,
            opcion.valorPrestamo
          )}\n`;
        });
        text += "\n";
      });
    }
    if (opcionesMensuales.length) {
      opcionesMensuales.forEach((opcion) => {
        text += `*📑 OPCIONES MENSUAL DE ${CurrencyService.formatStatic(
          opcion.valorPrestamo
        )}*\n\n`;
        const interesesMensuales = _.sortBy(
          dataOpcionesInteres.filter(
            (opcion) => opcion.tipo === "MENSUAL" && opcion.cantidadCuotas
          ),
          ["cantidadCuotas"]
        );
        interesesMensuales.forEach((interesMensual) => {
          text += `- ${interesMensual.cantidadCuotas} ${
            interesMensual.cantidadCuotas == 1 ? "cuota" : "cuotas"
          } de ${calcularValorCuota(
            calcularInteresTotal(interesMensual),
            interesMensual.cantidadCuotas,
            opcion.valorPrestamo
          )}\n`;
        });
        text += "\n";
      });
    }
    return text;
  };

  const generateTextWp = () => {
    let text = `*📅 COTIZACIÓN AL ${format(new Date(), "dd/MM/yyyy")}*\n\n`;
    text += getOpcionesText();
    return text;
  };

  const handleChangeOpcion = (checked, value) => {
    if (checked) {
      setOpciones([...opciones, value]);
    } else {
      setOpciones(opciones.filter(o => Object.is(o, value)));
    }
  };


  return (
    <CustomDialog open={open} onClose={() => setOpen(false)} scroll="paper">
      <DialogTitle>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap={5}
        >
          <Box
            fontSize={18}
            fontWeight={500}
            display={"flex"}
            alignItems="center"
            gap={2}
          >
            <FormatListBulleted
              sx={{
                fontSize: 32,
                color: "white",
                background: "#8EC3EF",
                borderRadius: "100%",
                height: 30,
                width: 30,
                padding: 0.5,
              }}
            />
            Tabla de valores
          </Box>
          <Box>
            <IconButton onClick={() => setOpen(false)}>
              <Close />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        {data && dataOpcionesInteres && (
          <Stack height={"100%"}>
            <Opcion
              title="Semanal"
              opciones={
                data ? _.orderBy(data.filter((item) => item.tipo === "SEMANAL"), ['valorPrestamo']) : []
              }
              handleChangeOpcion={handleChangeOpcion}
              editMode={editMode}
              setEditMode={setEditMode}
              refresher={refresher}
              setRefresher={setRefresher}
              elegidos={opciones}
            />
            <Opcion
              title="Mensual"
              opciones={
                data ? _.orderBy(data.filter((item) => item.tipo === "MENSUAL"), ['valorPrestamo']) : []
              }
              handleChangeOpcion={handleChangeOpcion}
              editMode={editMode}
              setEditMode={setEditMode}
              refresher={refresher}
              setRefresher={setRefresher}
              elegidos={opciones}
            />
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Stack direction="row" p={2} gap={2}>
          <CustomButton
            variant="outlined"
            sx={{
              color: "#1B75BE",
              borderColor: "#1B75BE",
              "&:hover": { backgroundColor: "white", borderColor: "#1B75BE" },
            }}
            onClick={() => {
              ShareService.openWhatsapp(generateTextWp());
            }}
            disabled={!opciones.length}
          >
            Enviar por whatsapp
          </CustomButton>
          <CustomButton
            variant="contained"
            buttonColor="#1B75BE"
            onClick={() => {
              ShareService.copyToClipboard(generateTextWp());
            }}
            disabled={!opciones.length}
          >
            Copiar a portapapeles
          </CustomButton>
        </Stack>
      </DialogActions>
    </CustomDialog>
  );
}
