import {
  FormControl,
  MenuItem,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import CustomSelect from "src/components/shared/Custom/Select";
import { ESTADOS } from "src/utils/PrestamoEnums";

interface ITableFilters {
  isLoading: boolean;
  setApi: React.Dispatch<React.SetStateAction<string>>;
  setEstadoId: React.Dispatch<React.SetStateAction<number>>;
}

export default function TableFilters({ isLoading, setApi, setEstadoId }: ITableFilters) {
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const estadoId = Number(event.target.value);
    setApi(estadoId === ESTADOS.PENDIENTE ? `prestamos?estadoId=${estadoId}&order=fechaEntrega&order=asc` : `prestamos?estadoId=${estadoId}`);
    setEstadoId(estadoId)
  };

  return (
    <Stack direction={"row"} alignItems={"center"}>
      <Typography sx={{ paddingRight: 2 }}>Filtrar por préstamos</Typography>
      <FormControl sx={{ m: 1, minWidth: 120 }} disabled={isLoading}>
        <CustomSelect
          onChange={(e) => handleChange(e)}
          displayEmpty
          defaultValue={ESTADOS.PENDIENTE}
        >
          <MenuItem value={ESTADOS.ACTIVO}>Activos</MenuItem>
          <MenuItem value={ESTADOS.PENDIENTE}>Pendientes</MenuItem>
          <MenuItem value={ESTADOS.FINALIZADO}>Finalizados</MenuItem>
        </CustomSelect>
      </FormControl>
    </Stack>
  );
}
