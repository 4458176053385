import { Close, NoteAddOutlined } from "@mui/icons-material";
import {
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Stepper,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import CustomButton from "src/components/shared/Custom/Button";
import CustomDialog, {
  CustomDialogActions,
} from "src/components/shared/Custom/Dialog";
import useForm, { IFormObject } from "src/hooks/useForm";
import useSnack from "src/hooks/useSnackbar";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import Resumen from "./Fields/resumen";
import CustomStep from "src/components/shared/Custom/Step";
import Monto from "./Fields/monto";
import Fecha from "./Fields/fecha";
import Metodo from "./Fields/metodo";
import Observacion from "./Fields/observacion";
import Tipo from "./Fields/tipo";
import { IPago, IPrestamo } from "src/utils/interfaces";
import useFetch from "src/hooks/useFetch";
import ApiService from "src/services/ApiService";
import { format, parse } from "date-fns";

export default function EntityForm() {
  const endpoint: string = "pagos";

  let [searchParams, setSearchParams] = useSearchParams();
  const [type, setType] = useState<"create" | "update">("create");

  const [open, setOpen] = useState<boolean>(false);

  const handleClose = () => {
    setSearchParams({});
  };

  useEffect(() => {
    if (
      searchParams.get("type") &&
      searchParams.get("entity") === "pago" &&
      searchParams.get("prestamoId")
    ) {
      setOpen(true);
      setType(searchParams.get("type") as "create" | "update");
    } else {
      setOpen(false);
      clearForm();
    }
  }, [searchParams]);

  const {data: pagoData, fetchData: fetchDataPago} = useFetch('get')

  const loadPago = async (pagoId) => {
    await fetchDataPago( `pagos/${pagoId}`)
  }

  useEffect(() => {
    if(type === 'update' && pagoData){
      loadEntity({
        ...pagoData,
        importe: String(pagoData.importe),
        fecha: pagoData.fecha
          ? format(
              parse(pagoData.fecha, "dd/MM/yyyy", new Date()),
              "yyyy-MM-dd"
            )
          : "",
      })
    }
  }, [pagoData])

  useEffect(() => {
    if(searchParams.get('pagoId')){
      loadPago(searchParams.get('pagoId'))
    }
  }, [searchParams.get('pagoId')])

  const [openSnackbar] = useSnack();

  const sendForm = async () => {
    const method = type === "create" ? "post" : "put";
    const response = await ApiService[method](
      type === "create" ? endpoint : `${endpoint}/${searchParams.get("pagoId")}`,
      {
        ...getSendForm(),
        importe: Number(getForm().importe),
        prestamoId: searchParams.get("prestamoId"),
      }
    );
    if (response?.response?.status === 400) {
      openSnackbar(
        `Hubo un error al intentar ${
          type === "create" ? "crear" : "editar"
        }  el préstamo: ${response.response.data.message}`
      );
    } else {
      setSearchParams({});
      openSnackbar(`Se ${type === "create" ? "agregó" : "edito un"} un pago`);
      clearForm();
    }
  };

  const fields: IFormObject = {
    fecha: { required: true, value: null },
    importe: { required: true, value: null },
    metodoPagoId: { required: true, value: null },
    observacion: { required: false, value: null },
    esInteres: { required: false, value: false },
  };

  const {
    getForm,
    getSendForm,
    handleFormChange,
    setFormField,
    saveButtonState,
    sendRequest,
    clearForm,
    loadEntity,
  } = useForm(fields, sendForm);

  const { data, fetchData } = useFetch(
    "get",
    `prestamos/${searchParams.get("prestamoId")}`
  );

  useEffect(() => {
    if (searchParams.get("prestamoId")) {
      fetchData();
    }
  }, [searchParams.get("prestamoId")]);

  return (
    <CustomDialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap={5}
        >
          <Box
            fontSize={18}
            fontWeight={500}
            display={"flex"}
            alignItems="center"
            gap={2}
          >
            {type === "create" ? (
              <NoteAddOutlined
                sx={{
                  fontSize: 32,
                  color: "white",
                  background: "#8EC3EF",
                  borderRadius: "100%",
                  height: 30,
                  width: 30,
                  padding: 0.5,
                }}
              />
            ) : (
              <BorderColorOutlinedIcon
                sx={{
                  fontSize: 32,
                  color: "white",
                  background: "#8EC3EF",
                  borderRadius: "100%",
                  height: 30,
                  width: 30,
                  padding: 0.5,
                }}
              />
            )}
            {type === "create" ? "Agregar pago" : "Editar pago"}
          </Box>
          <Box>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Stack paddingTop={1} gap={2}>
          <Resumen data={data} type={type} />
          <Tipo handleFormChange={handleFormChange} getForm={getForm} />
          <Stepper orientation="vertical">
            <CustomStep>
              <Monto
                handleFormChange={handleFormChange}
                getForm={getForm}
                data={data}
                setFormField={setFormField}
                type={type}
              />
            </CustomStep>
            <CustomStep>
              <Fecha handleFormChange={handleFormChange} getForm={getForm} />
            </CustomStep>
            <CustomStep completed>
              <Metodo
                handleFormChange={handleFormChange}
                getForm={getForm}
                setFormField={setFormField}
              />
            </CustomStep>
          </Stepper>
          <Observacion handleFormChange={handleFormChange} getForm={getForm} />
        </Stack>
      </DialogContent>
      <CustomDialogActions>
        <CustomButton
          variant="contained"
          buttonColor="#1B75BE"
          disabled={saveButtonState()}
          onClick={sendRequest}
        >
          {type === "create" ? "Agregar" : "Guardar"}
        </CustomButton>
      </CustomDialogActions>
    </CustomDialog>
  );
}
