import React, { useState } from "react";
import CustomButton from "../../../../shared/Custom/Button";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import TocIcon from "@mui/icons-material/Toc";
import Cotizador from "src/components/base/Cotizador";
import TablaValores from "src/components/base/TablaValores";

export default function Actions() {
  const [openCotizador, setOpenCotizador] = useState<boolean>(false);
  const [openTablaValores, setOpenTablaValores] = useState<boolean>(false);

  return (
    <>
      <TablaValores open={openTablaValores} setOpen={setOpenTablaValores} />
      <Cotizador open={openCotizador} setOpen={setOpenCotizador} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          flexBasis: "350px",
        }}
      >
        <CustomButton
          variant="contained"
          startIcon={<RequestQuoteOutlinedIcon />}
          buttonColor={"#93EF8E"}
          sx={{ width: "156px" }}
          onClick={() => setOpenCotizador(true)}
          >
          Cotizador
        </CustomButton>
        <CustomButton
          size="small"
          variant="contained"
          startIcon={<TocIcon />}
          buttonColor={"#EFB98E"}
          onClick={() => setOpenTablaValores(true)}
        >
          Tabla de valores
        </CustomButton>
      </div>
    </>
  );
}
