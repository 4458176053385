import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import React, { useState } from "react";
import Close from "@mui/icons-material/Close";
import CustomButton from "../Custom/Button";
import CustomDialog from "../Custom/Dialog";
import { IPrestamo } from "src/utils/interfaces";
import _ from "lodash";
import { IWhatsappButton } from "./WhatsappButton";
import Share from "@mui/icons-material/Share";
import CustomTitle from "../Custom/Title";
import CurrencyService from "src/services/CurrencyService";
import ShareService, { ISSItem } from "src/services/ShareService";
import WhatsappModal from "./WhatsappModal";

interface ICompartirModal {
  open: boolean;
  handleClose: () => void;
  prestamos: IPrestamo | IPrestamo[];
  type: "préstamo" | "cliente";
  clienteName?: string;
  telefonos?: IWhatsappButton;
}

export default function CompartirModal({
  open,
  handleClose,
  prestamos,
  type,
  clienteName,
  telefonos,
}: ICompartirModal) {

  const itemsPrestamo: (ISSItem<IPrestamo> | "short_separator")[] = [
    {
      label: "Estado",
      property: "estado.denominacion",
    },
    {
      label: "Tipo de préstamo",
      property: "tipo",
    },
    {
      label: "Día a cobrar",
      property: "subtipo",
    },
    {
      label: "Monto del préstamo",
      property: "valorPrestamo",
      format(row) {
        return row.valorPrestamo
          ? CurrencyService.formatStatic(row.valorPrestamo)
          : "--";
      },
    },
    "short_separator",
    {
      label: "Cuotas completadas / Cuotas totales",
      property: "cuotaActual",
      format(row) {
        return `${row.cuotasCompletadas}/${row.cantidadCuotas}`;
      },
    },
    {
      label: "Valor cuota",
      property: "valorCuota",
      format(row) {
        return row.valorCuota
          ? CurrencyService.formatStatic(row.valorCuota)
          : "--";
      },
    },
    {
      label: "Valor restante para completar la cuota",
      property: "valorRestanteCuotaActual",
      format(row) {
        return CurrencyService.formatStatic(row.valorRestanteCuotaActual);
      },
    },
    "short_separator",
  ];

  const [openWpModal, setOpenWpModal] = useState<boolean>(false)

  return (
    <>
      <WhatsappModal shareText={prestamos && ShareService.getWpTextified(clienteName, prestamos, itemsPrestamo)} entity={telefonos} open={openWpModal} handleClose={() => setOpenWpModal(false)} />
      <CustomDialog
        open={open}
        onClose={() => handleClose()}
      >
        <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap={5}
          >
            <Stack direction="row" alignItems="center" gap={2}>
              <Share
                sx={{
                  fontSize: 32,
                  color: "white",
                  background: "#8EC3EF",
                  borderRadius: "100%",
                  height: 30,
                  width: 30,
                  padding: 0.5,
                }}
              />
              <CustomTitle>Compartir {type}</CustomTitle>
            </Stack>
            <Box>
              <IconButton onClick={() => handleClose()}>
                <Close />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent dividers={true}>
          {prestamos && (
            <div
              dangerouslySetInnerHTML={{
                __html: ShareService.markdownToHTML(
                  _.replace(
                    ShareService.getWpTextified(
                      clienteName,
                      prestamos,
                      itemsPrestamo
                    ),
                    /\*/g,
                    "**"
                  )
                ),
              }}
            />
          )}
        </DialogContent>
        <DialogActions sx={{padding: 2}}>
          <Stack direction="row" justifyContent="space-between" gap={1}>
            <CustomButton
              disabled={!telefonos?.telefonoWp && !telefonos?.telefonoAltWp}
              variant="outlined"
              sx={{
                color: "#1B75BE",
                borderColor: "#1B75BE",
                "&:hover": { backgroundColor: "white", borderColor: "#1B75BE" },
              }}
              onClick={() => setOpenWpModal(true)}
            >
              Enviar a whatsapp
            </CustomButton>
            <CustomButton
              variant="contained"
              buttonColor="#1B75BE"
              onClick={() => {
                ShareService.copyToClipboard(
                  _.replace(
                    ShareService.getWpTextified(
                      clienteName,
                      prestamos,
                      itemsPrestamo
                    ),
                    /\*/g,
                    ""
                  )
                );
              }}
            >
              Copiar al portapapeles
            </CustomButton>
          </Stack>
        </DialogActions>
      </CustomDialog>
    </>
  );
}
