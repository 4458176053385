import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'
import { IFormComponent } from 'src/hooks/useForm'
import CurrencyService from 'src/services/CurrencyService'

interface IResumen extends IFormComponent {
  interesTotal: string
}

export default function Resumen({getForm, interesTotal}: IResumen) {

  const calcularValorCuota = (type: string | boolean = false) => {
    if(getForm()?.valorPrestamo && getForm()?.cantidadCuotas && interesTotal){
      const result = (Number(getForm()?.valorPrestamo)+(Number(getForm()?.valorPrestamo)*Number(interesTotal)/100))/Number(getForm()?.cantidadCuotas)
      if(type === 'numeric') return Math.round(result)
      return CurrencyService.formatStatic(result)
    }
    if(type === 'numeric'){
      return null
    }
    return 'No calculable'
  }

  const calcularMontoARecaudar = () => {
    if(calcularValorCuota('numeric')) return CurrencyService.formatStatic(Number(getForm()?.cantidadCuotas)*calcularValorCuota('numeric'))
    return 'No calculable'
  }

  return (
    <Box sx={{ overflow: "auto" }}>
        <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
          <Table sx={{ border: "2px solid #acafe7" }}>
            <TableHead sx={{ backgroundColor: "#1B23BE26" }}>
              <TableRow>
                <TableCell>Tipo de préstamo</TableCell>
                <TableCell>Monto del préstamo</TableCell>
                <TableCell>Interés total aplicado</TableCell>
                <TableCell>Cantidad total de cuotas</TableCell>
                <TableCell>Valor de la cuota</TableCell>
                <TableCell>Monto a recaudar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{getForm()?.tipo ? getForm()?.tipo : '--'}{getForm()?.subtipo && ` - ${getForm()?.subtipo}`}</TableCell>
                <TableCell sx={{whiteSpace: 'nowrap'}}>{getForm()?.valorPrestamo ? CurrencyService.formatStatic(getForm()?.valorPrestamo) : '--'}</TableCell>
                <TableCell sx={{whiteSpace: 'nowrap'}}>{interesTotal || getForm()?.interesTotal ? `${interesTotal || getForm()?.interesTotal}%` : 'No calculable'}</TableCell>
                <TableCell sx={{whiteSpace: 'nowrap'}}>{getForm()?.cantidadCuotas ? getForm()?.cantidadCuotas : '--'}</TableCell>
                <TableCell sx={{whiteSpace: 'nowrap'}}>{calcularValorCuota()}</TableCell>
                <TableCell sx={{whiteSpace: 'nowrap'}}>{calcularMontoARecaudar()}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Box>
  )
}
