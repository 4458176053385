import { FormControl, InputLabel, MenuItem, Stack, StepContent, StepLabel, Typography } from '@mui/material'
import React from 'react'
import CustomSelect from 'src/components/shared/Custom/Select'
import CustomStep from 'src/components/shared/Custom/Step'
import { IFormComponent } from 'src/hooks/useForm'
import { SUBTIPOSMensual, SUBTIPOSSemanal, TIPOSEnum } from 'src/utils/PrestamoEnums'

interface ITipo extends IFormComponent {}

export default function Tipo({handleFormChange, getForm, setFormField}: ITipo) {
  return (
    <>
          <StepLabel>
            <Typography fontSize={18} fontWeight={400} color={"black"}>
              Seleccionar un tipo de préstamo
            </Typography>
          </StepLabel>
          <StepContent>
            <Stack
              rowGap={2}
              columnGap={1}
              direction="row"
              justifyContent="space-between"
              flexWrap={"wrap"}
            >
              <FormControl sx={{ flexGrow: 1 }}>
                <InputLabel sx={{color: getForm()?.tipo ? 'secondary.main' : 'black'}}>Tipo de préstamo</InputLabel>
                <CustomSelect
                  name='tipo'
                  label="Tipo de préstamo"
                  variant="outlined"
                  placeholder="Elegir una opción"
                  sx={{ minWidth: "240px", width: "100%" }}
                  value={getForm()?.tipo}
                  onChange={(e) => {handleFormChange(e.target); setFormField('subtipo', null); setFormField('cantidadCuotas', null)}}
                >
                  <MenuItem value={TIPOSEnum.SEMANAL}>SEMANAL</MenuItem>
                  <MenuItem value={TIPOSEnum.MENSUAL}>MENSUAL</MenuItem>
                </CustomSelect>
              </FormControl>
              <FormControl sx={{ flexGrow: 1 }}>
                <InputLabel sx={{color: getForm()?.subtipo ? 'secondary.main' : 'black'}}>Día a cobrar</InputLabel>
                <CustomSelect
                  label="Día a cobrar"
                  variant="outlined"
                  placeholder="Elegir una opción"
                  sx={{ minWidth: "240px", width: "100%" }}
                  name='subtipo'
                  value={getForm()?.subtipo}
                  onChange={(e) => handleFormChange(e.target)}
                  disabled={!getForm()?.tipo}
                >
                  {getForm()?.tipo === TIPOSEnum.MENSUAL && SUBTIPOSMensual.map(subtipo => <MenuItem key={subtipo} value={subtipo}>{subtipo}</MenuItem>)}
                  {getForm()?.tipo === TIPOSEnum.SEMANAL && SUBTIPOSSemanal.map(subtipo => <MenuItem key={subtipo} value={subtipo}>{subtipo}</MenuItem>)}
                </CustomSelect>
              </FormControl>
            </Stack>
          </StepContent>
        </>
  )
}
