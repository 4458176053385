import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import * as Icons from "@mui/icons-material"
import { Stack, styled } from "@mui/material";
import { indexOf } from "lodash";
import { Link } from "react-router-dom";

export interface IBreadcrumb {
  icon: any;
  text: string;
  path: string;
}

interface ICustomPaper {
  children: React.ReactNode;
  breadcrumb?: IBreadcrumb[];
  sx?: any
}

const CustomLink = styled(Link)`
  text-decoration: none;
  color: #26282b;
  &:hover {
    color: #26282b;
    text-decoration: underline;
  }
  &:active {
    color: #26282b;
  }
  &:visited {
    color: #26282b;
  }
`;

function DynamicIcon ({iconName}) {

  const IconName = Icons[iconName];
  return <IconName sx={{ color: "#1B23BE", fontSize: 17 }}/>
}

export default function CustomPaper({ children, breadcrumb, sx }: ICustomPaper) {
  function breadcrumbGenerator(breadcrumb: IBreadcrumb[]) {
    return breadcrumb.map((section) => (
      <React.Fragment key={Math.random()}>
        <DynamicIcon iconName={section.icon}/>
        {indexOf(breadcrumb, section) !== breadcrumb.length - 1 ? (
          <CustomLink to={section.path}>
            <Typography fontSize={16} paddingLeft={1} noWrap>
              {section.text}
            </Typography>
          </CustomLink>
        ) : (
          <Typography fontSize={16} paddingLeft={1} noWrap>
            {section.text}
          </Typography>
        )}
        {indexOf(breadcrumb, section) !== breadcrumb.length - 1 && (
            <ChevronRightIcon sx={{ color: "#1B23BE", marginLeft: 1, marginRight: 1 }} />
        )}
      </React.Fragment>
    ));
  }

  return (
    <Paper sx={{ borderRadius: "16px", ...sx}}>
      {breadcrumb && (
        <Stack direction="row" alignItems="center" padding={2}>
          {breadcrumbGenerator(breadcrumb)}
        </Stack>
      )}
      {children}
    </Paper>
  );
}
