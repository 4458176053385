import { Delete } from "@mui/icons-material";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useTable from "src/hooks/useTable";

export default function TableWrapper({ refresher }) {
  const { data, getData } = useTable("metodos-pago");

  useEffect(() => {
    getData();
  }, [refresher]);

  return (
    <TableContainer sx={{ border: "2px solid #c3c5f2" }} component={Paper}>
      <Table size="small">
        <TableHead sx={{ backgroundColor: "#dddef5" }}>
          <TableRow>
            <TableCell sx={{textAlign: 'center'}}>Denominación</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Boolean(data) &&
            data.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{row.denominacion}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
