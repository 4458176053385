import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import CustomSkeleton from "../Custom/Skeleton";
import _ from "lodash";
import Row from "./Row";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";

export interface IColumn<T> {
  property: string;
  numeric?: boolean;
  label: string;
  notSortable?: boolean;
  format?: (row: T, value?) => any;
  sx?: {
    color?: string;
    fontSize?: number;
    fontWeight?: number;
    textAlign?: string;
    fontStyle?: string;
    width?: string;
  };
  isDate?: boolean;
}

interface EnhancedTableProps {
  rowCount: number;
  headCells: IColumn<any>[];
  collapsable: (row?: any) => React.ReactNode;
  actions: IAction[];
  editing?: boolean;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { headCells, collapsable, actions, editing } = props;

  return (
    <TableHead sx={{ backgroundColor: "#DDDEF5" }}>
      <TableRow>
        {editing && <TableCell />}
        {collapsable && <TableCell />}
        {headCells?.map((headCell) => (
          <TableCell
            key={headCell.property}
            align={headCell.numeric ? "right" : "left"}
          >
            {headCell.label}
          </TableCell>
        ))}
        {actions && <TableCell key={"actions"} width={2}></TableCell>}
      </TableRow>
    </TableHead>
  );
}

export function CellGenerator({
  row,
  column: { property, format, numeric, sx },
}: {
  row;
  column: IColumn<any>;
}) {
  return (
    <TableCell scope="row" key={`${row.id}.${property}`} sx={sx}>
      {format
        ? format(row, _.get(row, property))
          ? format(row, _.get(row, property))
          : "--"
        : _.get(row, property)
        ? _.get(row, property)
        : "--"}
    </TableCell>
  );
}

export interface IAction {
  label: string;
  onClick: (row?: any) => void;
  disabled?: (row?: any) => boolean;
  color?: string;
  hide?: (row?: any) => boolean;
}

interface ITablaEstandar {
  isLoading: boolean;
  rows: any;
  columns: IColumn<any>[];
  searchBar?: boolean;
  search?: string;
  setterSearch?: React.Dispatch<React.SetStateAction<string>>;
  filters?: React.ReactNode;
  actions?: IAction[];
  collapsable?: (row?: any) => React.ReactNode;
  searchPlaceholder?: string;
  setterData?: any;
  handleArrayMovement?: (rowsNewOrder?: any) => void;
  editing?: boolean;
}

const SortableItem = SortableElement(
  ({ row, columns, collapsable, actions, editing }) => (
    <Row
      key={row?.id}
      row={row}
      columns={columns}
      collapsable={collapsable}
      actions={actions}
      editing={editing}
    />
  )
);

const SortableListContainer = SortableContainer(
  ({ rows, columns, collapsable, actions, editing }) => (
    <TableBody component="tbody">
      {rows?.map((row, index) => (
        //@ts-ignore
        <SortableItem
          key={`sortableItem-${row?.id}`}
          index={index}
          //@ts-ignore
          row={row}
          columns={columns}
          collapsable={collapsable}
          actions={actions}
          editing={editing}
        />
      ))}
    </TableBody>
  )
);

export default function TablaOrdenable({
  isLoading,
  rows,
  columns,
  actions,
  collapsable,
  setterData,
  handleArrayMovement,
  editing,
}: ITablaEstandar) {
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const arrayMoved = arrayMove(rows, oldIndex, newIndex);
    setterData(arrayMoved);
    handleArrayMovement(
      arrayMoved.map((item: any) => ({
        id: item?.id,
        index: _.indexOf(arrayMoved, item),
      }))
    );
  };

  const handleSortStart = ({ node, helper }: any) => {
    node.childNodes.forEach((td: HTMLTableDataCellElement, index: number) => {
      helper.childNodes[index].style.width = `${td.offsetWidth}px`;
    });
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              rowCount={rows?.length}
              headCells={columns}
              collapsable={collapsable}
              actions={actions}
              editing={editing}
            />
            {Boolean(!rows?.length) && isLoading && (
              <>
                <TableRow>
                  {actions && (
                    <TableCell>
                      <CustomSkeleton
                        value={""}
                        loading={isLoading}
                        variant="rectangular"
                        width="100%"
                        height="30px"
                      ></CustomSkeleton>
                    </TableCell>
                  )}
                  {collapsable && (
                    <TableCell>
                      <CustomSkeleton
                        value={""}
                        loading={isLoading}
                        variant="rectangular"
                        width="100%"
                        height="30px"
                      ></CustomSkeleton>
                    </TableCell>
                  )}
                  {columns?.map((column) => (
                    <TableCell key={column.property}>
                      <CustomSkeleton
                        value={""}
                        loading={isLoading}
                        variant="rectangular"
                        width="100%"
                        height="30px"
                      ></CustomSkeleton>
                    </TableCell>
                  ))}
                </TableRow>
              </>
            )}
            {Boolean(rows?.length) && (
              <SortableListContainer
                //@ts-ignore
                rows={rows}
                onSortEnd={onSortEnd}
                onSortStart={handleSortStart}
                useDragHandle={true}
                lockAxis="y"
                columns={columns}
                collapsable={collapsable}
                actions={actions}
                editing={editing}
              />
            )}
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}
