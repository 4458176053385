import { AttachMoney } from "@mui/icons-material";
import {
  InputAdornment,
  StepContent,
  StepLabel,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import CustomTextField from "src/components/shared/Custom/TextField";
import { IFormComponent } from "src/hooks/useForm";
import CurrencyService from "src/services/CurrencyService";

export default function Monto({
  fields,
  name,
  handleFormChange
}) {

  return (
    <>
      <StepLabel>
        <Typography fontSize={18} fontWeight={400} color={"black"}>
          Ingresar monto del préstamo
        </Typography>
      </StepLabel>
      <StepContent>
        <CustomTextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AttachMoney />
              </InputAdornment>
            ),
          }}
          name={name}
          value={
            fields[name] ? CurrencyService.formatInput(fields[name]) : ''
          }
          onChange={(e) =>
            handleFormChange(e.target, (value) => {
              value = value.replace(/\D/g, "");
              return value;
            })
          }
          placeholder="Monto del préstamo"
          sx={{ minWidth: "240px", width: "100%" }}
        />
      </StepContent>
    </>
  );
}
