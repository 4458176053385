import {
  FormControl,
  MenuItem,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import CustomSelect from "src/components/shared/Custom/Select";
import { ICliente, IPrestamo } from "src/utils/interfaces";
import { ESTADOS } from "src/utils/PrestamoEnums";

export default function TableFilters({
  setFilters
}) {
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const estadoId = event.target.value
    if(!estadoId){
      setFilters('')
    } else {
      setFilters(`&conPrestamosEnEstado=${estadoId}`)
    }
  };

  return (
    <Stack direction={"row"} alignItems={"center"}>
      <Typography sx={{ paddingRight: 2 }}>
        Filtrar clientes con préstamos
      </Typography>
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <CustomSelect onChange={(e) => handleChange(e)} displayEmpty defaultValue={''}>
          <MenuItem value={''}>Todos</MenuItem>
          <MenuItem value={ESTADOS.ACTIVO}>Activos</MenuItem>
          <MenuItem value={ESTADOS.PENDIENTE}>Pendientes</MenuItem>
          <MenuItem value={ESTADOS.FINALIZADO}>Finalizados</MenuItem>
          <MenuItem value='INACTIVOS'>Inactivos</MenuItem>
        </CustomSelect>
      </FormControl>
    </Stack>
  );
}
