import { Stack, Switch, Typography } from '@mui/material'
import React from 'react'
import { ESTADOS } from 'src/utils/PrestamoEnums'

export default function Estado({handleFormChange, getForm}: {getForm: any, handleFormChange: (target: any, formatInput?: (value: any) => any) => void}) {
  return (
    <Stack>
    <Typography fontSize={16} fontWeight={500}>
      Estado del préstamo
    </Typography>
    <Stack direction={"row"} alignItems={"center"}>
      <Switch
        name='estadoId'
        onChange={(e) => {handleFormChange(e.target, (value) => value ? ESTADOS.ACTIVO : ESTADOS.PENDIENTE)}}
        sx={{
          "& .MuiSwitch-switchBase": {
            "&.Mui-checked": {
              color: "#0DFF00",
              "& + .MuiSwitch-track": {
                background: "#0DFF00",
              },
            },
          },
        }}
      />
      <Typography>{getForm()?.estadoId === ESTADOS.PENDIENTE ? 'Pendiente' : 'Activo'}</Typography>
    </Stack>
  </Stack>
  )
}
