import { InfoOutlined } from "@mui/icons-material";
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import useFetch from "src/hooks/useFetch";
import CurrencyService from "src/services/CurrencyService";

export default function Resumen({ data }) {

  return (
    <Stack gap={2}>
      <Stack direction="row" gap={1}>
        <InfoOutlined sx={{ color: "#1B23BE" }} />
        <Typography>Datos del préstamo seleccionado</Typography>
      </Stack>
      <TableContainer sx={{maxWidth: '100%'}}>
        <Table sx={{ width: "max-content" }}>
          <TableHead sx={{ backgroundColor: "#DDDEF5" }}>
            <TableRow>
              <TableCell>Alias cliente</TableCell>
              <TableCell>Fecha de visita inicial</TableCell>
              {data?.fechaAplazo && <TableCell>Fecha de visita aplazada</TableCell>}
              <TableCell>Cuota actual</TableCell>
              <TableCell>Valor de la cuota</TableCell>
              <TableCell>Cuotas completadas / Cuotas totales</TableCell>
              <TableCell>Restante cuota</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <b>{data?.prestamo?.cliente?.alias}</b>
              </TableCell>
              <TableCell>
                <b>{data?.fechaLista}</b>
              </TableCell>
              {data?.fechaAplazo && <TableCell>
                <b>{data?.fechaAplazo}</b>
              </TableCell>}
              <TableCell>{data?.prestamo?.cuotaActual}</TableCell>
              <TableCell>
                {CurrencyService.formatStatic(data?.prestamo?.valorCuota)}
              </TableCell>
              <TableCell sx={{ textAlign: "center" }}>
                {data?.prestamo?.cuotasCompletadas}/
                {data?.prestamo?.cantidadCuotas}
              </TableCell>
              <TableCell>
                {CurrencyService.formatStatic(
                  data?.prestamo?.valorRestanteCuotaActual
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
}
