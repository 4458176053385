import { PointOfSaleOutlined } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import PercentageBar from "./PercentageBar";
import MetodoPago from "./MetodoPago";
import { IFinanzasFilter } from "../..";
import useFetch from "src/hooks/useFetch";
import CurrencyService from "src/services/CurrencyService";
import _ from "lodash";

export default function Totales({filter, api}: {api: string, filter: IFinanzasFilter}) {

  const {fetchData, isLoading, data} = useFetch('get', `${api?.replace('pagos', 'pagos-stats')}`)

  useEffect(() => {
    if(api){
      fetchData()
    }
  }, [api])

  return (
    <Stack gap={2}>
      <Stack direction="row" gap={1}>
        <PointOfSaleOutlined sx={{ color: "#1B23BE" }} />
        <Typography fontSize={"18px"} fontWeight={"500"}>
          Total recaudado: <b>{data && data.length ? CurrencyService.formatStatic(_.sum(data.map(item => Number(item.total)))) : ''}</b>
        </Typography>
      </Stack>
      <PercentageBar data={data} />
      <MetodoPago data={data} />
    </Stack>
  );
}
