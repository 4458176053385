import { Add, ExpandMore, PersonAdd } from "@mui/icons-material";
import {
  AccordionDetails,
  AccordionSummary,
  FormControl,
  InputLabel,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomAccordion from "src/components/shared/Custom/Accordion";
import CustomButton from "src/components/shared/Custom/Button";
import CustomSelect from "src/components/shared/Custom/Select";
import CustomTextField from "src/components/shared/Custom/TextField";
import useFetch from "src/hooks/useFetch";

export default function Form({refresher, setRefresher}) {
  const [form, setForm] = useState<{
    nombre: string;
    apellido: string;
    email: string;
    password: string;
    rolId: number;
  }>({
    nombre: null,
    apellido: null,
    email: null,
    password: null,
    rolId: null,
  });

  const {data, fetchData} = useFetch('get', 'roles')

  useEffect(() => {fetchData()}, [])

  const {fetchData: fetchDataPost} = useFetch('post', 'usuarios', form)

  const handleFormChange = (field, value) => {
    setForm({...form, [field]: value})
  }

  const handleSubmit = async () => {
    await fetchDataPost()
    setRefresher(!refresher)
    clearForm()
    setExpanded(false)
  }

  const clearForm = () => {
    setForm({
      nombre: null,
      apellido: null,
      email: null,
      password: null,
      rolId: null,
    })
  }

  const [expanded, setExpanded] = useState<boolean>(false)

  useEffect(() => {clearForm()}, [expanded])

  return (
    <CustomAccordion sx={{backgroundColor: '#f2f2f2'}} expanded={expanded}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ flexDirection: "row-reverse", gap: 2 }}
        onClick={() => setExpanded(!expanded)}
      >
        <Stack direction='row' gap={1}>
        <PersonAdd sx={{color: '#93ef8e'}}/>
        <Typography fontWeight={500}>Crear usuario</Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Stack gap={2}>
          <CustomTextField onChange={(e) => handleFormChange(e.target.name, e.target.value)} name="nombre" label="Nombre" value={form['nombre']} />
          <CustomTextField onChange={(e) => handleFormChange(e.target.name, e.target.value)} name="apellido" label="Apellido" value={form['apellido']}/>
          <CustomTextField onChange={(e) => handleFormChange(e.target.name, e.target.value)} name="email" label="Email" value={form['email']}/>
          <CustomTextField onChange={(e) => handleFormChange(e.target.name, e.target.value)} name="password" label="Contraseña" value={form['password']}/>
          <FormControl>
            <InputLabel>Tipo</InputLabel>
            <CustomSelect onChange={(e) => handleFormChange(e.target.name, e.target.value)} name="rolId" label="Tipo" variant="outlined" value={form['rolId']}>
              {data?.length && data.map(rol => {
                return <MenuItem key={rol.id} value={rol.id}>{rol.denominacion}</MenuItem>
              })}
            </CustomSelect>
          </FormControl>
          <Stack justifyContent="flex-end" direction="row">
            <CustomButton onClick={() => handleSubmit()} buttonColor="#1b75be" disabled={Object.values(form).some(item => item === null || item === undefined || item === '')} variant="contained" startIcon={<Add />}>
              Crear
            </CustomButton>
          </Stack>
        </Stack>
      </AccordionDetails>
    </CustomAccordion>
  );
}
