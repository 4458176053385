import { List } from "@mui/icons-material";
import { DialogActions, DialogContent, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import CustomButton from "src/components/shared/Custom/Button";
import CustomDialog, {
  CustomDialogActions,
} from "src/components/shared/Custom/Dialog";
import CustomDialogTitle from "src/components/shared/Custom/DialogTitle";
import Resumen from "./Resumen";
import FechaAplazo from "./Fields/FechaAplazo";
import Observacion from "./Fields/Observacion";
import useFetch from "src/hooks/useFetch";
import axios, { AxiosError } from "axios";
import useSnack from "src/hooks/useSnackbar";
import { format, parse } from "date-fns";

export default function Aplazar({ getData }) {
  const [open, setOpen] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("aplazar")) {
      cleanForm();
      setOpen(true);
    } else {
      setOpen(false);
      getData()
    }
  }, [searchParams.get("aplazar")]);

  const handleClose = () => {
    setSearchParams({});
  };

  const [form, setForm] = useState({
    fechaAplazo: null,
    comentario: null,
    listaId: null,
  });

  const [openSnackbar] = useSnack();

  const cleanForm = () => {
    setForm({
      fechaAplazo: null,
      comentario: null,
      listaId: searchParams.get("aplazar"),
    });
  };

  const { fetchData, isLoading } = useFetch("post", "aplazos-listas", form);

  const handleSubmit = async () => {
    const response: any = await fetchData();
    if (axios.isAxiosError(response)) {
      openSnackbar(response?.response?.data?.message);
    } else {
      openSnackbar(
        `La visita de ${response?.data?.lista?.prestamo?.cliente?.alias} fue aplazada para el ${response?.data?.fechaAplazo}`
      );
      handleClose();
    }
  };

  const { data, fetchData: fetchDataAplazo } = useFetch("get", `listas/${searchParams.get("aplazar")}`);

  useEffect(() => {
    if (searchParams.get("aplazar")) {
      fetchDataAplazo();
    }
  }, [searchParams.get("aplazar")]);

  useEffect(() => {
    if(data?.fechaAplazo){
      setForm({...form, fechaAplazo: format(parse(data?.fechaAplazo, "dd/MM/yyyy", new Date()), 'yyyy-MM-dd')})
    }
  }, [data])

  return (
    <CustomDialog open={open} onClose={handleClose}>
      <CustomDialogTitle
        handleClose={handleClose}
        title={data?.fechaAplazo ? "Modificar aplazo de visita" : "Aplazar visita"}
        icon="List"
        iconColor="#8EC3EF"
      />
      <DialogContent>
        <Stack gap={3}>
          <Resumen data={data} />
          <FechaAplazo form={form} setForm={setForm} />
          <Observacion form={form} setForm={setForm} />
        </Stack>
      </DialogContent>
      <CustomDialogActions>
        <CustomButton
          variant="contained"
          buttonColor="#1B75BE"
          disabled={!form?.fechaAplazo || isLoading}
          onClick={() => handleSubmit()}
        >
          Guardar aplazo
        </CustomButton>
      </CustomDialogActions>
    </CustomDialog>
  );
}
