import { Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import CurrencyService from "src/services/CurrencyService";
import { ESTADOS } from "src/utils/PrestamoEnums";
import TablaEstandar, { IColumn, IAction } from "src/components/shared/Tabla";
import { ICliente, IPrestamo } from "src/utils/interfaces";
import useTable from "src/hooks/useTable";
import Collapsable from "./Collapsable";
import BorrarModal from "src/components/shared/Modals/BorrarModal";
import CompartirModal from "src/components/shared/Modals/CompartirModal";
import { IWhatsappButton } from "src/components/shared/Modals/WhatsappButton";
import EntityForm from "./EntityForms/EntityForm";
import AuthService from "src/services/AuthService";
import { Roles } from "src/utils/RolEnum";
import CrearVisita from "./CrearVisita";

export default function TableWrapper({ client }: { client: ICliente }) {
  const params = useParams();

  const { isLoading, getData, data } = useTable(
    `prestamos?clienteId=${params?.id}&order=createdAt&order=desc`
  );

  const columns: IColumn<IPrestamo>[] = [
    {
      property: "estado",
      label: "Estado",
      format(row) {
        if (row.estadoId === ESTADOS.ACTIVO) {
          return (
            <Typography fontWeight={500} color={"#09AD00"}>
              {row.estado.denominacion}
            </Typography>
          );
        } else
          return (
            <Typography fontWeight={500} color={"GrayText"}>
              {row.estado.denominacion}
            </Typography>
          );
      },
    },
    {
      property: "tipo",
      label: "Tipo de préstamo",
      format(row) {
        return (
          <>
            <Typography display="inline" fontWeight={500}>
              {row?.tipo}
            </Typography>
            {" - "}
            <Typography display="inline" fontStyle={"italic"}>
              {row?.subtipo}
            </Typography>
          </>
        );
      },
      notSortable: true,
    },
    {
      property: "valorPrestamo",
      label: "Monto del préstamo",
      format(row) {
        return row.valorPrestamo
          ? `${CurrencyService.formatStatic(row.valorPrestamo)}`
          : "--";
      },
    },
    {
      property: "cuotasCompletadas",
      label: "Cuotas completadas / Cuotas totales",
      format(row) {
        return (
          <>
            <Typography display="inline" fontWeight={500}>
              {row.cuotasCompletadas}
            </Typography>
            /{row.cantidadCuotas}
          </>
        );
      },
      notSortable: true,
    },
    {
      property: "valorCuota",
      label: "Valor de la cuota",
      format(row) {
        return row.valorCuota
          ? `${CurrencyService.formatStatic(row.valorCuota)}`
          : "";
      },
    },
    {
      property: "cobrado",
      label: "Monto recaudado / Monto a recaudar",
      format(row) {
        return (
          <>
            {typeof row.cobrado === "string"
              ? row.cobrado
              : `${CurrencyService.formatStatic(row.cobrado)}`}
            /
            {typeof row.bruto === "string"
              ? row.bruto
              : `${CurrencyService.formatStatic(row.bruto)}`}
          </>
        );
      },
      notSortable: true,
    },
    {
      property: "createdAt",
      label: "Fecha de creación",
      isDate: true,
    },
  ];

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    getData();
  }, [params?.id, searchParams.get("entity")]);

  const [openCrearVisita, setOpenCrearVisita] = useState<boolean>(false);

  const actions: IAction[] = [
    {
      label: "Crear visita",
      onClick: (row) => {
        setEntity(row);
        setOpenCrearVisita(true)
      },
      hide(row) {
        return row.estadoId !== ESTADOS.PENDIENTE;
      }
    },
    {
      label: "Compartir préstamo",
      onClick: (row) => {
        setEntity(row);
        setOpenCompartir(true);
      },
      hide(row) {
        return row.estadoId !== ESTADOS.PENDIENTE;
      },
    },
    {
      label: "Modificar préstamo",
      onClick: (row) => {
        setEntity(row);
        setSearchParams({ type: "update", entity: "prestamo", id: row.id });
      },
    },
    {
      label: "Borrar préstamo",
      color: "#F2788E",
      onClick: (row) => {
        setEntity(row);
        setOpenBorrar(true);
      },
      disabled(row) {
        return AuthService.getAuth().rolId !== Roles.ADMINISTRADOR
      },
    },
  ];

  const [openCompartir, setOpenCompartir] = useState<boolean>(false);
  const [openBorrar, setOpenBorrar] = useState<boolean>(false);
  const [entity, setEntity] = useState<IPrestamo>(null);

  return (
    <>
      <CrearVisita entity={entity} setOpen={setOpenCrearVisita} open={openCrearVisita}/>
      <EntityForm />
      <BorrarModal
        open={openBorrar}
        entity={entity}
        type={"préstamo"}
        onDelete={() => {
          getData();
        }}
        handleClose={() => {
          setOpenBorrar(false);
        }}
        endpoint={"prestamos"}
      />
      <CompartirModal
        telefonos={
          _.pick(client, [
            "telefono",
            "telefonoWp",
            "telefonoAlt",
            "telefonoAltWp",
          ]) as IWhatsappButton
        }
        clienteName={client?.alias}
        prestamos={entity}
        type="préstamo"
        open={openCompartir}
        handleClose={() => setOpenCompartir(false)}
      />
      <TablaEstandar
        collapsable={(row: IPrestamo) => <Collapsable prestamo={row} />}
        isLoading={isLoading}
        rows={data?.filter(
          (prestamo: IPrestamo) => prestamo.estadoId !== ESTADOS.FINALIZADO
        )}
        columns={columns}
        actions={actions}
      />
    </>
  );
}
