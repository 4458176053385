import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import useFetch from "src/hooks/useFetch";
import { ICliente } from "src/utils/interfaces";
import Card from "./Card";
import Infobar from "./Infobar";
import Prestamos from "./Prestamos";

export default function DetalleCliente() {
  const { id } = useParams();

  const [client, setClient] = useState<ICliente>(null);

  const { data, fetchData } = useFetch("get", `clientes/${id}`);

  let location = useLocation();

  useEffect(() => {
    fetchData();
  }, [location]);

  useEffect(() => {
    if (data) {
      setClient(data);
    }
  }, [data]);

  return (
    <>
      <Infobar client={client} />
      <Card client={client} />
      <Prestamos client={client} />
    </>
  );
}
