import {
  Add,
  AddTaskOutlined,
  BorderColorOutlined,
  Edit,
  ExpandMore,
  PostAdd,
} from "@mui/icons-material";
import {
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import CustomAccordion from "src/components/shared/Custom/Accordion";
import CustomButton from "src/components/shared/Custom/Button";
import CustomSelect from "src/components/shared/Custom/Select";
import CustomTextField from "src/components/shared/Custom/TextField";
import useFetch from "src/hooks/useFetch";

export default function Form({ refresher, setRefresher }) {
  const [expanded, setExpanded] = useState<boolean>(false);

  const [form, setForm] = useState<{
    tipo: "SEMANAL" | "MENSUAL";
    cantidadCuotas: '';
  }>({ tipo: "SEMANAL", cantidadCuotas: '' });

  const handleFormChange = (
    field: string | "tipo" | "cantidadCuotas",
    value
  ) => {
    if (field === "cantidadCuotas" && !/^\d+$/.test(value) && value !== "") return
    setForm({ ...form, [field]: value });
  };

  const { fetchData } = useFetch("post");

  const handleSubmit = async () => {
    await fetchData("opciones-intereses", {...form, cantidadCuotas: Number(form.cantidadCuotas)});
    setRefresher(!refresher);
    clearForm()
  };

  const clearForm = () => {
    setForm({ tipo: "SEMANAL", cantidadCuotas: '' });
  };

  useEffect(() => {
    clearForm();
  }, [expanded]);

  return (
    <CustomAccordion sx={{ backgroundColor: "#f2f2f2" }} expanded={expanded}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ flexDirection: "row-reverse", gap: 2 }}
        onClick={() => setExpanded(!expanded)}
      >
        <Stack direction="row" gap={1}>
          <AddTaskOutlined sx={{ color: "#93ef8e" }} />
          <Typography fontWeight={500}>Agregar valores prefijados</Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Stack gap={2}>
          <CustomTextField
            onChange={(e) => handleFormChange("cantidadCuotas", e.target.value)}
            label="Cantidad de cuotas"
            name="cantidadCuotas"
            value={form["cantidadCuotas"]}
          ></CustomTextField>
          <FormControl>
            <InputLabel>Tipo</InputLabel>
            <CustomSelect
              name="tipo"
              label="Tipo"
              variant="outlined"
              value={form["tipo"]}
              onChange={(e) => handleFormChange(e.target.name, e.target.value)}
            >
              <MenuItem value="SEMANAL">Semanal</MenuItem>
              <MenuItem value="MENSUAL">Mensual</MenuItem>
            </CustomSelect>
          </FormControl>
          <Stack justifyContent="flex-end" direction="row">
            <CustomButton
              onClick={() => handleSubmit()}
              buttonColor="#1b75be"
              variant="contained"
              startIcon={<Add />}
              disabled={
                !form["cantidadCuotas"] ||
                form["cantidadCuotas"] === "" ||
                !form["tipo"] || !Number(form['cantidadCuotas'])
              }
            >
              Agregar
            </CustomButton>
          </Stack>
        </Stack>
      </AccordionDetails>
    </CustomAccordion>
  );
}
