import React, {useState} from "react";
import TablaEstandar, { IAction, IColumn } from "src/components/shared/Tabla";
import Collapsable from "../Collapsable";
import useTable from "src/hooks/useTable";
import { IPrestamo } from "src/utils/interfaces";
import { useNavigate } from "react-router-dom";
import CurrencyService from "src/services/CurrencyService";
import CustomButton from "src/components/shared/Custom/Button";
import { NoteAddOutlined } from "@mui/icons-material";
import { Box, TableCell, TableRow, Typography } from "@mui/material";
import BorrarModal from "src/components/shared/Modals/BorrarModal";
import ApiService from "src/services/ApiService";
import AuthService from "src/services/AuthService";
import { Roles } from "src/utils/RolEnum";

export default function TableWrapperPagados({isLoading, data, getData}) {

  const columns: IColumn<any>[] = [
    {
      property: "prestamo.cliente.alias",
      label: "Alias cliente",
      notSortable: true,
      format(row) {
        return (
          <Typography
            fontWeight={500}
            onClick={() => navigate(`/clientes/${row?.prestamo?.cliente?.id}`)}
            sx={{ ":hover": {
              cursor: 'pointer',
              textDecoration: 'underline'
            } }}
          >
            {row?.prestamo?.cliente?.alias}
          </Typography>
        );
      },
    },
    {
      property: "pagar",
      label: "Pagar",
      notSortable: true,
      format(row, value) {
        return (
          <CustomButton
            buttonColor="#93EF8E"
            variant="contained"
            disabled={true}
          >
            <NoteAddOutlined sx={{ color: "white" }} />
          </CustomButton>
        );
      },
    },
    {
      property: "prestamo.cuotaActual",
      label: "Cuota actual",
      notSortable: true,
      format(row: any, value) {
        return (
          value > row?.prestamo?.cantidadCuotas ? '-' : value
        )
      }
    },
    {
      property: "prestamo.valorCuota",
      label: "Valor de la cuota",
      format(row, value) {
        return CurrencyService.formatStatic(value);
      },
    },
    {
      property: "prestamo.cuotasCompletadas",
      label: "Cuotas completadas / Cuotas totales",
      notSortable: true,
      format(row: any) {
        return (
          <>
            {row.prestamo.cuotasCompletadas}/{row.prestamo.cantidadCuotas}
          </>
        );
      },
    },
    {
      property: "prestamo.valorRestanteCuotaActual",
      label: "Restante cuota",
      notSortable: true,
      format(row: any, value) {
        return value > row?.prestamo?.cantidadCuotas ? '$ 0' : CurrencyService.formatStatic(value);
      },
    },
    {
      property: "fechaAplazo",
      label: "Es aplazo?",
      notSortable: true,
      format(row, value) {
        return value ? <b>Sí</b> : 'No'
      }
    }
  ];

  const navigate = useNavigate();

  const [openBorrar, setOpenBorrar] = useState<boolean>(false)
  const [listaId, setListaId] = useState<number>(null)
  const [pagoId, setPagoId] = useState<number>(null)

  const actions: IAction[] = [
    {
      label: "Ver cliente",
      onClick: (row) => {
        navigate(`/clientes/${row.prestamo.clienteId}`);
      },
    },
    {
      label: "Eliminar pago",
      onClick: (row) => {
        setPagoId(row?.pagoAsociadoId)
        setListaId(row?.id)
        setOpenBorrar(true)
      },
      color: "#F2788E",
      disabled(row) {
        return AuthService.getAuth().rolId !== Roles.ADMINISTRADOR
      },
    },
  ];

  const onDeletePago = async () => {
    await ApiService['put'](`listas/${listaId}`, {pagado: false, pagoAsociadoId: null})
    getData()
  }

  return (
    <>
      <BorrarModal entity={{id: pagoId}} onDelete={onDeletePago} type={'pago'} endpoint={'pagos'} open={openBorrar} handleClose={() => setOpenBorrar(false)} />
      <TablaEstandar
        pagination={false}
        collapsable={(row) => <Collapsable prestamo={row?.prestamo} />}
        isLoading={isLoading}
        rows={data}
        columns={columns}
        actions={actions}
        header={false}
        customHeader={
          <TableRow sx={{ backgroundColor: "#93EF8E" }}>
            <TableCell colSpan={columns?.length + 2} sx={{paddingY: .5}}>
              <Typography
                paddingLeft={8}
                color={"white"}
                fontWeight={500}
              >
                Pagados
              </Typography>
            </TableCell>
          </TableRow>
        }
      />
    </>
  );
}
