import { AttachMoney } from "@mui/icons-material";
import {
  InputAdornment,
  StepContent,
  StepLabel,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import CustomTextField from "src/components/shared/Custom/TextField";
import { IFormComponent } from "src/hooks/useForm";
import CurrencyService from "src/services/CurrencyService";

interface IMonto extends IFormComponent {}

export default function Monto({
  handleFormChange,
  getForm,
  setFormField,
}: IMonto) {

  return (
    <>
      <StepLabel>
        <Typography fontSize={18} fontWeight={400} color={"black"}>
          Ingresar monto del préstamo
        </Typography>
      </StepLabel>
      <StepContent>
        <CustomTextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AttachMoney />
              </InputAdornment>
            ),
          }}
          name="valorPrestamo"
          value={
            getForm()?.valorPrestamo ? CurrencyService.formatInput(getForm()?.valorPrestamo) : ''
          }
          onChange={(e) =>
            handleFormChange(e.target, (value) => {
              value = value.replace(/\D/g, "");
              return value;
            })
          }
          placeholder="Monto del préstamo"
          sx={{ minWidth: "240px", width: "100%" }}
        />
      </StepContent>
    </>
  );
}
