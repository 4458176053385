import {
  Info,
  LocalAtmOutlined,
  MonetizationOnOutlined,
} from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import CurrencyService from "src/services/CurrencyService";
import { IPrestamo } from "src/utils/interfaces";

export default function Resumen({
  data,
  type,
}: {
  data: IPrestamo;
  type: "create" | "update";
}) {
  return (
    <Stack gap={1}>
      <Stack direction="row" alignItems={"center"} gap={1}>
        <Info sx={{ color: "#1B23BE" }} />
        <Typography fontSize={18} fontWeight={400} color={"black"}>
          Información del pago
        </Typography>
      </Stack>
      <Box width={"100%"} sx={{ backgroundColor: "#DDDEF5", padding: "24px" }}>
        <Stack>
          <Stack direction="row" alignItems={"center"} gap={1}>
            <LocalAtmOutlined sx={{ color: "#1B23BE", fontSize: 20 }} />
            <Typography fontSize={16} fontWeight={400} color={"black"}>
              Valor de cuota:{" "}
              {data?.valorCuota
                ? CurrencyService.formatStatic(data?.valorCuota)
                : ""}
            </Typography>
          </Stack>
          {type === "create" && (
            <Stack direction="row" alignItems={"center"} gap={1}>
              <MonetizationOnOutlined sx={{ color: "#1B23BE", fontSize: 20 }} />
              <Typography fontSize={16} fontWeight={400} color={"black"}>
                Valor restante para completar la cuota actual:{" "}
                {data?.valorRestanteCuotaActual
                  ? CurrencyService.formatStatic(data?.valorRestanteCuotaActual)
                  : ""}
              </Typography>
            </Stack>
          )}
        </Stack>
      </Box>
    </Stack>
  );
}
