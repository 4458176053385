import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { Collapse, IconButton, Menu, MenuItem, Stack } from "@mui/material";
import CustomSkeleton from "../Custom/Skeleton";
import CustomTextField from "../Custom/TextField";
import { MoreVert, Search } from "@mui/icons-material";
import _ from "lodash";
import { parse } from "date-fns";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Row from "./Row";

function descendingComparator<T>(a: T, b: T, orderBy, isDate: boolean) {
  let A = _.get(a, orderBy);
  let B = _.get(b, orderBy);
  if (isDate) {
    A = A && parse(A, "dd/MM/yyyy", new Date()).toISOString();
    B = B && parse(B, "dd/MM/yyyy", new Date()).toISOString();
  }
  if (A === null) {
    return 1;
  }
  if (B === null) {
    return -1;
  }
  if (B < A) {
    return -1;
  }
  if (B > A) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
  isDate: boolean
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy, isDate)
    : (a, b) => -descendingComparator(a, b, orderBy, isDate);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array?.map((el, index) => [el, index] as [T, number]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

export interface IColumn<T> {
  property: string;
  numeric?: boolean;
  label: string;
  notSortable?: boolean;
  format?: (row: T, value?) => any;
  sx?: {
    color?: string;
    fontSize?: number;
    fontWeight?: number;
    textAlign?: string;
    fontStyle?: string;
    width?: string;
  };
  isDate?: boolean;
}

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  headCells: IColumn<any>[];
  collapsable: (row?: any) => React.ReactNode;
  actions: IAction[];
  header: boolean;
  customHeader: React.ReactNode
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    order,
    orderBy,
    onRequestSort,
    headCells,
    collapsable,
    actions,
    header,
    customHeader
  } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead sx={header && { backgroundColor: "#DDDEF5" }}>
      <TableRow>
        {collapsable && <TableCell />}
        {headCells?.map((headCell) => (
          <TableCell
            key={headCell.property}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.property ? order : false}
            sx={!header && { opacity: 0, maxHeight: 0, padding: 0, margin: 0 }}
          >
            <TableSortLabel
              active={orderBy === headCell.property}
              direction={orderBy === headCell.property ? order : "asc"}
              onClick={createSortHandler(headCell.property)}
              disabled={headCell.notSortable}
            >
              {headCell.label}
              {orderBy === headCell.property ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {actions && <TableCell key={"actions"} width={2}></TableCell>}
      </TableRow>
      {!header && customHeader}
    </TableHead>
  );
}

export function CellGenerator({
  row,
  column: { property, format, numeric, sx },
}: {
  row;
  column: IColumn<any>;
}) {
  return (
    <TableCell scope="row" key={`${row.id}.${property}`} sx={sx}>
      {format
        ? format(row, _.get(row, property))
          ? format(row, _.get(row, property))
          : "--"
        : _.get(row, property)
        ? _.get(row, property)
        : "--"}
    </TableCell>
  );
}

export interface IAction {
  label: string;
  onClick: (row?: any) => void;
  disabled?: (row?: any) => boolean;
  color?: string;
  hide?: (row?: any) => boolean;
}

interface ITablaEstandar {
  isLoading: boolean;
  rows: any;
  columns: IColumn<any>[];
  searchBar?: boolean;
  search?: string;
  setterSearch?: React.Dispatch<React.SetStateAction<string>>;
  filters?: React.ReactNode;
  actions?: IAction[];
  collapsable?: (row?: any) => React.ReactNode;
  searchPlaceholder?: string;
  header?: boolean;
  pagination?: boolean;
  customHeader?: React.ReactNode;
  defaultRowsPerPage?: number;
}

export default function TablaEstandar({
  isLoading,
  rows,
  columns,
  searchBar = false,
  setterSearch,
  search,
  filters,
  actions,
  collapsable,
  searchPlaceholder = null,
  header = true,
  pagination = true,
  customHeader,
  defaultRowsPerPage = 5
}: ITablaEstandar) {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<string>("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(defaultRowsPerPage);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  React.useEffect(() => {
    setPage(0);
  }, [rows]);

  return (
    <>
      {searchBar && (
        <Stack
          p={2}
          paddingBottom={5}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          flexWrap={"wrap"}
        >
          <CustomTextField
            disabled={isLoading}
            placeholder={
              searchPlaceholder ?? "Buscar por dato de la tabla o nombre"
            }
            label="Buscador"
            onChange={(e) => setterSearch(e.target.value)}
            value={search}
            InputProps={{
              startAdornment: <Search sx={{ marginRight: 1 }} />,
            }}
            sx={{
              width: "100%",
              maxWidth: "327px",
            }}
          ></CustomTextField>
          {filters}
        </Stack>
      )}
      <Box sx={{ width: "100%" }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
              headCells={columns}
              collapsable={collapsable}
              actions={actions}
              header={header}
              customHeader={customHeader}
            />
            {Boolean(!rows?.length) && isLoading && (
              <>
                {columns?.map((item) => (
                  <TableRow key={_.indexOf(columns, item)}>
                    {columns?.map((column) => (
                      <TableCell key={column.property}>
                        <CustomSkeleton
                          value={""}
                          loading={isLoading}
                          variant="rectangular"
                          width="100%"
                          height="30px"
                        ></CustomSkeleton>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </>
            )}
            <TableBody>
              {pagination
                ? stableSort(
                    rows,
                    getComparator(
                      order,
                      orderBy,
                      _.find(columns, { property: orderBy })?.isDate
                    )
                  )
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((row, index) => {
                      return (
                        <Row
                          key={row.id}
                          row={row}
                          columns={columns}
                          collapsable={collapsable}
                          actions={actions}
                        />
                      );
                    })
                : rows?.map((row) => (
                    <Row
                      key={row.id}
                      row={row}
                      columns={columns}
                      collapsable={collapsable}
                      actions={actions}
                    />
                  ))}
              {emptyRows > 0 && (
                <TableRow>
                  <TableCell colSpan={columns?.length + 1} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {pagination && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={rows?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            showFirstButton
            showLastButton
          />
        )}
      </Box>
    </>
  );
}
