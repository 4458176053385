import { Step, StepProps } from "@mui/material";
import React from "react";

interface ICustomStep extends StepProps {
  children: React.ReactNode;
}

export default function CustomStep({ children, ...props }: ICustomStep) {
  return (
    <Step
      active
      {...props}
      sx={{
        '& .MuiStepLabel-root .Mui-active': {
            color: '#1B23BE', // circle color (COMPLETED)
          },
        ...props.sx,
      }}
    >
      {children}
    </Step>
  );
}
