import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import CustomStep from "src/components/shared/Custom/Step";
import Monto from "./Fields/monto";
import Resumen from "./Fields/resumen";
import Observacion from "./Fields/observacion";
import CustomDialog from "src/components/shared/Custom/Dialog";
import { MonetizationOn } from "@mui/icons-material";
import Close from "@mui/icons-material/Close";
import CustomButton from "src/components/shared/Custom/Button";
import ShareService from "src/services/ShareService";
import { format } from "date-fns";
import CurrencyService from "src/services/CurrencyService";
import Opciones from "./Fields/opciones";
import _ from "lodash";

export interface ICotizadorOpcion {
  tipo: "SEMANAL" | "MENSUAL";
  cantidadCuotas: number;
  interesTotal: number;
  esAgregado?: boolean;
}

export interface ICotizadorFields {
  valorPrestamo: string | null;
  observacion: string | null;
  opciones: ICotizadorOpcion[];
}

export default function Cotizador({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [fields, setFields] = useState<ICotizadorFields>({
    valorPrestamo: null,
    observacion: null,
    opciones: [],
  });

  const calcularValorCuota = (interesTotal, cantidadCuotas) => {
    if(fields.valorPrestamo){
      const result = (Number(fields.valorPrestamo)+(Number(fields.valorPrestamo)*Number(interesTotal)/100))/Number(cantidadCuotas)
      return CurrencyService.formatStatic(result)
    }
    return 'No calculable'
  }

  const getOpcionesText = () => {
    let text = ''
    const opcionesSemanales = _.sortBy(fields.opciones.filter(opcion => opcion.tipo === 'SEMANAL'), ['cantidadCuotas'])
    const opcionesMensuales = _.sortBy(fields.opciones.filter(opcion => opcion.tipo === 'MENSUAL'), ['cantidadCuotas'])
    if(opcionesSemanales.length){
      text += `*📑 OPCIONES SEMANAL DE ${CurrencyService.formatStatic(fields.valorPrestamo)}*\n\n`
      opcionesSemanales.forEach(opcion => {
        text += `- ${opcion.cantidadCuotas} ${opcion.cantidadCuotas == 1 ? 'cuota' : 'cuotas'} de ${calcularValorCuota(opcion.interesTotal, opcion.cantidadCuotas)}\n`
      })
      text += '\n'
    }
    if(opcionesMensuales.length){
      text += `*📑 OPCIONES MENSUAL DE ${CurrencyService.formatStatic(fields.valorPrestamo)}*\n\n`
      opcionesMensuales.forEach(opcion => {
        text += `- ${opcion.cantidadCuotas} ${opcion.cantidadCuotas == 1 ? 'cuota' : 'cuotas'} de ${calcularValorCuota(opcion.interesTotal, opcion.cantidadCuotas)}\n`
      })
      text += '\n'
    }
    return text
  }

  const generateTextWp = () => {
    let text = `*📅 COTIZACIÓN AL ${format(
      new Date(),
      "dd/MM/yyyy"
    )}*\n\n`
    text += getOpcionesText()
    if (fields.observacion) text += `*📎OBSERVACIÓN*: ${fields.observacion}`;
    return text;
  };

  const handleFormChange = (target, formatInput?: (value) => any) => {
    let value = target.value;
    if (formatInput) {
      value = formatInput(value);
    }
    setFields({ ...fields, [target.name]: value });
  };

  useEffect(() => {
    setFields({
      valorPrestamo: null,
      observacion: null,
      opciones: [],
    })
  }, [open])

  return (
    <CustomDialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap={5}
        >
          <Box
            fontSize={18}
            fontWeight={500}
            display={"flex"}
            alignItems="center"
            gap={2}
          >
            <MonetizationOn
              sx={{
                fontSize: 32,
                color: "white",
                background: "#8EC3EF",
                borderRadius: "100%",
                height: 30,
                width: 30,
                padding: 0.5,
              }}
            />
            Cotizador
          </Box>
          <Box>
            <IconButton onClick={() => setOpen(false)}>
              <Close />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Stack gap={2} maxWidth={"100%"}>
          <Stepper orientation="vertical">
            <CustomStep>
              <Monto
                fields={fields}
                name={"valorPrestamo"}
                handleFormChange={handleFormChange}
              />
            </CustomStep>
            <CustomStep>
              <Opciones fields={fields} setFields={setFields} />
            </CustomStep>
            <CustomStep completed>
              <StepLabel>
                <Typography fontSize={18} fontWeight={400} color={"black"}>
                  Opciones incluidas
                </Typography>
              </StepLabel>
            </CustomStep>
          </Stepper>
          <Resumen fields={fields} />
          <Observacion
            fields={fields}
            name={"observacion"}
            handleFormChange={handleFormChange}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" p={2} gap={2}>
          <CustomButton
            variant="outlined"
            sx={{
              color: "#1B75BE",
              borderColor: "#1B75BE",
              "&:hover": { backgroundColor: "white", borderColor: "#1B75BE" },
            }}
            onClick={() => {ShareService.openWhatsapp(generateTextWp())}}
            disabled={!fields.opciones.length || !fields.valorPrestamo}
          >
            Enviar por whatsapp
          </CustomButton>
          <CustomButton
            variant="contained"
            buttonColor="#1B75BE"
            disabled={!fields.opciones.length || !fields.valorPrestamo}
            onClick={() => {ShareService.copyToClipboard(generateTextWp())}}
          >
            Copiar a portapapeles
          </CustomButton>
        </Stack>
      </DialogActions>
    </CustomDialog>
  );
}
